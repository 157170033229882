    import React, {useState } from 'react';
    import { useNavigate } from 'react-router-dom';
    import axios from 'axios';
    import Swal from 'sweetalert2';
    import PhotoUpload from '../../../component/PhotoUpload';
    import Nationalities from '../../../assets/dropdowns/Nationalities';
    import Religions from '../../../assets/dropdowns/Religions';
    import Occupation from '../../../assets/dropdowns/Occupation';
    import PresentAddress from '../../../assets/dropdowns/PresentAddress';

    const RegisterResident = () => {
        const navigate = useNavigate();
        const [householdMembers, setHouseholdMembers] = useState([]);
        const [showPopup, setShowPopup] = useState(false);  // Popup state
        const [credentials, setCredentials] = useState({ email: '', password: '', householdID: '' }); 
        const [profilePic, setProfilePic] = useState(null);
        const [attachedFiles, setAttachedFiles] = useState([]); 
        const [loading, setLoading] = useState(false); // Loading state
        const [showCustomRelationship, setShowCustomRelationship] = useState(false);

        const [formData, setFormData] = useState({
            roleinHousehold: '',
            householdID: '',
            householdHead: '',
            reltohouseholdhead: '', 
            lastName: '',
            firstName: '',
            middleName: '',
            suffix: '',
            sex: '',
            birthday: '',
            birthplace: '',
            age: '',
            occupation: '',
            nationality: '',
            religion: '',
            civilStatus: '',
            emailAddress: '',
            mobileNumber: '',
            typeofdisability: '',
            profilepic: null, 
            voter: false,
            indigent: false,
            fourpsmember: false,
            pwd: false,
            soloparent: false,
            seniorCitizen: false,
            indigenouspeople: false,  
            outofschoolchildren: false,
            outofschoolyouth: false,
            pwdid_num: '',
            soloparentid_num: '',
            seniorcitizenid_num: '',
            permanentAddress: {
                unitFloorRoomNo: '',
                building: '',
                blockNo: '',
                lotNo: '',
                phaseNo: '',
                houseNo: '',
                street: '',
                subdivision: ''
            },
            presentAddress: {
                unitFloorRoomNo: '',
                building: '',
                blockNo: '',
                lotNo: '',
                phaseNo: '',
                houseNo: '',
                street: '',
                subdivision: '',
                city: '',
                province: '',
                barangay: '',
                region: '',
                postalcode: '',
            },
            philsys_num: '',
            voters_id: '',
            sss_num: '',
            pagibig_num: '',
            philhealth_num: '',
            tin_num: ''
        });
        const [sameAsPermanent, setSameAsPermanent] = useState(false);
        const [errors, setErrors] = useState({
            roleinHousehold: '(Required)',
            householdID: '',
            householdHead: '',
            reltohouseholdhead: '',
            lastName: '(Required)',
            firstName: '(Required)',
            sex: '(Required)',
            birthday: '(Required)',
            birthplace: '(Required)',
            civilStatus: '(Required)',
            nationality: '(Required)',
            religion: '(Required)',
            occupation: '(Required)',
            emailAddress: '(Required)',
            mobileNumber: '(Required)',
            'permanentAddress.houseNo': '(Required)',
            'permanentAddress.street': '(Required)'
        });

        const formatHouseholdHeadName = (name) => {
            const nameParts = name.split(' ');
            
            const formatNamePart = (part) => {
                // Keep the first letter and replace some of the following letters with asterisks
                if (part.length > 1) {
                    const visibleLength = Math.ceil(part.length / 3); // Show about 1/3 of the name
                    const maskedPart = part.substring(visibleLength).replace(/./g, '*'); // Mask remaining letters with *
                    return part.substring(0, visibleLength) + maskedPart;
                }
                return part; // For single letter names, no masking
            };
        
            const formattedParts = nameParts.map((part, index) => {
                if (index === nameParts.length - 1) {
                    return formatNamePart(part); // Format last name
                } else {
                    return `${part.charAt(0)}${formatNamePart(part.substring(1))}`; // Initial + masked name
                }
            });
        
            return formattedParts.join(' ');
        };
        
        const fetchHouseholdByNumber = async (householdID) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/${householdID}`);
                if (response.data && response.data.household) {
                    const { householdHeadName, household } = response.data;
        
                    const formattedName = formatHouseholdHeadName(householdHeadName);
        
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        householdHead: formattedName,
                        householdID: household.householdID
                    }));
        
                    // Make sure you're getting roleinHousehold
                    setHouseholdMembers(household.members);  // Assuming `roleinHousehold` is part of `members`
                    setErrors(prevErrors => ({ ...prevErrors, householdID: '' })); 
                } else {
                    setErrors(prevErrors => ({ ...prevErrors, householdID: 'Household information not found' }));
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        householdHead: '',
                        householdID: ''
                    }));
                    setHouseholdMembers([]);
                }
            } catch (error) {
                console.error('Error fetching household info:', error);
                setErrors(prevErrors => ({ ...prevErrors, householdID: 'Household ID Not found' }));
            }
        };

        const handleRelationshipChange = (e) => {
            const { value } = e.target;
        
            // If the user selects 'Other', prepare for custom input
            if (value === "Other") {
                setShowCustomRelationship(true);
                setFormData((prev) => ({
                    ...prev,
                    reltohouseholdhead: ""  // Clear reltohouseholdhead for custom input
                }));
            } else {
                setShowCustomRelationship(false);
                setFormData((prev) => ({
                    ...prev,
                    reltohouseholdhead: value  // Set the relationship as a single string value
                }));
            }
        };    

        const handleCustomRelationshipChange = (e) => {
            const { value } = e.target;
        
            setFormData((prev) => ({
                ...prev,
                reltohouseholdhead: value  // Store the custom relationship as a string
            }));
        };
        
        const handleInputChange = (e) => {
            const { name, value, type, checked } = e.target;
            const updatedValue = type === 'checkbox' ? checked : value;

            // Prevent numbers in name fields
        if (["lastName", "firstName", "middleName", "typeofdisability"].includes(name)) {
            const textOnlyRegex = /^[A-Za-z\s]*$/; // Allows only letters and spaces
            if (!textOnlyRegex.test(value)) {
                return; // Prevents updating the state if a number is entered
            }
        }

                // Handle email validation
                if (name === 'emailAddress') {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(value)) {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            emailAddress: 'Invalid email format',
                        }));
                    } else {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            emailAddress: '',
                        }));
                    }
                }

            // Handle the "Same as Permanent Address" checkbox
            if (name === 'sameAsPermanent') {
                setSameAsPermanent(checked);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    presentAddress: checked
                        ? { ...prevFormData.permanentAddress }
                        : {
                            unitFloorRoomNo: '',
                            building: '',
                            blockNo: '',
                            lotNo: '',
                            phaseNo: '',
                            houseNo: '',
                            street: '',
                            subdivision: '',
                            city: '',
                            province: '',
                            barangay: '',
                            region: '',
                            postalcode: '',
                        },
                }));
                return;
            }
        
            // Handle mobile number validation
            if (name === 'mobileNumber') {
                if (/^\d*$/.test(value) && value.length <= 11) {
                    setFormData({ ...formData, [name]: updatedValue });
        
                    // Validate mobile number to ensure it's exactly 10 digits and starts with '9'
                    if (value.length === 11 && value.startsWith('09')) {
                        setErrors({ ...errors, mobileNumber: '' });
                    } else if (value.length === 11) {
                        setErrors({ ...errors, mobileNumber: 'Mobile number must start with 09' });
                    } else {
                        setErrors({ ...errors, mobileNumber: 'Mobile number must be exactly 11 digits.' });
                    }
                }
                return; // Return early since the rest of the function doesn't apply to mobileNumber
            }
        
            if (name.startsWith('permanentAddress.') || name.startsWith('presentAddress.')) {
                const [addressType, addressField] = name.split('.');
                const fieldValue = updatedValue;
            
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [addressType]: {
                        ...prevFormData[addressType],
                        [addressField]: fieldValue,
                    },
                }));
            
                // ✅ Update error clearing for nested fields
                if (fieldValue.trim() === '') {
                    setErrors(prev => ({
                        ...prev,
                        [`${addressType}.${addressField}`]: '((Required))'
                    }));
                } else {
                    setErrors(prev => {
                        const updatedErrors = { ...prev };
                        updatedErrors[`${addressType}.${addressField}`] = '';
                        return updatedErrors;
                    });
                }
            
                if (sameAsPermanent && addressType === 'permanentAddress') {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        presentAddress: {
                            ...prevFormData.presentAddress,
                            [addressField]: fieldValue,
                        },
                    }));
                }
            
                return;
            }
        
            // Handle birthday to calculate age, senior citizen, and voter status
            if (name === 'birthday') {
                const selectedDate = new Date(value);
                const today = new Date();
        
                // Check if the selected date is in the future
                if (selectedDate > today) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        birthday: 'Invalid Date',
                    }));
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        birthday: '', 
                    }));
                    return;
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        birthday: '',
                    }));
                    const age = calculateAge(selectedDate);
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        birthday: value,
                        age: age,
                        seniorCitizen: age >= 60 ? true : false, // ✅ Automatically enable Senior Citizen checkbox
                        seniorcitizenid_num: age >= 60 ? prevFormData.seniorcitizenid_num || '' : '', // ✅ Restore or clear Senior Citizen ID        
                        outofschoolyouth: age >= 15 && age <= 24 ? formData.outofschoolyouth : false,
                        voter: age >= 16 ? prevFormData.voter : false,
                    }));
                    // Age validation: show an error if the age is less than 15
                    if (age < 15) {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            age: 'Residents must be at least 15 years old to register.',
                        }));
                    } else {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            age: '',
                        }));
                    }
                    }
                return;
            }

        // ✅ Handle PWD Checkbox Toggle (Restore Previous Values)
        if (name === 'pwd') {
            setFormData((prevData) => ({
                ...prevData,
                pwd: checked,
                pwdid_num: checked ? prevData.pwdid_num || '' : '', // Restore old or clear
                typeofdisability: checked ? prevData.typeofdisability || '' : '', // Restore old or clear
            }));
            return;
        }

        // ✅ Handle Solo Parent Checkbox Toggle (Restore Previous Values)
        if (name === 'soloparent') {
            setFormData((prevData) => ({
                ...prevData,
                soloparent: checked,
                soloparentid_num: checked ? prevData.soloparentid_num || '' : '', // Restore old or clear
            }));
            return;
        }

        // ✅ Handle Senior Citizen Checkbox (Prevent Manual Toggle)
        if (name === 'seniorCitizen') {
            return; // Prevent manual toggle
        }

            // Handle general field changes and their validation
            setFormData({ ...formData, [name]: updatedValue });
            if (typeof updatedValue === 'string' && updatedValue.trim() === '') {
                setErrors({ ...errors, [name]: '(Required)' });
            } else {
                setErrors({ ...errors, [name]: '' });
            }
        
            // Specific validation for checkboxes (e.g., voter, indigent)
            if (type === 'checkbox') {
                handleCheckboxValidation(name, checked);
            }
        
            // Handle role in household
            if (name === 'roleinHousehold') {
                handleHouseholdRoleChange(value);
            }
        
            // Handle household ID validation
            if (name === 'householdID') {
                handleHouseholdIDChange(value);
            }
        
            // Handle relationship field visibility and validation
            if (name === 'reltohouseholdhead' && formData.roleinHousehold === 'Household Member') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    reltohouseholdhead: value ? '' : '(Required)',
                }));
            }    
        
            // Handle voter checkbox
            if (name === 'voter') {
                setFormData({ ...formData, voter: checked });
            }
        };    
        
        // Helper function to handle checkbox-specific validation
        const handleCheckboxValidation = (name, checked) => {
            if (name === 'voter' || name === 'indigent' || name === 'fourpsmember' || name === 'pwd' || name === 'soloparent' || name === 'seniorCitizen') {
                const errorKey = {
                    indigent: 'indigent',
                    fourpsmember: 'fourpsmember',
                    pwd: 'pwdid_num',
                    soloparent: 'soloparentid_num',
                    seniorCitizen: 'seniorcitizenid_num',
                }[name];
        
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [errorKey]: checked ? '(Required)' : '',
                }));
            }
        };
        
        // Helper function to handle changes to the household role
        const handleHouseholdRoleChange = (value) => {
            if (value === 'Household Head') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    householdID: '',
                    householdHead: '',
                    reltohouseholdhead: '',
                }));
                setHouseholdMembers([]);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    householdID: '',
                    reltohouseholdhead: '',
                }));
            } else if (value === 'Household Member') {
                if (!formData.householdID) {
                    setErrors((prevErrors) => ({ ...prevErrors, householdID: '(Required)' }));
                }
                if (!formData.reltohouseholdhead) {
                    setErrors((prevErrors) => ({ ...prevErrors, reltohouseholdhead: '(Required)' }));
                }
            } else if (value === '') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    householdID: '',
                    householdHead: '',
                    reltohouseholdhead: '',
                }));
                setHouseholdMembers([]);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    householdID: '',
                    reltohouseholdhead: '',
                }));
            }
        };
        
        // Helper function to handle changes to the household ID
        const handleHouseholdIDChange = (value) => {
            if (value.trim() !== '') {
                fetchHouseholdByNumber(value);
            } else {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    householdHead: '',
                }));
                setHouseholdMembers([]);
                setErrors((prevErrors) => ({ ...prevErrors, householdID: '(Required)' }));
            }
        };
        
        
        const handleInputBlur = (e) => {
            const { name, value } = e.target;
        
            // Check if the householdID field lost focus
            if (name === 'householdID') {
                if (errors.householdID) {
                    // Show alert only if there's an error after losing focus
                    alert(errors.householdID);
                }
            }
        };

        const calculateAge = (birthDate) => {
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        };

        const handleFileChange = (e) => {
            const files = Array.from(e.target.files);
            setAttachedFiles(prevFiles => [...prevFiles, ...files]);
        };

        const handleRemoveFile = (index) => {
            setAttachedFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
        };        
        
        const handleSubmit = async (e) => {
            if (e && e.preventDefault) {
                e.preventDefault(); // Prevent default form submission if `e` is provided
            }
        
            // Age validation: Prevent submission if age is below 15
            if (formData.age < 15) {
                Swal.fire({
                    icon: 'error',
                    title: 'Age Restriction',
                    text: 'You must be at least 15 years old to register.',
                });
                return;
            }

            // Perform validation for email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.emailAddress)) {
                setErrors(prevErrors => ({ ...prevErrors, emailAddress: 'Invalid email format' }));
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: 'Please provide a valid email address.'
                });
                return;
            }

            // Check if PWD is checked and "Type of Disability" is empty
            if (formData.pwd && !formData.typeofdisability) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    typeofdisability: 'Please specify your type of disability',
                }));
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: 'Please specify your type of disability if you are a PWD.'
                });
                return;
            }

            // Generate the password exactly as in the backend
            const generatePassword = () => {
            const currentYear = new Date().getFullYear();
            const sanitizedLastName = formData.lastName.replace(/\s+/g, '').toLowerCase();
            const firstNameInitial = formData.firstName.charAt(0).toLowerCase();
            const middleNameInitial = formData.middleName ? formData.middleName.charAt(0).toLowerCase() : '';

            return `${currentYear}${sanitizedLastName}${firstNameInitial}${middleNameInitial}`;
        };

        const generatedPassword = generatePassword();
            
        if (formData.roleinHousehold === 'Household Member' && !formData.reltohouseholdhead) {
            setErrors(prevErrors => ({ ...prevErrors, reltohouseholdhead: 'Relationship to household head is required for household members.' }));
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please specify the relationship to the household head for household members.'
            });
            return;
        }
        
            // Validate if a valid ID is attached
            if (attachedFiles.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'No ID attached',
                    text: 'Please attach at least one valid ID before submitting.'
                });
                return;
            }
        
            // Perform final validation for required fields
            const requiredFields = ['roleinHousehold', 'religion', 'occupation', 'emailAddress', 'mobileNumber'];
            const fieldErrors = {};
        
            requiredFields.forEach(field => {
                if (!formData[field]) {
                    fieldErrors[field] = '(Required)';
                }
            });
        
            // Check if there are any errors in required fields
            if (Object.keys(fieldErrors).length > 0) {
                setErrors(prevErrors => ({ ...prevErrors, ...fieldErrors }));
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: 'Please fill out all required fields.'
                });
                return;
            }
        
            // Validate mobile number to ensure it's exactly 10 digits and starts with '9'
            if (formData.mobileNumber.length !== 11 || !formData.mobileNumber.startsWith('09')) {
                setErrors(prevErrors => ({ ...prevErrors, mobileNumber: 'Mobile number must be exactly 11 digits and start with 09.' }));
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: 'Please provide a valid 11-digit mobile number that starts with 09.'
                });
                return;
            }

                // Fetch all residents to check for duplicates
                    try {
                        const residentsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents`);
                        const residents = residentsResponse.data.residents;

                        // Convert user-entered birthday to a comparable format (YYYY-MM-DD)
                        const formattedBirthday = new Date(formData.birthday).toISOString().split('T')[0];

                        // Check if a resident with the same first name, last name, and birthday exists  // Check for duplicates including middle name and suffix
                        const duplicateResident = residents.find(
                            (resident) =>
                                resident.firstName.toLowerCase().trim() === formData.firstName.toLowerCase().trim() &&
                                resident.middleName?.toLowerCase().trim() === formData.middleName?.toLowerCase().trim() &&
                                resident.lastName.toLowerCase().trim() === formData.lastName.toLowerCase().trim() &&
                                (resident.suffix || '').toLowerCase().trim() === (formData.suffix || '').toLowerCase().trim() &&
                                new Date(resident.birthday).toISOString().split('T')[0] === formattedBirthday
                        );

                        if (duplicateResident) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Duplicate Record Found',
                                text: 'A resident with the same name and birthdate already exists in the system. Please visit the barangay and bring two valid IDs or a birth certificate for verification.',
                                confirmButtonColor: '#1346AC',
                            });
                            return;
                        }
                    } catch (error) {
                        console.error('Error fetching residents:', error);
                        Swal.fire({
                            icon: 'error',
                            title: 'System Error',
                            text: 'An error occurred while checking for duplicate records. Please try again later.',
                            confirmButtonColor: '#1346AC',
                        });
                        return; // Stop execution if there's an error fetching residents
                    }
        
            setLoading(true); // Set loading to true
        
            // Prepare the data for submission using FormData for file uploads
            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                if (key === 'permanentAddress') {
                    Object.keys(formData.permanentAddress).forEach(addressKey => {
                        formDataToSend.append(`permanentAddress[${addressKey}]`, formData.permanentAddress[addressKey]);
                    });
                } else if (key === 'presentAddress') {
                    Object.keys(formData.presentAddress).forEach(addressKey => {
                        formDataToSend.append(`presentAddress[${addressKey}]`, formData.presentAddress[addressKey]);
                    });
                } else if (key === 'profilepic' && formData.profilepic instanceof File) {
                    formDataToSend.append('profilepic', formData.profilepic);
                } else if (key === 'emailAddress') {
                    formDataToSend.append('email', formData.emailAddress); // Map emailAddress to email
                } else if (key === 'birthday') {
                    formDataToSend.append('birthday', formData.birthday); // Map birthday to birthday
                } else if (key === 'mobileNumber') {
                    formDataToSend.append('contactNumber', formData.mobileNumber); // Map mobileNumber to contactNumber
                } else if (key === 'householdID') {
                    formDataToSend.append(key, formData.householdID); // Make sure to append householdID
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            });
        
            // Ensure 'reltohouseholdhead' is appended only if it's required
            if (formData.roleinHousehold === 'Household Member') {
                formDataToSend.append('reltohouseholdhead', formData.reltohouseholdhead);
            }
            
            attachedFiles.forEach((file, index) => {
                formDataToSend.append('validIDs', file);
            });
        
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_KEY}/api/new/resident`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
        
                if (response.status >= 200 && response.status < 300 && response.data.newResident) {
                    const newResident = response.data.newResident;
                    const { email, residentID, householdID } = newResident;
        
                    const readableHouseholdID = await fetchReadablehouseholdID(householdID);
        
                    setCredentials({
                        email: email || formData.emailAddress,
                        password: generatedPassword || '',
                        residentID: residentID || 'Not available',
                        householdID: readableHouseholdID || 'Not assigned',
                    });
                    setShowPopup(true);
                    Swal.fire({
                        icon: 'success',
                        title: 'Registration Successful',
                        text: 'Please check your email for a verification link.'
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to Register',
                        text: 'Failed to create resident. Please try again.'
                    });
                }
            } catch (error) {
                if (error.response) {
                    const errorMessage = error.response.data.message || 'An error occurred during registration.';
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `Error registering resident: ${errorMessage}`
                    });
                } else if (error.request) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'No response from the server. Please try again later.'
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `Error registering resident: ${error.message}`
                    });
                }
            } finally {
                setLoading(false); 
            }
        };    

        const fetchReadablehouseholdID = async (householdObjectId) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/id/${householdObjectId}`);

                // Check if the response contains householdID and return it
                if (response.status === 200 && response.data && response.data.householdID) {
                    return response.data.householdID;  // Return the readable household number
                } else {
                    console.error('Readable householdID not found in response data, using ObjectId as fallback.', response.data);
                    return 'Not assigned';  // Return a fallback value if householdID is not found
                }
            } catch (err) {
                console.error('Error fetching readable householdID:', err);
                return 'Not assigned';  // Fallback to 'Not assigned' if there's an error
            }
        };
        
        
        const handleClosePopup = () => {
            setShowPopup(false);
            handleClear();
            clearPhoto(); // Clear the photo
            clearHouseholdMembers(); // Clear the household members
        };

        const handleClear = () => {
            setFormData({
                roleinHousehold: '',
                householdID: '',
                householdHead: '',
                reltohouseholdhead: '',
                lastName: '',
                firstName: '',
                middleName: '',
                suffix: '',
                sex: '',
                birthday: '',
                birthplace: '',
                age: '',
                occupation: '',
                nationality: '',
                religion: '',
                civilStatus: '',
                emailAddress: '',
                mobileNumber: '',
                typeofdisability: '',
                profilepic: null, 
                voter: false,
                indigent: false,
                fourpsmember: false,
                pwd: false,
                soloparent: false,
                seniorCitizen: false,
                indigenouspeople: false,  
                outofschoolchildren: false,
                outofschoolyouth: false,
                pwdid_num: '',
                soloparentid_num: '',
                seniorcitizenid_num: '',
                permanentAddress: {
                    unitFloorRoomNo: '',
                    building: '',
                    blockNo: '',
                    lotNo: '',
                    phaseNo: '',
                    houseNo: '',
                    street: '',
                    subdivision: ''
                },
                presentAddress: {
                    unitFloorRoomNo: '',
                    building: '',
                    blockNo: '',
                    lotNo: '',
                    phaseNo: '',
                    houseNo: '',
                    street: '',
                    subdivision: '',
                    city: '',
                    province: '',
                    barangay: '',
                    region: '',
                    postalcode: '',
                },
                philsys_num: '',
                voters_id: '',
                sss_num: '',
                pagibig_num: '',
                philhealth_num: '',
                tin_num: ''
            });
            setErrors({
                roleinHousehold: '(Required)',
                householdID: '',
                householdHead: '',
                reltohouseholdhead: '',
                lastName: '(Required)',
                firstName: '(Required)',
                sex: '(Required)',
                birthday: '(Required)',
                birthplace: '(Required)',
                civilStatus: '(Required)',
                religion: '(Required)',
                occupation: '(Required)',
                emailAddress: '(Required)',
                mobileNumber: '(Required)',
                nationality: '(Required)',
                'permanentAddress.houseNo': '(Required)',
                'permanentAddress.street': '(Required)'
            });
            setAttachedFiles([]); // Clear attached files
        };

        const clearPhoto = () => {
            setProfilePic(null);  // Clear the `profilePic` state
            setFormData(prevFormData => ({ ...prevFormData, profilepic: null }));  // Clear profilepic in formData
        };

        const clearHouseholdMembers = () => {
            setHouseholdMembers([]);
        };  

        return (
            <div className="flex flex-col h-screen overflow-hidden">
                {loading && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="spinner-border text-white" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                <div className="flex flex-1">
                    <main className="flex-1 p-8 bg-gray-100">
                        <div className="flex items-center mb-7">
                            <button
                                className="text-xl text-gray-500 hover:text-[#1346AC] cursor-pointer font-semibold mr-10"
                                onClick={() => navigate('/')}
                            >
                                &lt; Back
                            </button>
                            <h1 className="text-4xl font-bold">Register Resident</h1>
                        </div>
                        <form onSubmit={handleSubmit} className="flex-1 flex flex-col overflow-hidden">
                        <div className="bg-white p-6 rounded-lg shadow-md flex overflow-y-auto h-[calc(100vh-150px)]">
                                <div className="max-w-64">
                                <PhotoUpload
                                        profilepic={formData.profilepic}  
                                        handleClear={handleClear}
                                        handleSubmit={handleSubmit}
                                        setFormData={setFormData}
                                        clearPhoto={clearPhoto}
                                    />
                                </div>
                                <div className="flex-1 overflow-y-auto max-h-[800px] pl-6 scrollbar-hidden">
                                    <h2 className="text-2xl font-semibold mb-6">Household Information</h2>
                                    <div className="grid grid-cols-4 gap-4">
                                    <div>
                                            <div className='flex space-x-2'>
                                            <label className="block text-md font-medium text-gray-700">Household role </label>
                                            {errors.roleinHousehold && <p className="text-red-500 text-m">{errors.roleinHousehold}</p>}
                                            </div>
                                            <select
                                                name="roleinHousehold"  
                                                value={formData.roleinHousehold}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                            >
                                                <option value="">Select Household role</option>
                                                <option value="Household Head">Household Head</option>
                                                <option value="Household Member">Household Member</option>
                                            </select>                               
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                            <label className="block text-md font-medium text-gray-700">Household No.</label>
                                            {errors.householdID && <p className="text-red-500 text-m">{errors.householdID}</p>}
                                            </div>
                                            <input
                                                type="text"
                                                name="householdID"
                                                value={formData.householdID}
                                                onChange={handleInputChange}
                                                onBlur={handleInputBlur}
                                                disabled={formData.roleinHousehold === '' || formData.roleinHousehold === 'Household Head'}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Household No."
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Household Head</label>
                                            <input
                                                type="text"
                                                name="householdHead"
                                                readOnly
                                                value={formData.householdHead}
                                                onChange={handleInputChange}
                                                disabled={formData.roleinHousehold === '' || formData.roleinHousehold === 'Household Head'}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Household Head"
                                            />
                                        </div>
                                    <div>
                                    <div className='flex space-x-2'>
                                    <label className="block text-md font-medium text-gray-700">Relationship to Household Head</label>
                                    {errors.reltohouseholdhead && <p className="text-red-500 text-m">{errors.reltohouseholdhead}</p>}
                                    </div>
                                    <select
                                        name="reltohouseholdhead"
                                        value={showCustomRelationship ? "Other" : formData.reltohouseholdhead}  // If custom input is selected, show "Other"
                                        onChange={handleRelationshipChange}
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                        disabled={formData.roleinHousehold === 'Household Head'}
                                    >
                                        <option value="">Select Relationship</option>
                                        <option value="Parent">Parent</option>
                                        <option value="Spouse">Spouse</option>
                                        <option value="Child">Child</option>
                                        <option value="Sibling">Sibling</option>
                                        <option value="Grandchild">Grandchild</option>
                                        <option value="Grand Parent">Grand Parent</option>
                                        <option value="Niece">Niece</option>
                                        <option value="Uncle">Uncle</option>
                                        <option value="Aunt">Aunt</option>
                                        <option value="Cousin">Cousin</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    {/* Display the input for custom relationship if "Other" is selected */}
                                    {showCustomRelationship && (
                                        <input
                                            type="text"
                                            name="customReltohouseholdhead"
                                            value={formData.reltohouseholdhead}
                                            onChange={handleCustomRelationshipChange}
                                            className="mt-2 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                            placeholder="Please specify relationship"
                                        />
                                    )}
                                        </div>
                                        </div>
                                    {/* <h2 className="text-2xl font-semibold mt-6 mb-6">Household Members</h2>
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full bg-white">
                                            <thead>
                                                <tr className='border text-center'>
                                                    <th className="py-2 border-r">Resident No.</th>
                                                    <th className="py-2 border-r">Name</th>
                                                    <th className="py-2 border-r">Sex</th>
                                                    <th className="py-2 border-r">Age</th>
                                                    <th className="py-2 border-r">Role</th>
                                                    <th className="py-2 border-r">Contact Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {householdMembers.map((member, index) => (
                                                    <tr key={index} className='border text-center'>
                                                        <td className="py-2 border-r">{member.residentID}</td>
                                                        <td className="py-2 border-r">{`${member.firstName} ${member.middleName ? member.middleName + ' ' : ''}${member.lastName}`}</td>
                                                        <td className="py-2 border-r">{member.sex}</td>
                                                        <td className="py-2 border-r">{member.age}</td>
                                                        <td className="py-2 border-r">{member.roleinHousehold}</td>
                                                        <td className="py-2 border-r">{member.contactNumber}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div> */}
                                    <h2 className="text-2xl font-semibold mt-6 mb-6">Personal Information</h2>
                                    <div className="grid grid-cols-4 gap-4">
                                        <div>
                                            <div className='flex space-x-2'>
                                            <label className="block text-md font-medium text-gray-700">Last Name</label>
                                            {errors.lastName && <p className="text-red-500 text-m">{errors.lastName}</p>}
                                            </div>
                                            <input
                                                type="text"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Last Name"
                                            />
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                            <label className="block text-md font-medium text-gray-700">First Name</label>
                                            {errors.firstName && <p className="text-red-500 text-m">{errors.firstName}</p>}
                                            </div>
                                            <input
                                                type="text"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter First Name"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Middle Name</label>
                                            <input
                                                type="text"
                                                name="middleName"
                                                value={formData.middleName}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Middle Name"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Suffix</label>
                                            <select
                                                name="suffix"
                                                value={formData.suffix || ''}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                            >
                                                <option value="">None</option>
                                                <option value="JR.">Jr.</option>
                                                <option value="SR.">Sr.</option>
                                                <option value="III">III</option>
                                                <option value="IV">IV</option>
                                                <option value="V">V</option>
                                            </select>
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                            <label className="block text-md font-medium text-gray-700">Sex</label>
                                            {errors.sex && <p className="text-red-500 text-m">{errors.sex}</p>}
                                            </div>
                                            <select
                                                name="sex"
                                                value={formData.sex}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                            >
                                                <option value="">Select Sex</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                            <label className="block text-md font-medium text-gray-700">Birthdate</label>
                                            {errors.birthday && <p className="text-red-500 text-m">{errors.birthday}</p>}
                                            </div>
                                            <input
                                                type="date"
                                                name="birthday"
                                                value={formData.birthday}
                                                onChange={handleInputChange}
                                                max={new Date().toISOString().split('T')[0]} // Today's date
                                                min={new Date(new Date().setFullYear(new Date().getFullYear() - 120)).toISOString().split('T')[0]} 
                                                className="mt-1 block w-full pl-3 pr-2 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                            />
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                            <label className="block text-md font-medium text-gray-700">Birthplace</label>
                                            {errors.birthplace && <p className="text-red-500 text-m">{errors.birthplace}</p>}
                                            </div>
                                            <input
                                                type="text"
                                                name="birthplace"
                                                value={formData.birthplace}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Birth Place"
                                            />                                      
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Age</label>
                                            <input
                                                type="number"
                                                name="age"
                                                value={formData.age}
                                                onChange={handleInputChange}
                                                readOnly
                                                className="mt-1 block w-full pl-3 pr-2 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                            />
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                        <label className="block text-md font-medium text-gray-700">Occupation</label>
                                        {errors.occupation && <p className="text-red-500 text-m">{errors.occupation}</p>}
                                        </div>
                                            <Occupation
                                                selectedOccupation={formData.occupation}
                                                handleChange={handleInputChange}
                                            />                                       
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                        <label className="block text-md font-medium text-gray-700">Nationality</label>
                                        {errors.nationality && (
                                                <p className="text-red-500 text-m">{errors.nationality}</p>
                                            )}
                                        </div>
                                            <Nationalities
                                                selectedNationality={formData.nationality}
                                                handleChange={handleInputChange}
                                            />
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                        <label className="block text-md font-medium text-gray-700">Religion</label>
                                        {errors.religion && (
                                                <p className="text-red-500 text-m">{errors.religion}</p>
                                            )}
                                            </div>
                                            <Religions
                                                selectedReligion={formData.religion}
                                                handleChange={handleInputChange}
                                            />
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                            <label className="block text-md font-medium text-gray-700">Civil Status</label>
                                            {errors.civilStatus && <p className="text-red-500 text-m">{errors.civilStatus}</p>}
                                            </div>
                                            <select
                                                name="civilStatus"
                                                value={formData.civilStatus}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                            >
                                                <option value="">Select Civil Status</option>
                                                <option value="Single">Single</option>
                                                <option value="Married">Married</option>
                                                <option value="Separated">Separated</option>
                                                <option value="Annulled">Annulled</option>
                                                <option value="Divorced">Divorced</option>
                                                <option value="Widowed">Widowed</option>
                                            </select>
                                        </div>
                                        <div>
                                        <div className='flex space-x-2'>
                                            <label className="block text-md font-medium text-gray-700">Email Address</label>
                                            {errors.emailAddress && <p className="text-red-500 text-m">{errors.emailAddress}</p>}
                                            </div>
                                            <input
                                                type="email"
                                                name="emailAddress"
                                                value={formData.emailAddress}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Email Address"
                                            />
                                        </div>
                                        <div>
                                        <div className="flex space-x-2 items-center">
                                            <label className="block text-md font-medium text-gray-700">Mobile Number</label>
                                            {errors.mobileNumber && errors.mobileNumber.length <= 10 && (
                                            <p className="text-red-500 text-m">{errors.mobileNumber}</p>
                                            )}
                                        </div>

                                        <div className="mt-1 flex rounded-md shadow-sm">
                                            <input
                                            type="text"
                                            name="mobileNumber"
                                            value={formData.mobileNumber}
                                            onChange={handleInputChange}
                                            className="flex-1 block w-full min-w-0 pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                            placeholder="Enter Mobile Number"
                                            />
                                        </div>
                                        {errors.mobileNumber && errors.mobileNumber.length > 10 && (
                                            <p className="text-red-500 text-sm mt-1 break-words">{errors.mobileNumber}</p>
                                        )}
                                        </div>
                                        <div className="flex items-center">
                                        <input
                                                type="checkbox"
                                                name="voter"
                                                checked={formData.voter}
                                                onChange={handleInputChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                                disabled={formData.age < 16} 
                                            />
                                            <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Voter</label>
                                            <input
                                                type="checkbox"
                                                name="indigent"
                                                checked={formData.indigent}
                                                onChange={handleInputChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                            <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Indigent</label>
                                            <input
                                                type="checkbox"
                                                name="fourpsmember"
                                                checked={formData.fourpsmember}
                                                onChange={handleInputChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                            <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">4Ps</label>

                                            <input
                                                type="checkbox"
                                                name="outofschoolchildren"
                                                checked={formData.outofschoolchildren}
                                                disabled
                                                onChange={handleInputChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                            <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Out-of-School <br/> Children</label>
                                            <input
                                                type="checkbox"
                                                name="outofschoolyouth"
                                                checked={formData.outofschoolyouth}
                                                disabled={!(formData.age >= 15 && formData.age <= 24)}
                                                onChange={handleInputChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                            <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Out-of-School <br/> Youth</label>
                                            <input
                                                type="checkbox"
                                                name="indigenouspeople"
                                                checked={formData.indigenouspeople}
                                                onChange={handleInputChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                            <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Indigenous <br/> People</label>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4 mt-4">
                                    {/* PWD Section */}
                                    <div className="space-y-2">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                name="pwd"
                                                checked={formData.pwd}
                                                onChange={handleInputChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                            <label className="ml-2 block text-md text-gray-900">PWD</label>
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">PWD ID No.</label>
                                            <input
                                                type="text"
                                                name="pwdid_num"
                                                value={formData.pwdid_num}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter PWD ID No."
                                                disabled={!formData.pwd}
                                            />
                                            {errors.pwdid_num && <p className="text-red-500 text-m mt-1">{errors.pwdid_num}</p>}
                                        </div>

                                        {formData.pwd && (
                                            <div>
                                                <label className="block text-md font-medium text-gray-700">Type of Disability</label>
                                                <input
                                                    type="text"
                                                    name="typeofdisability"
                                                    value={formData.typeofdisability}
                                                    onChange={handleInputChange}
                                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                    placeholder="Enter Type of Disability"
                                                />
                                                {formData.pwd && !formData.typeofdisability && (
                                                    <p className="text-red-500 text-m mt-1">Please specify your type of disability.</p>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    {/* Solo Parent Section */}
                                    <div className="space-y-2">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                name="soloparent"
                                                checked={formData.soloparent}
                                                onChange={handleInputChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                            <label className="ml-2 block text-md text-gray-900">Solo Parent</label>
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Solo Parent ID No.</label>
                                            <input
                                                type="text"
                                                name="soloparentid_num"
                                                value={formData.soloparentid_num}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Solo Parent ID No."
                                                disabled={!formData.soloparent}
                                            />
                                            {errors.soloparentid_num && <p className="text-red-500 text-m mt-1">{errors.soloparentid_num}</p>}
                                        </div>
                                    </div>

                                    {/* Senior Citizen Section */}
                                    <div className="space-y-2">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                name="seniorCitizen"
                                                checked={formData.seniorCitizen}
                                                onChange={handleInputChange}
                                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                                disabled={formData.age < 60}
                                            />
                                            <label className="ml-2 block text-md text-gray-900">Senior Citizen</label>
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Senior Citizen ID No.</label>
                                            <input
                                                type="text"
                                                name="seniorcitizenid_num"
                                                value={formData.seniorcitizenid_num}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Senior Citizen ID No."
                                                disabled={!formData.seniorCitizen}
                                            />
                                            {errors.seniorcitizenid_num && <p className="text-red-500 text-m mt-1">{errors.seniorcitizenid_num}</p>}
                                        </div>
                                    </div>
                                </div>

                                    <h2 className="text-2xl font-semibold mt-6 mb-6">Permanent Address</h2>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Unit/Floor/Room No.</label>
                                            <input
                                                type="text"
                                                name="permanentAddress.unitFloorRoomNo"
                                                value={formData.permanentAddress.unitFloorRoomNo}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Unit/Floor/Room No."
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Building</label>
                                            <input
                                                type="text"
                                                name="permanentAddress.building"
                                                value={formData.permanentAddress.building}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Building"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Block No.</label>
                                            <input
                                                type="text"
                                                name="permanentAddress.blockNo"
                                                value={formData.permanentAddress.blockNo}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Block No."
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Lot No.</label>
                                            <input
                                                type="text"
                                                name="permanentAddress.lotNo"
                                                value={formData.permanentAddress.lotNo}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Lot No."
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Phase No.</label>
                                            <input
                                                type="text"
                                                name="permanentAddress.phaseNo"
                                                value={formData.permanentAddress.phaseNo}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Phase No."
                                            />
                                        </div>
                                        <div>
                                        <div className="flex space-x-2">
                                                <label className="block text-md font-medium text-gray-700">House No.</label>
                                                {errors['permanentAddress.houseNo'] && errors['permanentAddress.houseNo'].length <= 10 && (
                                                <p className="text-red-500 text-m">{errors['permanentAddress.houseNo']}</p>
                                                )}
                                            </div>
                                            <input
                                                type="text"
                                                name="permanentAddress.houseNo"
                                                value={formData.permanentAddress.houseNo}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter House No."
                                            />
                                            {errors['permanentAddress.houseNo'] && errors['permanentAddress.houseNo'].length > 10 && (
                                            <p className="text-red-500 text-m mt-1 break-words">{errors['permanentAddress.houseNo']}</p>
                                        )}
                                        </div>
                                        <div>
                                        <div className="flex space-x-2 items-center">
                                                <label className="block text-md font-medium text-gray-700">Street</label>
                                                {errors['permanentAddress.street'] && errors['permanentAddress.street'].length <= 10 && (
                                                <p className="text-red-500 text-m">{errors['permanentAddress.street']}</p>
                                                )}
                                            </div>
                                            <input
                                                type="text"
                                                name="permanentAddress.street"
                                                value={formData.permanentAddress.street}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Street"
                                            />  
                                            {errors['permanentAddress.street'] && errors['permanentAddress.street'].length > 10 && (
                                                <p className="text-red-500 text-sm mt-1 break-words">{errors['permanentAddress.street']}</p>
                                            )}                                    
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Subdivision</label>
                                            <input
                                                type="text"
                                                name="permanentAddress.subdivision"
                                                value={formData.permanentAddress.subdivision}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Subdivision"
                                            />
                                        </div>
                                    </div>
                                    <PresentAddress formData={formData} handleInputChange={handleInputChange} errors={errors} />
                                        <div className="flex items-center justify-start mt-8 flex-wrap">
                                            <label className="text-md font-medium text-gray-700 mr-2">
                                                Same as Permanent Address
                                            </label>
                                            <input
                                                type="checkbox"
                                                name="sameAsPermanent"
                                                checked={sameAsPermanent}
                                                onChange={handleInputChange}
                                                className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                            />
                                        </div>
                                    <h2 className="text-2xl font-semibold mt-6 mb-6">Other Information</h2>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">PhilSys No.</label>
                                            <input
                                                type="text"
                                                name="philsys_num"
                                                value={formData.philsys_num}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter PhilSys No."
                                            />
                                        </div>
                                        <div>
                                        <label className="block text-md font-medium text-gray-700">Voters ID No. (Optional)</label>
                                        <input
                                            type="text"
                                            name="voters_id"
                                            value={formData.voters_id}
                                            onChange={handleInputChange}
                                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                            placeholder="Enter Voters ID No. (Optional)"
                                            disabled={!formData.voter} 
                                        />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">SSS ID No.</label>
                                            <input
                                                type="text"
                                                name="sss_num"
                                                value={formData.sss_num}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter SSS ID No."
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Pag-IBIG MID No.</label>
                                            <input
                                                type="text"
                                                name="pagibig_num"
                                                value={formData.pagibig_num}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Pag-IBIG MID No."
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">Philhealth No.</label>
                                            <input
                                                type="text"
                                                name="philhealth_num"
                                                value={formData.philhealth_num}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter Philhealth No."
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-md font-medium text-gray-700">TIN</label>
                                            <input
                                                type="text"
                                                name="tin_num"
                                                value={formData.tin_num}
                                                onChange={handleInputChange}
                                                className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                                                placeholder="Enter TIN"
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <label className="text-2xl font-semibold mt-6 mb-6">Attach Valid IDs</label>
                                        <div>
                                            <button
                                                type="button"
                                                className="border mt-4 block border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                                style={{ width: "100px", height: "100px" }}
                                                onClick={() => document.getElementById('file-upload').click()}
                                            >
                                                <div className="flex justify-center items-center h-full">
                                                    <span className="text-4xl font-bold">+</span>
                                                </div>
                                            </button>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                style={{ display: 'none' }}
                                                multiple
                                                onChange={handleFileChange}
                                            />
                                            <div className="mt-2 flex flex-wrap gap-4">
                                                {attachedFiles.map((file, index) => (
                                                    <div key={index} className="flex flex-col items-center">
                                                        <img
                                                            src={URL.createObjectURL(file)}
                                                            alt="Attached file"
                                                            className="max-w-xs max-h-xs object-cover"
                                                        />
                                                        <button
                                                            type="button"
                                                            className="bg-red-500 text-white px-2 py-1 rounded-full mt-2"
                                                            onClick={() => handleRemoveFile(index)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </main>
                </div>
                {showPopup && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-10 rounded-lg shadow-lg min-h-80" >
                            <h2 className="text-2xl font-semibold mb-4">Login Credentials</h2>
                            <p className="mb-2">Resident ID: {credentials.residentID || 'Not available'}</p> 
                            <p className="mb-2">Email: {credentials.email || 'Not available'}</p>
                            <p className="mb-2">Password: {credentials.password || 'Not available'}</p>
                            {credentials.householdID && <p className="mb-4">Household No: {credentials.householdID}</p>}
                            <div className="mb-2">
                                <button
                                    onClick={() => { setShowPopup(false); handleClear(); clearPhoto(); clearHouseholdMembers(); }}
                                    className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold w-full hover:bg-blue-700"
                                >
                                    Add Another Resident
                                </button>
                            </div>
                            <button
                                onClick={() => { setShowPopup(false); handleClear(); clearPhoto(); clearHouseholdMembers(); handleClosePopup();}}
                                className="bg-transparent border border-[#1346AC] text-[#1346AC] px-4 py-2 rounded-full font-semibold w-full hover:bg-gray-200"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    export default RegisterResident;
