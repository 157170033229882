import React from 'react';
import { Link } from 'react-router-dom';
import {  MdEditDocument, MdDescription, MdMessage, MdGroup, MdInfo, MdMap } from 'react-icons/md';
import { BiSolidMegaphone } from 'react-icons/bi';
import { FaHouse, FaSquarePhone } from 'react-icons/fa6';
import { AiFillInfoCircle } from 'react-icons/ai';
import { BsFillBuildingsFill } from "react-icons/bs";

const HomepageCards = ({ residentData }) => {
  const cardData = [
    {
      path: "/Resident/Announcements",
      icon: <BiSolidMegaphone size={50} />,
      title: "Announcements"
    },
    {
      path: "/Resident/Complaint",
      icon: <MdEditDocument size={50} />,
      title: "Complaints"
    },
    {
      path: "/Resident/Document-Request",
      icon: <MdDescription size={50} />,
      title: "Document Requests"
    },
    {
      path: "/Resident/Messages",
      icon: <AiFillInfoCircle size={50} />,
      title: "Help Center"
    },
    {
      path: "/Resident/Barangay-Officials-Directory",
      icon: <MdGroup size={50} />,
      title: "Barangay Officials Directory"
    },
    {
      path: "/Resident/Barangay-Information",
      icon: <BsFillBuildingsFill size={50} />,
      title: "Barangay Information"
    },
    {
      path: "/Resident/EvacuationMap",
      icon: <MdMap size={50} />,
      title: "Evacuation Map"
    },
    {
      path: "/Resident/Emergency-Hotlines",
      icon: <FaSquarePhone size={50} />,
      title: "Emergency Hotlines"
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
    {cardData.map((card, index) => (
      <div key={index} className="card bg-base-100 shadow-xl hover:bg-[#1346AC] hover:rounded-2xl group transition duration-300">
        <div className="card-body flex items-center justify-center h-full">
          <Link
            to={{ pathname: card.path, state: { residentData } }}
            className="flex flex-col sm:flex-row items-center text-center sm:text-left gap-2 p-2 text-black group-hover:text-white transition duration-300 w-full"
          >
            <div className="text-black group-hover:text-white transition duration-300">
              {card.icon}
            </div>
            <span className="font-semibold text-lg break-words sm:whitespace-nowrap line-clamp-2">{card.title}</span>
          </Link>
        </div>
      </div>
    ))}
  </div>
);
};

export default HomepageCards;
