import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import barangayLogo from '../assets/images/iServe-Barangay-home-logo.png';
import defaultProfile from '../assets/icons/default-profile.png';
import arrowDown from '../assets/icons/arrowdown.png';

const Header = ({ userName, userRole, profilePic, handleLogout }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

     // Navigate to profile page when the Profile dropdown is clicked
     const handleProfileClick = () => {
        const user = JSON.parse(localStorage.getItem('user')); // Fetch the user details from local storage
        if (user && user._id) {
            navigate(`/Profile/${user._id}`); // Navigate to /Profile/{objectID}
        }
    };

    return (
        <div className="flex items-center justify-between bg-[#1346AC] p-4">
            {/* Hide Logo on smaller screens */}
            <button onClick={() => navigate('/home')}>
                <img src={barangayLogo} alt="Logo" className="w-30 h-10 ml-3 hidden lg:block cursor-pointer" />
            </button>

            <div className="flex items-center">
                {/* Hide User Name & Role on smaller screens */}
                <div className="mr-4 text-white hidden sm:block">
                    <span className="block font-bold text-xl text-start">{userName}</span> 
                    <span className="block text-lg text-end">{userRole}</span>
                </div>
                    {/* Profile Picture & Dropdown (Always Visible) */}
                    <div className="relative mr-5">
                    <div className="w-12 h-12 relative">
                        <img
                            src={profilePic ? profilePic : defaultProfile} 
                            alt="Profile"
                            className="w-full h-full rounded-full cursor-pointer object-cover" 
                            onClick={toggleDropdown}
                        />
                        <img
                            src={arrowDown}
                            alt="Arrow Down"
                            className="w-4 h-4 absolute bottom-0 right-0 mb-0.5 mr-0.5 cursor-pointer"
                            onClick={toggleDropdown}
                        />
                    </div>
                    {dropdownOpen && (
                        <div className="absolute right-0 mt-3 w-48 bg-gray-200 rounded-lg shadow-lg">
                            <div
                                className="block px-4 py-2 text-gray-800 bg-gray-200 hover:bg-gray-300 rounded-md cursor-pointer font-semibold"
                                onClick={handleProfileClick}
                            >
                                Profile
                            </div>
                            <div
                                className="block px-4 py-2 text-gray-800 bg-gray-200 hover:bg-gray-300 rounded-md cursor-pointer font-semibold"
                                onClick={handleLogout}
                            >
                                Logout
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
