import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from '../../component/Header';
import Navigation from '../../component/Navigation';
import Occupation from '../../assets/dropdowns/Occupation';
import Religions from '../../assets/dropdowns/Religions';
import Nationalities from '../../assets/dropdowns/Nationalities';
import PresentAddress from '../../assets/dropdowns/PresentAddress';

const EditResident = () => {
  const [formData, setFormData] = useState(null);
  const [originalData, setOriginalData] = useState(null); // Store original data for comparison
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [adminData, setAdminData] = useState(null);
  const [householdMembers, setHouseholdMembers] = useState([]);
  const [householdHead, setHouseholdHead] = useState('');
  const [householdNumber, setHouseholdNumber] = useState('');
  const [householdHeadContactNumber, setHouseholdHeadContactNumber] = useState('');
  const [errors, setErrors] = useState({});
  const [attachedFiles, setAttachedFiles] = useState([]); // Valid ID storage
  const [sameAsPermanent, setSameAsPermanent] = useState(false); // Toggle for address
  const [showModal, setShowModal] = useState(false);
  const [changes, setChanges] = useState({});
  const [removedValidIDs, setRemovedValidIDs] = useState([]);
  const { residentID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        if (user.profilepic) {
            user.profilepic = user.profilepic.replace(/\\/g, '/');
        }
        const capitalizeWords = (str) =>
            str
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        const firstName = capitalizeWords(user.firstName);
        const lastName = capitalizeWords(user.lastName);
        const middleInitial = user.middleName ? capitalizeWords(user.middleName.charAt(0)) + '.' : '';
        setUserName(`${firstName} ${middleInitial} ${lastName}`);
        setAdminData(user);
        setUserRole(user.roleinBarangay);
    }
}, []);

  useEffect(() => {
    fetchResidentAndHouseholdDetails();
  }, []);


  const fetchResidentAndHouseholdDetails = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/${residentID}`);
        const residentData = response.data;
        setFormData(residentData);
        setOriginalData(residentData);
        setPreview(residentData.profilepic);

      // If validIDs already exist (stored URLs), store them
      if (residentData.validIDs) {
        setAttachedFiles(residentData.validIDs.map(file => ({ url: file }))); // Store as object {url: existingURL}
      }

      if (residentData._id) {
        fetchHouseholdByResident(residentData._id);
    } else {
        console.warn('No resident ID found');
    }

    } catch (error) {
        console.error('Error fetching resident details:', error);
    }
};


const handleInputChange = (e) => {
  const { name, value, type, checked } = e.target;
  const updatedValue = type === 'checkbox' ? checked : value;

  if (name === 'sameAsPermanent') {
    setSameAsPermanent(checked);

    setFormData((prevData) => ({
        ...prevData,
        presentAddress: checked
            ? { ...prevData.permanentAddress } // Ensure deep copy
            : {
                  unitFloorRoomNo: '',
                  building: '',
                  blockNo: '',
                  lotNo: '',
                  phaseNo: '',
                  houseNo: '',
                  street: '',
                  subdivision: '',
                  barangay: '',
                  city: '',
                  province: '',
                  region: '',
                  postalcode: '',
              },
    }));
    return;
}

      // ✅ First Name Validation
      if (name === "firstName") {
        setErrors((prevErrors) => ({
            ...prevErrors,
            firstName: value.trim() === "" ? "First Name is required" : "",
        }));
    }

    // ✅ Last Name Validation
    if (name === "lastName") {
        setErrors((prevErrors) => ({
            ...prevErrors,
            lastName: value.trim() === "" ? "Last Name is required" : "",
        }));
    }

    // ✅ Email Validation
    if (name === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: emailRegex.test(value) ? "" : "Invalid email format",
        }));
    }

    // ✅ Street Validation
    if (name === "permanentAddress.street") {
        setErrors((prevErrors) => ({
            ...prevErrors,
            street: value.trim() === "" ? "Street is required" : "",
        }));
    }

    // ✅ House No. Validation
    if (name === "permanentAddress.houseNo") {
        setErrors((prevErrors) => ({
            ...prevErrors,
            houseNo: value.trim() === "" ? "House No. is required" : "",
        }));
    }
  
     // ✅ Handle Birthday Change: Auto-Update Age & Senior Citizen Checkbox
     if (name === 'birthday') {
      const birthDate = new Date(value);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }

      if (birthDate > today) {
          Swal.fire({
              icon: 'error',
              title: 'Invalid Date',
              text: 'Birthdate cannot be in the future.',
          });
          return;
      }

      setFormData((prevData) => ({
          ...prevData,
          birthday: value,
          age: age,
          seniorCitizen: age >= 60 ? true : false, // ✅ Automatically enable Senior Citizen checkbox
          seniorcitizenid_num: age >= 60 ? prevData.seniorcitizenid_num : '', // ✅ Restore or clear Senior Citizen ID
      }));
      return;
  }

  // ✅ Handle PWD Checkbox Toggle (Restore Previous Values)
  if (name === 'pwd') {
      setFormData((prevData) => ({
          ...prevData,
          pwd: checked,
          pwdid_num: checked ? prevData.pwdid_num || originalData.pwdid_num || '' : '', // Restore old or clear
          typeofdisability: checked ? prevData.typeofdisability || originalData.typeofdisability || '' : '', // Restore old or clear
      }));
      return;
  }

  // ✅ Handle Solo Parent Checkbox Toggle (Restore Previous Values)
  if (name === 'soloparent') {
      setFormData((prevData) => ({
          ...prevData,
          soloparent: checked,
          soloparentid_num: checked ? prevData.soloparentid_num || originalData.soloparentid_num || '' : '', // Restore old or clear
      }));
      return;
  }


    // Handling nested object updates (permanentAddress and presentAddress)
    if (name.startsWith('permanentAddress.') || name.startsWith('presentAddress.')) {
      const [addressType, addressField] = name.split('.');
      setFormData((prevData) => ({
          ...prevData,
          [addressType]: {
              ...prevData[addressType],
              [addressField]: updatedValue,
          },
      }));

      // If "Same as Permanent Address" is checked, update presentAddress too
      if (sameAsPermanent && addressType === 'permanentAddress') {
          setFormData((prevData) => ({
              ...prevData,
              presentAddress: { ...prevData.permanentAddress, [addressField]: updatedValue },
          }));
      }
      return;
  }

  setFormData((prevData) => ({
    ...prevData,
    [name]: updatedValue
  }));
};

   // ✅ Handle Profile Picture Change
   const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  };

    const fetchHouseholdByResident = async (residentId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/resident/${residentId}`); 
  
        if (response.data) {
          const { householdHeadName, householdHeadContactNumber, members, householdID } = response.data;
  
          setHouseholdHead(householdHeadName || 'N/A');
          setHouseholdHeadContactNumber(householdHeadContactNumber || 'N/A');
          setHouseholdMembers(members || []);
          setHouseholdNumber(householdID || 'N/A');
        } else {
          setHouseholdHead('N/A');
          setHouseholdHeadContactNumber('N/A');
          setHouseholdMembers([]);
          setHouseholdNumber('N/A');
        }
      } catch (error) {
        console.error('Error fetching household info:', error);
      }
    };

    const handleValidIDChange = (e) => {
      const files = Array.from(e.target.files);
  
      setAttachedFiles(prevFiles => [
          ...prevFiles, // Keep previously attached files
          ...files.map(file => ({ file })) // Add new files as objects
      ]);
  };  
  
  const handleRemoveFile = (index) => {
    const fileToRemove = attachedFiles[index];
    if (fileToRemove.url) {
        setRemovedValidIDs((prev) => [...prev, fileToRemove.url]);
    }
    setAttachedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
};

    
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.age < 15) {
        Swal.fire({
            icon: 'error',
            title: 'Age Restriction',
            text: 'You must be at least 15 years old to register.',
        });
        return;
    }

    let updatedChanges = {};

      Object.keys(formData).forEach((key) => {
        if (key === "permanentAddress" || key === "presentAddress") {
            let addressChanges = {};
            Object.keys(formData[key]).forEach((subKey) => {
                if (formData[key][subKey] !== originalData[key][subKey]) {
                    addressChanges[subKey] = {
                        old: originalData[key][subKey] || "Not Set",
                        new: formData[key][subKey] || "Not Set"
                    };
                }
            });

            if (Object.keys(addressChanges).length > 0) {
                updatedChanges[key] = addressChanges;
            }
        } else if (formData[key] !== originalData[key]) {
            updatedChanges[key] = {
                old: originalData[key] || "Not Set",
                new: formData[key] || "Not Set"
            };
        }
    });

    if (selectedFile) {
        updatedChanges["profilepic"] = {
            old: originalData.profilepic || "No Previous Image",
            new: URL.createObjectURL(selectedFile),
        };
    }

 // Track changes for valid IDs
 const oldValidIDs = originalData.validIDs || [];
 const newValidIDs = attachedFiles.map(item => item.url || "New File Added");
 const removedFiles = oldValidIDs.filter(url => !attachedFiles.some(item => item.url === url));

 if (JSON.stringify(oldValidIDs) !== JSON.stringify(newValidIDs)) {
     updatedChanges["validIDs"] = {
         old: oldValidIDs.length
             ? oldValidIDs.map(id => ({ url: id }))
             : [{ url: "No Previous IDs" }],
         new: newValidIDs.length
             ? attachedFiles.map(item => ({ url: item.file ? URL.createObjectURL(item.file) : item.url }))
             : [{ url: "No New IDs Uploaded" }]
     };
 }

    if (Object.keys(updatedChanges).length === 0) {
        Swal.fire('No Changes', 'No updates were made.', 'info');
        return;
    }

    setChanges(updatedChanges);
    setShowModal(true);
};


const confirmSubmit = async () => {
  setShowModal(false);

  try {
      const token = localStorage.getItem('token');
      const updatedFormData = new FormData();

      Object.keys(formData).forEach((key) => {
        if (formData[key] !== originalData[key]) {

            // ✅ Ensure permanentAddress and presentAddress are JSON stringified
            if (key === "permanentAddress" || key === "presentAddress") {
                updatedFormData.append(key, JSON.stringify(formData[key]));
            } else {
                updatedFormData.append(key, formData[key]);
            }
        }
    });

      if (selectedFile) {
          updatedFormData.append('profilepic', selectedFile);
      }

        // ✅ Separate newly added files and existing URLs
        const newFiles = attachedFiles.filter(item => item.file).map(item => item.file);
        const existingUrls = attachedFiles.filter(item => item.url).map(item => item.url);

        newFiles.forEach(file => {
            updatedFormData.append('validIDs', file);
        });

        // ✅ Handle file removals
        const removedFiles = originalData.validIDs.filter(url => !existingUrls.includes(url));
        if (removedFiles.length > 0) {
            updatedFormData.append('removedFiles', JSON.stringify(removedFiles));
        }

        const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/${residentID}`, updatedFormData, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
          },
      });

      if (response.status === 200) {
          Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Resident details updated successfully.',
          });
          navigate(-1);
      }

  } catch (error) {
      console.error('Error updating resident:', error);

      // ✅ Handle errors with Swal
      if (error.response) {
          const { status, data } = error.response;
          let errorMessage = 'Something went wrong. Please try again.';

          if (status === 400) {
              errorMessage = data.message || 'Bad request. Please check your input.';
          } else if (status === 401) {
              errorMessage = 'Unauthorized. Please log in again.';
          } else if (status === 404) {
              errorMessage = 'Resident not found.';
          } else if (status === 500) {
              errorMessage = 'Server error. Please contact support.';
          }

          Swal.fire({
              icon: 'error',
              title: `Error ${status}`,
              text: errorMessage,
          });

      } else {
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to update resident details. Please check your network connection.',
          });
      }
  }
};

  if (!formData) return <div>Loading...</div>;

  const getCurrentDate = () => {
    const date = new Date();
    return date.toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
};

const handleLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  navigate('/');
};


  return (
    <div className="flex flex-col min-h-screen">
        <Header userName={userName} userRole={userRole} handleLogout={handleLogout} profilePic={adminData?.profilepic}/>
        <div className="flex flex-1">
            <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
        <main className="flex-1 p-8 bg-gray-100">
          <div className="flex items-center mb-7">
            <button className="text-xl text-gray-500 hover:text-[#1346AC] font-semibold mr-10" onClick={() =>  navigate('/ResidentManagement', { state: { activeTab: 'Residents' } })}>
              &lt; Back
            </button>
            <h1 className="text-4xl font-bold">Edit Resident</h1>
          </div>

          <form onSubmit={handleSubmit}>
          
          <div className='flex w-full bg-white rounded-lg shadow-md'>
          <div className="bg-white rounded-lg w-1/5 flex flex-col justify-between items-center p-6">
          <div className='w-full items-center flex flex-col'>
              {preview ? (
                  <img src={preview} alt="Profile" className="w-52 h-52 rounded-md object-cover border border-gray-300 shadow-md" />
              ) : (
                  <div className="w-52 h-52 rounded-md bg-gray-200 flex items-center justify-center border border-gray-300 shadow-md">
                      <span className="text-gray-500">No Image</span>
                  </div>
              )}

              {/* Hidden File Input */}
              <input 
                  type="file" 
                  accept="image/*" 
                  onChange={handleFileChange} 
                  id="profile-pic-upload" 
                  className="hidden" 
              />

              {/* Custom Button to Trigger File Input */}
              <button 
                  type="button" 
                  onClick={() => document.getElementById('profile-pic-upload').click()} 
                  className="mt-2 px-4 py-1 bg-[#1346AC] text-white font-semibold rounded-full hover:bg-blue-600"
              >
                  Change Profile Picture
              </button>
          </div>

          <div className='w-full'>
              <button type="button" onClick={handleSubmit} className="mt-6 w-full px-4 py-2 bg-[#1346AC] text-white font-semibold rounded-full hover:bg-blue-600">
                  Save Changes
              </button>
          </div>
      </div>
            <div className="w-4/5 overflow-y-auto max-h-[800px] p-6 px-6">
              <h2 className="text-2xl font-semibold mb-4">Household Information</h2>
              <div className="flex justify-between w-full">
              <div className='flex flex-col'><span className="font-medium">Household Role</span> {formData.roleinHousehold || '-'}</div>
              <div className='flex flex-col' ><span className="font-medium">Household No.</span> {householdNumber || '-'}</div>
              <div className='flex flex-col' ><span className="font-medium">Household Head</span>{householdHead || '-'}</div>
              <div className='flex flex-col'><span className="font-medium">Relationship to Household Head</span> {formData.reltohouseholdhead || '-'}</div>
              </div>

              <h2 className="text-2xl font-semibold mt-4 mb-2">Household Members</h2>
              <table  className="min-w-full bg-white">
                <thead>
                  <tr className="border text-center">
                    <th className="py-2 border-r">Resident No.</th>
                    <th className="py-2 border-r">Name</th>
                    <th className="py-2 border-r">Sex</th>
                    <th className="py-2 border-r">Age</th>
                    <th className="py-2 border-r">Role</th>
                    <th className="py-2 border-r">Contact Number</th>
                  </tr>
                </thead>
                <tbody>
                  {householdMembers.length > 0 ? (
                    householdMembers.map((member, index) => (
                      <tr key={index} className="border text-center">
                        <td className="py-2 border-r">{member.residentID}</td>
                        <td className="py-2 border-r">{`${member.firstName} ${member.middleName || ''} ${member.lastName}`}</td>
                        <td className="py-2 border-r">{member.sex}</td>
                        <td className="py-2 border-r">{member.age}</td>
                        <td className="py-2 border-r">{member.roleinHousehold}</td>
                        <td className="py-2 border-r">{member.contactNumber || 'N/A'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center py-2">No household members found.</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <h2 className="text-2xl font-semibold mt-6 mb-6">Personal Information</h2>
              <div className="grid grid-cols-4 gap-4">
              <div >
                <label className="block text-md font-medium text-gray-700">First Name</label>
                <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange}
                 className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md" 
                 required />
                 {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Middle Name</label>
                <input type="text" name="middleName" value={formData.middleName} onChange={handleInputChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md" />
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Last Name</label>
                <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange}
                 className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md" 
                 required />
                 {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Suffix</label>
                <select name="suffix" value={formData.suffix} onChange={handleInputChange} 
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md">
                  <option value="">None</option>
                  <option value="JR.">Jr.</option>
                  <option value="SR.">Sr.</option>
                  <option value="III">III</option>
                  <option value="IV">IV</option>
                  <option value="V">V</option>
                </select>
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Sex</label>
                <select name="sex" value={formData.sex} onChange={handleInputChange} 
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Birthdate</label>
                <input type="date" name="birthday" value={formData.birthday.split('T')[0]} onChange={handleInputChange}
                 className="mt-1 block w-full pl-3 pr-2 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md" required />
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Birthplace</label>
                <input type="text" name="birthplace" value={formData.birthplace} onChange={handleInputChange}
                  className="border mt-1 block w-full p-2 rounded-md" required />
              </div>

              <div>
                  <label className="block text-md font-medium text-gray-700">Age</label>
                  <input
                      type="number"
                      name="age"
                      value={formData.age}
                      onChange={handleInputChange}
                      readOnly
                      className="mt-1 block w-full pl-3 pr-2 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                  />
              </div>

              <div>
                <Occupation selectedOccupation={formData.occupation} handleChange={handleInputChange} />
              </div>

              <div>
                <Nationalities selectedNationality={formData.nationality} handleChange={handleInputChange} />
              </div>

              <div>
                <Religions selectedReligion={formData.religion} handleChange={handleInputChange} />
              </div>

              <div>
                  <label className="block text-md font-medium text-gray-700">Civil Status</label>
                  <select
                      name="civilStatus"
                      value={formData.civilStatus}
                      onChange={handleInputChange}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                  >
                      <option value="">Select Civil Status</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Separated">Separated</option>
                      <option value="Annulled">Annulled</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                  </select>
              </div>
              
              <div>
                <label className="block text-md font-medium text-gray-700">Email Address</label>
                <input type="email" name="email" value={formData.email} onChange={handleInputChange}
                  className="border mt-1 block w-full p-2 rounded-md" required />
                  {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Contact Number</label>
                <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleInputChange}
                  className="border mt-1 block w-full p-2 rounded-md" required />
              </div>
              <div className="flex items-center">
                <input
                        type="checkbox"
                        name="voter"
                        checked={formData.voter}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                        disabled={formData.age < 16} 
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Voter</label>
                    <input
                        type="checkbox"
                        name="indigent"
                        checked={formData.indigent}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Indigent</label>
                    <input
                        type="checkbox"
                        name="fourpsmember"
                        checked={formData.fourpsmember}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">4Ps</label>

                    <input
                        type="checkbox"
                        name="outofschoolchildren"
                        checked={formData.outofschoolchildren}
                        disabled
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Out-of-School <br/> Children</label>
                    <input
                        type="checkbox"
                        name="outofschoolyouth"
                        checked={formData.outofschoolyouth}
                        disabled={!(formData.age >= 15 && formData.age <= 24)}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Out-of-School <br/> Youth</label>
                    <input
                        type="checkbox"
                        name="indigenouspeople"
                        checked={formData.indigenouspeople}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Indigenous <br/> People</label>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 mt-4">
              {/* PWD Section */}
              <div className="space-y-2">
                  <div className="flex items-center">
                      <input
                          type="checkbox"
                          name="pwd"
                          checked={formData.pwd}
                          onChange={handleInputChange}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label className="ml-2 block text-md text-gray-900">PWD</label>
                  </div>
                  <div>
                      <label className="block text-md font-medium text-gray-700">PWD ID No.</label>
                      <input
                          type="text"
                          name="pwdid_num"
                          value={formData.pwdid_num}
                          onChange={handleInputChange}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                          placeholder="Enter PWD ID No."
                          disabled={!formData.pwd}
                      />
                  </div>

                  {formData.pwd && (
                      <div>
                          <label className="block text-md font-medium text-gray-700">Type of Disability</label>
                          <input
                              type="text"
                              name="typeofdisability"
                              value={formData.typeofdisability}
                              onChange={handleInputChange}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                              placeholder="Enter Type of Disability"
                          />
                      </div>
                  )}
              </div>

              {/* Solo Parent Section */}
              <div className="space-y-2">
                  <div className="flex items-center">
                      <input
                          type="checkbox"
                          name="soloparent"
                          checked={formData.soloparent}
                          onChange={handleInputChange}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label className="ml-2 block text-md text-gray-900">Solo Parent</label>
                  </div>
                  <div>
                      <label className="block text-md font-medium text-gray-700">Solo Parent ID No.</label>
                      <input
                          type="text"
                          name="soloparentid_num"
                          value={formData.soloparentid_num}
                          onChange={handleInputChange}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                          placeholder="Enter Solo Parent ID No."
                          disabled={!formData.soloparent}
                      />
                  </div>
              </div>

              {/* Senior Citizen Section */}
              <div className="space-y-2">
                  <div className="flex items-center">
                      <input
                          type="checkbox"
                          name="seniorCitizen"
                          checked={formData.seniorCitizen}
                          onChange={handleInputChange}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                          disabled
                      />
                      <label className="ml-2 block text-md text-gray-900">Senior Citizen</label>
                  </div>
                  <div>
                      <label className="block text-md font-medium text-gray-700">Senior Citizen ID No.</label>
                      <input
                          type="text"
                          name="seniorcitizenid_num"
                          value={formData.seniorcitizenid_num}
                          onChange={handleInputChange}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                          placeholder="Enter Senior Citizen ID No."
                          disabled={!formData.seniorCitizen}
                      />
                  </div>
              </div>
            </div>
            <h2 className="text-2xl font-semibold mt-6 mb-6">Permanent Address</h2>
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <label className="block text-md font-medium text-gray-700">Unit/Floor/Room No.</label>
                    <input
                        type="text"
                        name="permanentAddress.unitFloorRoomNo"
                        value={formData.permanentAddress.unitFloorRoomNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Unit/Floor/Room No."
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Building</label>
                    <input
                        type="text"
                        name="permanentAddress.building"
                        value={formData.permanentAddress.building}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Building"
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Block No.</label>
                    <input
                        type="text"
                        name="permanentAddress.blockNo"
                        value={formData.permanentAddress.blockNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Block No."
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Lot No.</label>
                    <input
                        type="text"
                        name="permanentAddress.lotNo"
                        value={formData.permanentAddress.lotNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Lot No."
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Phase No.</label>
                    <input
                        type="text"
                        name="permanentAddress.phaseNo"
                        value={formData.permanentAddress.phaseNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Phase No."
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">House No.</label>
                    <input
                        type="text"
                        name="permanentAddress.houseNo"
                        value={formData.permanentAddress.houseNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter House No."
                        required
                    />
                     {errors.houseNo && <p className="text-red-500 text-xs mt-1">{errors.houseNo}</p>}
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Street</label>
                    <input
                        type="text"
                        name="permanentAddress.street"
                        value={formData.permanentAddress.street}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Street"
                        required
                    />
                    {errors.street && <p className="text-red-500 text-xs mt-1">{errors.street}</p>}
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Subdivision</label>
                    <input
                        type="text"
                        name="permanentAddress.subdivision"
                        value={formData.permanentAddress.subdivision}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Subdivision"
                    />
                </div>
            </div>

            <PresentAddress formData={formData} handleInputChange={handleInputChange} errors={errors} />
                  <div className="flex items-center justify-start mt-8 flex-wrap">
                      <label className="text-md font-medium text-gray-700 mr-2">
                          Same as Permanent Address
                      </label>
                      <input
                          type="checkbox"
                          name="sameAsPermanent"
                          checked={sameAsPermanent}
                          onChange={handleInputChange}
                          className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                  </div>

            <h2 className="text-2xl font-semibold mt-6 mb-6">Other Information</h2>
                <div className="grid grid-cols-3 gap-4">
                    <div>
                        <label className="block text-md font-medium text-gray-700">PhilSys No.</label>
                        <input
                            type="text"
                            name="philsys_num"
                            value={formData.philsys_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter PhilSys No."
                        />
                    </div>
                    <div>
                    <label className="block text-md font-medium text-gray-700">Voters ID No. (Optional)</label>
                    <input
                        type="text"
                        name="voters_id"
                        value={formData.voters_id}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Voters ID No. (Optional)"
                        disabled={!formData.voter} 
                    />
                    </div>
                    <div>
                        <label className="block text-md font-medium text-gray-700">SSS ID No.</label>
                        <input
                            type="text"
                            name="sss_num"
                            value={formData.sss_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter SSS ID No."
                        />
                    </div>
                    <div>
                        <label className="block text-md font-medium text-gray-700">Pag-IBIG MID No.</label>
                        <input
                            type="text"
                            name="pagibig_num"
                            value={formData.pagibig_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter Pag-IBIG MID No."
                        />
                    </div>
                    <div>
                        <label className="block text-md font-medium text-gray-700">Philhealth No.</label>
                        <input
                            type="text"
                            name="philhealth_num"
                            value={formData.philhealth_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter Philhealth No."
                        />
                    </div>
                    <div>
                        <label className="block text-md font-medium text-gray-700">TIN</label>
                        <input
                            type="text"
                            name="tin_num"
                            value={formData.tin_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter TIN"
                        />
                    </div>
                </div>

                <div className='mt-4'>
                <h2 className="text-2xl font-semibold mb-4">Valid IDs</h2>
              <input type="file" multiple onChange={handleValidIDChange} className="hidden" id="valid-id-upload" />
              <label htmlFor="valid-id-upload" className="cursor-pointer bg-[#1346AC] hover:bg-blue-700 text-white py-2 px-4 rounded-full">
                Upload Valid IDs
              </label>
              <div className="mt-4 flex flex-wrap gap-4">
                {attachedFiles.map((item, index) => (
                  <div key={index} className="flex flex-col items-center">
                    {item.file ? (
                      <img src={URL.createObjectURL(item.file)} alt="Valid ID" className="max-w-xs max-h-xs object-cover" />
                    ) : (
                      <img src={item.url} alt="Valid ID" className="max-w-xs max-h-xs object-cover" />
                    )}
                    <button type="button" className="bg-red-500 text-white px-2 py-1 rounded-full mt-2" onClick={() => handleRemoveFile(index)}>
                      Remove
                    </button>
                  </div>
                ))}
              </div>
                </div>
            </div>
            </div>
          </form>
        </main>
        {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[500px]">
            <h2 className="text-2xl font-semibold mb-4">Confirm Changes</h2>
            
            <div className="max-h-60 overflow-y-auto border p-3">
              
              {/* ✅ Display Profile Picture Changes Separately */}
              {changes.profilepic && (
                  <div className="mb-4">
                      <p className="font-medium">Profile Picture:</p>
                      <div className="flex items-center space-x-4">
                          <div className="flex flex-col items-center">
                              <p className="text-sm text-gray-600"><b>Old:</b></p>
                              <img src={changes.profilepic.old} alt="Old Profile" className="w-24 h-24 object-cover border border-gray-300 shadow-md" />
                          </div>
                          <div className="flex flex-col items-center">
                              <p className="text-sm text-green-600"><b>New:</b></p>
                              <img src={changes.profilepic.new} alt="New Profile" className="w-24 h-24 object-cover border border-gray-300 shadow-md" />
                          </div>
                      </div>
                  </div>
              )}

              {/* ✅ Display Old and New Valid IDs */}
                {changes.validIDs && (
                    <div className="mb-4">
                        <p className="font-medium">Valid IDs:</p>
                        
                        {/* Old Valid IDs */}
                        <div className="mb-2">
                            <p className="text-sm text-gray-600"><b>Old:</b></p>
                            <div className="flex flex-wrap gap-2">
                                {changes.validIDs.old.map((item, index) => (
                                    <img key={index} src={item.url !== "No Previous IDs" ? item.url : null} 
                                        alt="Old Valid ID" 
                                        className="w-16 h-16 object-cover border shadow-md" />
                                ))}
                            </div>
                        </div>

                        {/* New Valid IDs */}
                        <div>
                            <p className="text-sm text-green-600"><b>New:</b></p>
                            <div className="flex flex-wrap gap-2">
                                {changes.validIDs.new.map((item, index) => (
                                    <img key={index} src={item.url !== "No New IDs Uploaded" ? item.url : null} 
                                        alt="New Valid ID" 
                                        className="w-16 h-16 object-cover border shadow-md" />
                                ))}
                            </div>
                        </div>
                    </div>
                )}

              {/* ✅ Display Address Changes Separately */}
              {changes.permanentAddress && (
                  <div className="mb-4">
                      <p className="font-medium">Permanent Address Changes:</p>
                      {Object.entries(changes.permanentAddress).map(([field, values]) => (
                          <div key={field}>
                              <p className="text-sm text-gray-600"><b>{field.replace(/([A-Z])/g, " $1")}:</b></p>
                              <p className="text-sm text-gray-600"><b>Old:</b> {values.old}</p>
                              <p className="text-sm text-green-600"><b>New:</b> {values.new}</p>
                          </div>
                      ))}
                  </div>
              )}

              {changes.presentAddress && (
                  <div className="mb-4">
                      <p className="font-medium">Present Address Changes:</p>
                      {Object.entries(changes.presentAddress).map(([field, values]) => (
                          <div key={field}>
                              <p className="text-sm text-gray-600"><b>{field.replace(/([A-Z])/g, " $1")}:</b></p>
                              <p className="text-sm text-gray-600"><b>Old:</b> {values.old}</p>
                              <p className="text-sm text-green-600"><b>New:</b> {values.new}</p>
                          </div>
                      ))}
                  </div>
              )}

              {/* ✅ Display Other Changes */}
              {Object.entries(changes).map(([key, { old, new: newValue }]) => (
                key !== "profilepic" && key !== "validIDs" && key !== "permanentAddress" && key !== "presentAddress" && (
                  <div key={key} className="mb-4">
                    <p className="font-medium">{key.replace(/([A-Z])/g, " $1")}:</p>
                    <p className="text-sm text-gray-600">
                      <b>Old:</b> {typeof old === 'object' ? JSON.stringify(old, null, 2) : old}
                    </p>
                    <p className="text-sm text-green-600">
                      <b>New:</b> {typeof newValue === 'object' ? JSON.stringify(newValue, null, 2) : newValue}
                    </p>
                  </div>
                )
              ))}
            </div>

            <div className="mt-4 flex justify-end space-x-3">
              <button onClick={() => setShowModal(false)} className="px-4 py-2 bg-gray-400 hover:bg-gray-300 text-gray-700 rounded-md">
                Cancel
              </button>
              <button onClick={confirmSubmit} className="px-4 py-2 bg-[#1346AC] text-white rounded-md hover:bg-blue-600">
                Confirm & Save
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default EditResident;
