import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const DocumentRequest = () => {
    const [documentRequests, setDocumentRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const requestsPerPage = 5; // Max content per page is 5
    const [pageRange, setPageRange] = useState([1, 2, 3, 4]); // Only 4 pagination buttons visible at a time
    const navigate = useNavigate();

    // Fetch document requests from the API
    useEffect(() => {
        const fetchDocumentRequests = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/document-requests`);
                const sortedData = response.data.requests.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setDocumentRequests(sortedData);
                setFilteredRequests(sortedData);
            } catch (error) {
                console.error("Error fetching document requests", error);
            }
        };

        fetchDocumentRequests();
    }, []);

    // Calculate the index of the first and last request on the current page
    const indexOfLastRequest = currentPage * requestsPerPage;
    const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
    const currentRequests = filteredRequests.slice(indexOfFirstRequest, indexOfLastRequest);

    // Calculate total pages
    const totalPages = Math.ceil(filteredRequests.length / requestsPerPage);

    // Handle page change
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        if (pageNumber > pageRange[3] && pageNumber <= totalPages) {
            setPageRange(pageRange.map(page => page + 1));
        }
        if (pageNumber < pageRange[0] && pageNumber >= 1) {
            setPageRange(pageRange.map(page => page - 1));
        }
    };

    // Handle Previous button click
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
            if (currentPage <= pageRange[0]) {
                setPageRange(pageRange.map(page => page - 1));
            }
        }
    };

    // Handle Next button click
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
            if (currentPage >= pageRange[3]) {
                setPageRange(pageRange.map(page => page + 1));
            }
        }
    };

    return (
        <div className="bg-white p-6 rounded shadow">
            <div className="flex justify-between mb-4">
                <h2 className="text-lg font-semibold">Document Requests</h2>
                <button
                    className="bg-[#1346AC] text-white px-4 py-2 rounded-full hover:bg-blue-700 font-semibold"
                    onClick={() => navigate('/Document-Request')}
                >
                    View Document Requests
                </button>
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border text-center">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b border-r">Date Requested</th>
                            <th className="py-2 px-4 border-b border-r ">Document Type</th>
                            <th className="py-2 px-4 border-b ">Requestor Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRequests.length > 0 ? (
                            currentRequests.map((request, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-4 border-b border-r truncate">{new Date(request.created_at).toLocaleDateString()}</td>
                                    <td className="py-2 px-4 border-b border-r truncate ">{request.documentType}</td>
                                    <td className="py-2 px-4 border-b truncate">{`${request.requestedBy.firstName} ${request.requestedBy.middleName ? request.requestedBy.middleName + ' ' : ''}${request.requestedBy.lastName}`}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="py-2 px-4 text-center">No document requests available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Pagination Section */}
            <div className="flex justify-between items-end mt-4">
                <div className="text-sm text-gray-600" >
                    Showing {Math.min(indexOfLastRequest, filteredRequests.length)} of {filteredRequests.length} entries
                </div>
                <div className="flex items-center space-x-2 font-semibold">
                    <button
                        onClick={handlePrevious}
                        className="px-3 py-1 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    {pageRange.map((pageNumber) => (
                        <button
                            key={pageNumber}
                            onClick={() => paginate(pageNumber)}
                            className={`px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-[#1346AC] text-white' : 'bg-gray-200 hover:bg-[#1346AC] hover:text-white'}`}
                            disabled={pageNumber > totalPages}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        onClick={handleNext}
                        className="px-3 py-1 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentRequest;
