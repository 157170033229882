import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ResidentNav from '../../../component/Resident/ResidentNav';
import ResidentHeader from '../../../component/Resident/ResidentHeader';
import { useNavigate } from 'react-router-dom';
import ResidentDocumentRequestModal from '../../../component/Resident/ResidentDocumentRequestModal';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import NestedDropdown from '../../../assets/dropdowns/NestedDropdown';
import DocReqDataPrivAgreement from '../../../assets/dataprivacyandtermsandconditions/DocReqDataPrivAgreement';
import DocReqTermsandConditions from '../../../assets/dataprivacyandtermsandconditions/DocReqTermsandConditions';

const ResidentDocRequest = () => {
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [residentData, setResidentData] = useState(null);
    const [formData, setFormData] = useState({
        documentType: '',
        otherDocumentType: '',
        purpose: '',
        recipientID: '',
        otherPurpose: '',
        recipient: '',
        validID: [], 
        residentName: '',
    });
    const [errors, setErrors] = useState({});
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(null); 
    const [showError, setShowError] = useState(false);
    const [documentRequests, setDocumentRequests] = useState([]);
    const [filters, setFilters] = useState({ category: 'All', status: 'All', purpose: 'All' });
    const [householdMembers, setHouseholdMembers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('date');
    const [sortDirection, setSortDirection] = useState('desc');
    const [isRecipientUser, setIsRecipientUser] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [referenceNo, setReferenceNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [selectedSortText, setSelectedSortText] = useState('Sort by Date');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4; // Or any other value depending on the design
    const [pageRange, setPageRange] = useState([1, 2, 3, 4]); // Initial page range
    
    
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setResidentData(user);
            setUserName(`${user.firstName} ${user.middleName ? user.middleName.charAt(0) + '.' : ''} ${user.lastName}`);
            setUserRole(user.roleinHousehold);
            setFormData((prevData) => ({
                ...prevData,
                residentName: ` ${user.firstName} ${user.middleName ? user.middleName.charAt(0) + '.' : ''} ${user.lastName}`, 
            }));
            fetchDocumentRequests(); // Fetch requests after setting user info

            // Fetch household members
            if (user.householdID) {
                fetchHouseholdMembers(user.householdID); // Fetch household members by household ID
            }
        }
        
    }, [filters]);

    // Fetch household members from API with loading state
    const fetchHouseholdMembers = async (householdId) => {
        setLoading(true); // Set loading to true
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/id/${householdId}`);
            const members = response.data.members;
            setHouseholdMembers(members);  
        } catch (error) {
            console.error('Error fetching household members:', error);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };
    
    const handleSetRecipientAsUser = () => {
        setIsRecipientUser(!isRecipientUser);
        const user = JSON.parse(localStorage.getItem('user'));
        if (!isRecipientUser && user) {
            setFormData({
                ...formData,
                recipient: `${user.firstName} ${user.middleName ? user.middleName.charAt(0) + '.' : ''} ${user.lastName}`,
                recipientID: user._id // Append user's own ID as recipientID
            });
        } else {
            setFormData({
                ...formData,
                recipient: '', // Clear recipient if checkbox is unchecked
                recipientID: '' // Clear recipientID
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData({ ...formData, validID: [...formData.validID, ...files] }); 
    };


    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault(); // This will only run if the event is passed
        }
    
        if (!validateForm()) {
            return;
        }

        if (!validateForm() || !termsAccepted) {
            setShowError(true);
            return;
        }

    const user = JSON.parse(localStorage.getItem('user'));

    // Check if the accountStatus is "Pending"
    if (user.accountStatus === 'Pending') {
        Swal.fire({
            icon: 'error',
            title: 'Account Pending',
            text: 'Your account is still pending approval. Please wait for your account to be approved before creating a document request.',
        });
        return; // Stop further execution
    }
    
        // Show loading Swal while processing the request
        Swal.fire({
            title: 'Processing...',
            text: 'Please wait while we process your document request.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading(); 
            },
        });
    

        const isAdmin = user.roleinBarangay && ['Barangay Captain', 'Secretary', 'Kagawad'].includes(user.roleinBarangay);
        const requestedByType = isAdmin ? 'Admin' : 'Resident';
    
        const formDataToSend = new FormData();
        formDataToSend.append('requestedBy', user._id);
        formDataToSend.append('requestedByType', requestedByType);
        formDataToSend.append('documentType', formData.documentType === 'Others' ? formData.otherDocumentType : formData.documentType);
        formDataToSend.append('purpose', formData.purpose === 'Others' ? formData.otherPurpose : formData.purpose);
        formDataToSend.append('recipient', formData.recipient);
        formDataToSend.append('recipientID', formData.recipientID);
        formDataToSend.append('residentName', formData.residentName);
    
        if (formData.validID && formData.validID.length > 0) {
            formData.validID.forEach((file) => {
                formDataToSend.append('ValidID', file); 
            });
        }
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_KEY}/api/new/document-requests`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            // Ensure that response contains the expected data
            if (response.data && response.data.request && response.data.request.ReferenceNo) {
                setReferenceNo(response.data.request.ReferenceNo);
    
                // Show success alert with the reference number
                await Swal.fire({
                    icon: 'success',
                    title: 'Request Submitted',
                    text: `Your Reference Number is: ${response.data.request.ReferenceNo}`,
                });
    
                // Reset form and states after success alert is dismissed
                setFormData({
                    documentType: '',
                    otherDocumentType: '',
                    purpose: '',
                    otherPurpose: '',
                    recipient: '',
                    recipientID: '',
                    validID: [],
                    residentName: ` ${user.firstName} ${user.middleName ? user.middleName.charAt(0) + '.' : ''} ${user.lastName}`,
                });
                setTermsAccepted(false);
                setIsRecipientUser(false);
    
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; 
                }
    
                fetchDocumentRequests(); 
            } else {
                throw new Error('Unexpected response structure.');
            }
        } catch (error) {
            console.error('Error creating document request:', error);
    
            Swal.fire({
                icon: 'error',
                title: 'Request Failed',
                text: 'An error occurred while processing your request. Please try again.',
            });
        }
    };    
    
    const resetFilters = () => {
        setFilters({
            category: 'All',
            status: 'All',
            purpose: 'All',
        });
        setSearchTerm('');
        fetchDocumentRequests(); 
    };
    

    const validateForm = () => {
        let newErrors = {};
        if (!formData.documentType) newErrors.documentType = 'Document type is required';
        if (formData.documentType === 'Others' && !formData.otherDocumentType) newErrors.otherDocumentType = 'Please specify the document type';
        if (!formData.purpose) newErrors.purpose = 'Purpose is required';
        if (formData.purpose === 'Others' && !formData.otherPurpose) newErrors.otherPurpose = 'Please specify the purpose';
        if (!formData.recipient) newErrors.recipient = 'Recipient is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const fetchDocumentRequests = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        
        if (!user || !user._id) {
            console.error('User not found or missing _id');
            return;
        }
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/document-requests/history/${user._id}`);
            const requests = response.data.map(request => {
                if (request.status === 'Archived' && request.archived_at) {
                    // Calculate the number of days until deletion (assuming 90 days after archived)
                    const archivedAt = new Date(request.archived_at);
                    const ninetyDaysLater = new Date(archivedAt);
                    ninetyDaysLater.setDate(archivedAt.getDate() + 90);
    
                    const daysUntilDeletion = Math.ceil((ninetyDaysLater - new Date()) / (1000 * 60 * 60 * 24));
    
                    // Add daysUntilDeletion to the request object
                    return {
                        ...request,
                        daysUntilDeletion: daysUntilDeletion > 0 ? daysUntilDeletion : 0, // Ensure it's not negative
                    };
                }
                return request;
            });
    
            setDocumentRequests(requests);  // Update the state with the processed requests
        } catch (error) {
            console.error('Error fetching document requests:', error.response ? error.response.data : error.message);
        }
    };
    
    

    const sortedRequests = documentRequests.sort((a, b) => {
        if (sortOrder === 'date') {
            return sortDirection === 'desc' ? new Date(b.created_at) - new Date(a.created_at) : new Date(a.created_at) - new Date(b.created_at);
        } else if (sortOrder === 'documentType') {
            return a.documentType.localeCompare(b.documentType);
        } else if (sortOrder === 'status') {
            return a.status.localeCompare(b.status);
        }
        return 0;
    });
    
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    // Handle opening modal
    const handleOpenModal = (request) => {
        setSelectedRequest(request);
        setIsModalOpen(true);
    };

    // Handle closing modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedRequest(null);
    };

    // Handle opening Terms and Conditions modal
    const openTermsModal = () => {
        setShowTermsModal('terms');
    };

    // Handle opening Data Privacy Agreement modal
    const openPrivacyModal = () => {
        setShowTermsModal('privacy');
    };

       // Handle sort direction change
       const handleSortChange = (direction) => {
        // Reset any status filters when sorting by date
        setFilters({ ...filters, status: 'All' }); // Reset status filter when switching to date sort
        setSortOrder('date'); // Set sort by date
        setSortDirection(direction); // Set sort direction (asc or desc)
        setSelectedSortText(`Sort by Date: ${direction === 'desc' ? 'Latest to Oldest' : 'Oldest to Latest'}`);
    };

    // Handle purpose filter change
    const handlePurposeFilterChange = (e) => {
        setFilters({ ...filters, purpose: e.target.value });
    };
    
    const filteredRequests = documentRequests
    .filter((request) => {
        const validDocumentTypes = [
            'Barangay Certification', 
            'Barangay Business Clearance', 
            'Certificate of Indigency', 
            'Certificate of Residency', 
            'Certificate of Good Moral Character', 
            'Certificate of Local Employment', 
            'Certificate of Financial Assistance', 
            'Certificate of First Time Jobseeker', 
            'Barangay Clearance', 
            'Barangay Business Permit'
        ];

        // 1. Filter by document type
        const categoryMatch = filters.category === 'All' || 
            (filters.category === 'Others' ? !validDocumentTypes.includes(request.documentType) : request.documentType === filters.category);

        // 2. Filter by purpose
        const purposeMatch = filters.purpose === 'All' || 
            (filters.purpose === 'Others' ? !['Work', 'Open Bank Account', 'Business', 'School', 'Travel'].includes(request.purpose) : request.purpose === filters.purpose);

        // 3. Filter by status
        const statusMatch = filters.status === 'All' ? request.status !== 'Archived' : request.status === filters.status;

        // 4. Search by document type
        const searchMatch = searchTerm ? request.documentType.toLowerCase().includes(searchTerm.toLowerCase()) : true;

        // Only return requests where all conditions match
        return categoryMatch && purposeMatch && statusMatch && searchMatch;
    })
    .sort((a, b) => {
        if (sortOrder === 'date') {
            return sortDirection === 'desc' ? new Date(b.created_at) - new Date(a.created_at) : new Date(a.created_at) - new Date(b.created_at);
        } else if (sortOrder === 'status') {
            return a.status.localeCompare(b.status);
        }
        return 0;
    });



     // Remove a selected file
    const handleRemoveFile = (indexToRemove) => {
        setFormData((prevData) => ({
            ...prevData,
            validID: prevData.validID.filter((_, index) => index !== indexToRemove),
        }));
    };

    const handleUpdateRequest = async (updatedData) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_API_KEY}/api/document-requests/${selectedRequest._id}`,
                updatedData,  // Pass FormData
                { headers: { 'Content-Type': 'multipart/form-data' } } 
            );
            Swal.fire('Success', 'Document request updated successfully!', 'success');
            fetchDocumentRequests();  // Refetch the updated document requests
            handleCloseModal();  // Close the modal
        } catch (error) {
            console.error('Error updating document request:', error);
            Swal.fire('Error', 'Failed to update the document request.', 'error');
        }
    };
    

useEffect(() => {
    // Fetch the document requests here and filter/sort as needed
}, [filters, sortDirection]);

    // Handle pagination logic
    const indexOfLastRequest = currentPage * itemsPerPage;
    const indexOfFirstRequest = indexOfLastRequest - itemsPerPage;
    const currentRequests = filteredRequests.slice(indexOfFirstRequest, indexOfLastRequest);
    
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
    const handleNext = () => {
        const maxPage = Math.ceil(filteredRequests.length / itemsPerPage);
        if (pageRange[3] < maxPage) {
            const newPageRange = pageRange.map((num) => num + 2);
            setPageRange(newPageRange);
            setCurrentPage(newPageRange[0]);
        }
    };
    
    const handlePrevious = () => {
        if (pageRange[0] > 1) {
            const newPageRange = pageRange.map((num) => num - 2);
            setPageRange(newPageRange);
            setCurrentPage(newPageRange[0]);
        }
    };
    

    const renderHouseholdMembers = () => (
        <select
            name="recipient"
            value={formData.recipient}
            onChange={(e) => {
                const selectedMember = householdMembers.find(member => 
                    `${member.firstName} ${member.middleName ? member.middleName.charAt(0) + '.' : ''} ${member.lastName}` === e.target.value
                );
                setFormData({
                    ...formData,
                    recipient: e.target.value,
                    recipientID: selectedMember ? selectedMember.residentID : '' // Set recipientID when a member is selected
                });
            }}
            className="form-select mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
            disabled={isRecipientUser}
        >
            <option value="">Select a Recipient</option>
            {householdMembers.map((member) => (
                <option key={member._id} value={`${member.firstName} ${member.middleName ? member.middleName.charAt(0) + '.' : ''} ${member.lastName}`}>
                    {`${member.firstName} ${member.middleName ? member.middleName.charAt(0) + '.' : ''} ${member.lastName}`}
                </option>
            ))}
        </select>
    );

    const openConfirmModal = () => {
        setShowConfirmModal(true);
    };
    
    const closeConfirmModal = () => {
        setShowConfirmModal(false);
    };
    

    
    return (
        <div className="flex flex-col min-h-screen">
            <ResidentHeader 
                userName={userName} 
                userRole={userRole} 
                handleLogout={handleLogout} 
                profilePic={residentData?.profilepic} 
            />
            <div className="flex flex-1">
            <ResidentNav residentData={residentData}  />
                <main className="flex-1 p-8 bg-gray-100">
                    <h2 className="text-3xl font-bold mb-8">Document Request</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-5 gap-8">
                        <div className="col-span-1 lg:col-span-2 bg-white p-6 rounded-lg shadow-md">
                            <form onSubmit={handleSubmit}>
                                <h3 className="text-2xl mb-4">Create Document Request</h3>

                                <div className="mb-4">
                                    <label className="block text-md font-medium text-gray-700">Type of Document</label>
                                    <select
                                        name="documentType"
                                        value={formData.documentType}
                                        onChange={handleInputChange}
                                        className="form-select mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                    >
                                        <option value="">Select Document Type</option>
                                        <option value="Certificate of Indigency">Certificate of Indigency</option>
                                        <option value="Certificate of Residency">Certificate of Residency</option>
                                        <option value="Certificate of Good Moral Character">Certificate of Good Moral Character</option>
                                        <option value="Certificate of Local Employment">Certificate of Local Employment</option>
                                        <option value="Certificate of Financial Assistance">Certificate of Financial Assistance</option>
                                        <option value="Certificate of First Time Jobseeker">Certificate of First Time Jobseeker</option>
                                        <option value="Barangay Clearance">Barangay Clearance</option>
                                        <option value="Barangay Business Permit">Barangay Business Permit</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {errors.documentType && <p className="text-red-500 text-xs">{errors.documentType}</p>}
                                </div>

                                {formData.documentType === 'Others' && (
                                    <div className="mb-4">
                                        <label className="block text-md font-medium text-gray-700">Specify Other Document Type</label>
                                        <input
                                            type="text"
                                            name="otherDocumentType"
                                            value={formData.otherDocumentType}
                                            onChange={handleInputChange}
                                            className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                            placeholder="Enter document type"
                                        />
                                        {errors.otherDocumentType && <p className="text-red-500 text-xs">{errors.otherDocumentType}</p>}
                                    </div>
                                )}

                                {/* User Full name */}
                                <div className="mb-4">
                                    <label className="block text-md font-medium text-gray-700">Full Name</label>
                                    <input
                                        type="text"
                                        value={formData.residentName} // Non-editable field
                                        className="form-input mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                        disabled
                                    />
                                </div>

                               {/* Editable Recipient Full Name */}
                               <div className="mb-4">
                                        <label className="block text-md font-semibold text-black">Recipient Full Name</label>
                                        <div className="flex items-center gap-4">
                                            {renderHouseholdMembers()}
                                            <div className="flex items-center gap-2">
                                                <span className="text-md font-semibold text-black">I am the Recipient</span>
                                                <input
                                                    type="checkbox"
                                                    name="isRecipientUser"
                                                    checked={isRecipientUser}
                                                    onChange={handleSetRecipientAsUser}
                                                    className="form-checkbox"
                                                />
                                            </div>
                                        </div>
                                        {errors.recipient && <p className="text-red-500 text-xs">{errors.recipient}</p>}
                                    </div>

                                <div className="mb-4">
                                    <label className="block text-md font-medium text-gray-700">Purpose of Request</label>
                                    <select
                                        name="purpose"
                                        value={formData.purpose}
                                        onChange={handleInputChange}
                                        className="form-select mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                    >
                                        <option value="">Select Purpose</option>
                                        <option value="Work">Work</option>
                                        <option value="Open Bank Account">Open Bank Account</option>
                                        <option value="Business">Business</option>
                                        <option value="School">School</option>
                                        <option value="Travel">Travel</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {errors.purpose && <p className="text-red-500 text-xs">{errors.purpose}</p>}
                                </div>

                                {/* Show input for custom purpose if "Others" is selected */}
                                {formData.purpose === 'Others' && (
                                    <div className="mb-4">
                                        <label className="block text-md font-medium text-gray-700">Specify Other Purpose</label>
                                        <input
                                            type="text"
                                            name="otherPurpose"
                                            value={formData.otherPurpose}
                                            onChange={handleInputChange}
                                            className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                            placeholder="Enter purpose"
                                        />
                                        {errors.otherPurpose && <p className="text-red-500 text-xs">{errors.otherPurpose}</p>}
                                    </div>
                                )}

                                {/* File Upload Field */}
                                <div className="mb-4">
                                    <label className="block text-md font-medium text-gray-700">Attachment</label>
                                    <input 
                                        type="file" 
                                        name="ValidID" 
                                        onChange={handleFileChange} 
                                        ref={fileInputRef}
                                        className="mt-1 block w-full border border-gray-300 rounded-md" 
                                        multiple 
                                    />
                                    {errors.validID && <p className="text-red-500 text-xs">{errors.validID}</p>}
                                </div>

                                <div className="mb-4">
                                <label className="block text-md font-medium text-gray-700">Attached Files</label>
                                {formData.validID && formData.validID.length > 0 ? (
                                    <ul className="space-y-2">
                                        {formData.validID.map((file, index) => (
                                            <div
                                                key={index}
                                                className="relative flex items-center justify-between p-2 border rounded-lg shadow-sm bg-gray-50"
                                            >
                                                <div className="flex items-center">
                                                    <div className="w-10 h-10 bg-[#1346AC] text-white flex items-center justify-center rounded-full mr-3">
                                                        <i className="fas fa-file-alt"></i>
                                                    </div>
                                                    <div className="truncate max-w-xs">
                                                        <span className="text-blue-600 font-semibold truncate">
                                                            {file.name}
                                                        </span>
                                                        <p className="text-gray-500 text-xs">{file.type}</p>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveFile(index)}
                                                    className="absolute right-0 top-0 text-black hover:text-red-600 mr-2"
                                                >
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No files attached.</p>
                                )}
                                </div>

                                <div className="mt-2 mb-2 flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={termsAccepted}
                                        onChange={() => setTermsAccepted(!termsAccepted)}
                                        className="mr-2"
                                    />
                                    <span className="text-md">
                                        I agree to the{' '}
                                        <span
                                            className="text-blue-600 cursor-pointer underline"
                                            onClick={openTermsModal}
                                        >
                                            Terms and Conditions 
                                        </span> 
                                        {' '} and {' '}
                                        <span
                                            className="text-blue-600 cursor-pointer underline"
                                            onClick={openPrivacyModal}
                                        >
                                            Data Privacy Agreement
                                        </span>.
                                    </span>

                                </div>
                                {showError && !termsAccepted && (
                                    <p className="text-red-500 text-md mb-2">You must agree to the Terms and Conditions and Data Privacy Agreement</p>
                                )}
                                <button 
                                type="button" 
                                className="bg-[#1346AC] text-white px-4 py-2 rounded hover:bg-blue-700 mt-2"
                                onClick={openConfirmModal}
                            >
                                Request Document
                            </button>
                            {showConfirmModal && (
                                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center modal-overlay">
                                        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                                            <h3 className="text-xl font-semibold mb-4 text-start">Confirm Submission</h3>
                                            <p className="text-start text-gray-600 mb-4">
                                                Are you sure you want to submit? 
                                            </p>
                                            <p className='text-start text-gray-600 mb-4'>Once the document request is processed, you won't be able to make any changes.</p>
                                            <div className="flex justify-end items-end">
                                                <button
                                                    onClick={closeConfirmModal}
                                                    className="text-[#1346AC] px-4 py-2 rounded-md font-semibold hover:text-blue-700"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        handleSubmit(); // Submit form
                                                        closeConfirmModal(); 
                                                    }}
                                                    className="text-[#1346AC] px-4 py-2 rounded-md font-semibold hover:text-blue-700"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>

                        {showTermsModal && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center modal-overlay">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-1/4 md:w-2/2 max-h-[80vh] scrollbar-thin overflow-y-auto">
                            {showTermsModal === 'terms' && <DocReqTermsandConditions closeModal={() => setShowTermsModal(null)} />}
                            {showTermsModal === 'privacy' && <DocReqDataPrivAgreement closeModal={() => setShowTermsModal(null)} />}
                            <button
                                onClick={() => setShowTermsModal(null)}
                                className="bg-[#1346AC] text-white w-full px-4 py-2 rounded-full font-semibold hover:bg-blue-700"
                            >
                                Close
                            </button>
                            </div>
                        </div>
                        )}
                        
                        {showSuccessModal && (
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                                <div className="bg-white p-10 rounded-lg shadow-lg max-w-lg w-md">
                                    <h2 className="text-2xl font-semibold mb-4 text-center">Document Request Submitted</h2>
                                    <p className="text-center mb-4">Your Reference Number is: <strong>{referenceNo}</strong></p>
                                    <div className="flex justify-center gap-4">
                                        <button
                                            onClick={() => setShowSuccessModal(false)}
                                            className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold hover:bg-blue-700"
                                        >
                                            Okay
                                        </button>
                                        <button
                                            onClick={() => setShowSuccessModal(false)}
                                            className="bg-gray-600 text-white px-4 py-2 rounded-full font-semibold hover:bg-gray-500"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        
                        <div className="col-span-1 lg:col-span-3 bg-white p-6 rounded-lg shadow-md flex flex-col justify-between min-h-[600px]">
                            <div className="flex justify-between w-full">
                            <div className='flex flex-col w-full'>
                            <div className='flex justify-between w-full'>
                            <h2 className="text-2xl font-semibold mb-4">Document Request History</h2>
                            <div className="flex-col space-x-4 justify-end items-end ">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="border border-gray-300 rounded-md p-2 w-72"
                                    />
                                    <div className='justify-self-end'>
                                    <NestedDropdown
                                        handleSortChange={handleSortChange} 
                                        selectedSortText={selectedSortText} 
                                    />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center mb-4">
                                <div className="mr-2">
                                    <label htmlFor="Category" className="block text-md font-medium text-gray-700">Document Type</label>
                                    <select
                                        name="filterCategory"
                                        value={filters.category}
                                        onChange={(e) => setFilters({ ...filters, category: e.target.value })}
                                        className="border border-gray-300 rounded-md p-2 w-48"
                                    >
                                        <option value="All">All</option>
                                        <option value="Certificate of Indigency">Certificate of Indigency</option>
                                        <option value="Certificate of Residency">Certificate of Residency</option>
                                        <option value="Certificate of Good Moral Character">Certificate of Good Moral Character</option>
                                        <option value="Certificate of Local Employment">Certificate of Local Employment</option>
                                        <option value="Certificate of Financial Assistance">Certificate of Financial Assistance</option>
                                        <option value="Certificate of First Time Jobseeker">Certificate of First Time Jobseeker</option>
                                        <option value="Barangay Clearance">Barangay Clearance</option>
                                        <option value="Barangay Business Permit">Barangay Business Permit</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                <div className="mr-2">
                                    <label htmlFor="purpose" className="block text-md font-medium text-gray-700">Purpose</label>
                                    <select
                                        name="purpose"
                                        value={filters.purpose}
                                        onChange={handlePurposeFilterChange}
                                        className="border border-gray-300 rounded-md p-2 w-48"
                                    >
                                        <option value="All">All</option>
                                        <option value="Work">Work</option>
                                        <option value="Open Bank Account">Open Bank Account</option>
                                        <option value="Business">Business</option>
                                        <option value="School">School</option>
                                        <option value="Travel">Travel</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                <div className="mr-2">
                                    <label htmlFor="status" className="block text-md font-medium text-gray-700">Status</label>
                                    <select
                                        name="status"
                                        value={filters.status}
                                        onChange={(e) => setFilters({ ...filters, status: e.target.value })} // Handle status change correctly
                                        className="border border-gray-300 rounded-md p-2 w-48"
                                    >
                                        <option value="All">All</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Processing">Processing</option>
                                        <option value="Approved">Approved</option>
                                        <option value="Ready for Pickup">Ready for Pickup</option>
                                        <option value="Released">Released</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="Archived">Archived</option>
                                    </select>

                                </div>
                                <button
                                    className="mt-4 text-blue-500 hover:text-[#1A50BE] cursor-pointer font-semibold"
                                    onClick={resetFilters}
                                >
                                    Reset Filters
                                </button>
                                </div>
                            </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {currentRequests.length > 0 ? (
                                    currentRequests.map((request, index) => (
                                        <div
                                            key={index}
                                            className="bg-[#dee1e7] p-4 rounded shadow-md hover:bg-[#d8dbe0] hover:shadow-lg transition-shadow cursor-pointer"
                                            onClick={() => handleOpenModal(request)}
                                        >
                                            <div
                                                className={`w-fit px-5 py-1 rounded-full font-semibold mb-4
                                              ${request.status === 'Pending' ? 'bg-[#FFEA00] text-white' :
                                                request.status === 'Processing' ? 'bg-blue-400 text-white' :
                                                request.status === 'Approved' ? 'bg-green-600 text-white' :
                                                request.status === 'Ready for Pickup' ? 'bg-green- text-white' :
                                                request.status === 'Released' ? 'bg-gray-500 text-white' :
                                                request.status === 'Rejected' ? 'bg-red-700 text-white' :
                                                request.status === 'Archived' ? 'bg-[#f52b2b] text-white' :
                                                'bg-red-200'}`}
                                            >
                                                {request.status}
                                            </div>
                                            <div className="flex items-center mb-4">
                                                <div>
                                                    <h4 className="text-lg font-semibold">{request.documentType}</h4>
                                                    <p className="text-md text-black font-semibold mt-2">{request.purpose}</p>
                                                    <p className="text-xs text-gray-500">
                                                        {new Date(request.created_at).toLocaleString()}
                                                    </p>
                                                </div>
                                            </div>
                                            {request.status === 'Archived' && request.daysUntilDeletion !== undefined && request.daysUntilDeletion > 0 && (
                                        <p className="text-red-500 text-sm mt-2">
                                            This request will be deleted in {request.daysUntilDeletion} days.
                                        </p>
                                            )}
                                            {request.status === 'Archived' && request.daysUntilDeletion !== undefined && request.daysUntilDeletion <= 0 && (
                                                <p className="text-red-500 text-sm mt-2">
                                                    This request is scheduled for deletion.
                                                </p>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p>No document requests found.</p>
                                )}
                            </div>
                            <div className="flex justify-between items-center mt-auto">
                            <div className="text-sm text-gray-600">
                                    Showing {Math.min(indexOfLastRequest, filteredRequests.length)} of {filteredRequests.length} entries
                                </div>
                                <div className="mt-4 flex items-center">
                                    <button
                                        onClick={handlePrevious}
                                        className="px-3 py-1 mr-2 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
                                        disabled={pageRange[0] === 1}
                                    >
                                        Previous
                                    </button>
                                    {pageRange.map((pageNumber) => (
                                        <button
                                            key={pageNumber}
                                            onClick={() => paginate(pageNumber)}
                                            className={`px-3 py-1 mr-2 rounded ${currentPage === pageNumber ? 'bg-[#1346AC] text-white' : 'bg-gray-200 hover:bg-[#1346AC] hover:text-white'}`}
                                            disabled={pageNumber > Math.ceil(filteredRequests.length / itemsPerPage)}
                                        >
                                            {pageNumber}
                                        </button>
                                    ))}
                                    <button
                                        onClick={handleNext}
                                        className="px-3 py-1 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
                                        disabled={pageRange[3] >= Math.ceil(filteredRequests.length / itemsPerPage)}
                                    >
                                        Next
                                    </button>
                                </div>
                            {isModalOpen && selectedRequest && (
                                <ResidentDocumentRequestModal
                                documentRequest={selectedRequest}
                                onClose={handleCloseModal}
                                onSave={handleUpdateRequest}
                            />
                            )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ResidentDocRequest;
