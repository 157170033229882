import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import DocReqDataPrivAgreement from "../assets/dataprivacyandtermsandconditions/DocReqDataPrivAgreement";
import DocReqTermsandConditions from "../assets/dataprivacyandtermsandconditions/DocReqTermsandConditions";


const AddDocumentRequestModal = ({ onClose, onSubmit }) => {
    const [formData, setFormData] = useState({
        documentType: "",
        otherDocumentType: "",
        recipient: "",
        residentName: "",
        purpose: "",
        otherPurpose: "",
        validID: [],
    });

    const [errors, setErrors] = useState({});
    const [residents, setResidents] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const fileInputRef = useRef(null);


    useEffect(() => {
        fetchResidents();
    }, []);

    const fetchResidents = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents`);
            console.log("API Response:", response.data);
            setResidents(Array.isArray(response.data) ? response.data : response.data.residents || []);
        } catch (error) {
            console.error("Error fetching residents:", error);
            setResidents([]);
        }
    };

    const handleSearchResident = (query) => {
        if (!query.trim()) {
            setSearchResults([]);
            return;
        }

        const filteredResidents = residents.filter((resident) =>
            `${resident.firstName} ${resident.middleName ? resident.middleName + " " : ""}${resident.lastName}`
                .toLowerCase()
                .includes(query.toLowerCase())
        );

        setSearchResults(filteredResidents);
    };

    const handleSelectResident = (resident) => {
        setFormData((prev) => ({
            ...prev,
            recipientID: resident._id, // Append the resident's Object ID
            recipient: `${resident.firstName} ${resident.middleName ? resident.middleName.charAt(0) + '.' : ''} ${resident.lastName}`, // Format name
            residentName: `${resident.firstName} ${resident.middleName ? resident.middleName.charAt(0) + '.' : ''} ${resident.lastName}`
        }));
        setSearchResults([]);
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prev) => ({
            ...prev,
            validID: [...prev.validID, ...files],
        }));
    };
    

    const handleRemoveFile = (index) => {
        setFormData((prev) => ({
            ...prev,
            validID: prev.validID.filter((_, i) => i !== index),
        }));

        
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const validateForm = () => {
        let newErrors = {};

        if (!formData.documentType) newErrors.documentType = "Document type is required.";
        if (formData.documentType === "Others" && !formData.otherDocumentType)
            newErrors.otherDocumentType = "Please specify document type.";
        if (!formData.recipient) newErrors.recipient = "Recipient name is required.";
        if (!formData.purpose) newErrors.purpose = "Purpose is required.";
        if (formData.purpose === "Others" && !formData.otherPurpose)
            newErrors.otherPurpose = "Please specify the purpose.";
        if (!termsAccepted) newErrors.termsAccepted = "You must accept the terms.";

        setErrors(newErrors);

        console.log("Validation Errors:", newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleConfirm = () => {
        if (!validateForm()) return; // Show validation errors first before showing confirm modal
        setShowConfirmModal(true);
    };

    const closeConfirmModal = () => {
        setShowConfirmModal(false);
    };

   const handleSubmit = async () => {
        setShowConfirmModal(false);

        const formDataToSend = new FormData();
        const user = JSON.parse(localStorage.getItem("user"));

        if (!user) {
            Swal.fire("Error", "User not found. Please log in again.", "error");
            return;
        }

        const isAdmin = user.roleinBarangay && ['Barangay Captain', 'Secretary', 'Kagawad'].includes(user.roleinBarangay);
        const requestedByType = isAdmin ? "Admin" : "Resident";

        formDataToSend.append("requestedBy", user._id);
        formDataToSend.append("requestedByType", requestedByType);
        formDataToSend.append("residentName", formData.residentName);
        formDataToSend.append("recipient", formData.recipient);
        formDataToSend.append("recipientID", formData.recipientID);
        formDataToSend.append("documentType", formData.documentType === "Others" ? formData.otherDocumentType : formData.documentType);
        formDataToSend.append("purpose", formData.purpose === "Others" ? formData.otherPurpose : formData.purpose);

        if (formData.validID.length > 0) {
            formData.validID.forEach((file) => {
                formDataToSend.append("ValidID", file, file.name);
            });
        } else {
            Swal.fire("Error", "Valid ID is required.", "error");
            return;
        }

        try {
            Swal.fire({
                title: "Processing...",
                text: "Please wait while we process your document request.",
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading(),
            });

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_API_KEY}/api/new/document-requests`,
                formDataToSend,
                { headers: { "Content-Type": "multipart/form-data" } }
            );

            await Swal.fire({
                icon: "success",
                title: "Request Submitted",
                text: `Your Reference Number is: ${response.data.request.ReferenceNo}`,
            });

            onSubmit();
            onClose();
        } catch (error) {
            console.error("Error submitting document request:", error);
            Swal.fire("Request Failed", "An error occurred. Please try again.", "error");
        }
    };
    
    const truncateFilename = (filename, maxLength = 25) => {
        const dotIndex = filename.lastIndexOf('.');
        const name = filename.substring(0, dotIndex);
        const ext = filename.substring(dotIndex);
        return name.length > maxLength ? `${name.substring(0, maxLength)}...${ext}` : filename;
    };
    
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center" onClick={onClose}>
        <div className="bg-white p-6 rounded-lg shadow-lg w-96" onClick={(e) => e.stopPropagation()}>
            <h3 className="text-xl font-semibold mb-4">Create Document Request</h3>
            <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                ✕
            </button>
                <form>
                    <label className="block text-md font-medium text-gray-700">Type of Document</label>
                    <select name="documentType" value={formData.documentType} onChange={handleInputChange} className="w-full border p-2 rounded">
                    <option value="">Select Document Type</option>
                        <option value="Certificate of Indigency">Certificate of Indigency</option>
                        <option value="Certificate of Residency">Certificate of Residency</option>
                        <option value="Certificate of Good Moral Character">Certificate of Good Moral Character</option>
                        <option value="Certificate of Local Employment">Certificate of Local Employment</option>
                        <option value="Certificate of Financial Assistance">Certificate of Financial Assistance</option>
                        <option value="Certificate of First Time Jobseeker">Certificate of First Time Jobseeker</option>
                        <option value="Barangay Clearance">Barangay Clearance</option>
                        <option value="Barangay Business Permit">Barangay Business Permit</option>
                        <option value="Others">Others</option>
                    </select>
                    {errors.documentType && <p className="text-red-500">{errors.documentType}</p>}
                    {formData.documentType === "Others" && (
                        <div className="mb-4">
                            <label className="block text-md font-medium text-gray-700">Specify Other Document Type</label>
                            <input
                                type="text"
                                name="otherDocumentType"
                                value={formData.otherDocumentType}
                                onChange={handleInputChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                placeholder="Enter document type"
                            />
                            {errors.otherDocumentType && <p className="text-red-500 text-xs">{errors.otherDocumentType}</p>}
                        </div>
                    )}

                    <label className="block text-md font-medium text-gray-700 mt-4">Recipient Full Name</label>
                    <input
                        type="text"
                        name="recipient"
                        value={formData.recipient}
                        onChange={(e) => {
                            handleInputChange(e);
                            handleSearchResident(e.target.value);
                        }}
                        className="w-full border p-2 rounded"
                        placeholder="Search or enter manually"
                    />

                    {searchResults.length > 0 && (
                        <ul className="border rounded bg-white mt-1 max-h-32 overflow-y-auto">
                            {searchResults.map((resident) => (
                                <li key={resident._id} onClick={() => handleSelectResident(resident)} className="p-2 hover:bg-gray-100 cursor-pointer">
                                    {`${resident.firstName} ${resident.middleName ? resident.middleName + " " : ""}${resident.lastName}`}
                                </li>
                            ))}
                        </ul>
                    )}

                        {/* Purpose Selection */}
                        <label className="block text-md font-medium text-gray-700 mt-4">Purpose</label>
                        <select
                            name="purpose"
                            value={formData.purpose}
                            onChange={handleInputChange}
                            className="w-full border p-2 rounded"
                        >
                            <option value="">Select Purpose</option>
                            <option value="Work">Work</option>
                            <option value="Open Bank Account">Open Bank Account</option>
                            <option value="Business">Business</option>
                            <option value="School">School</option>
                            <option value="Travel">Travel</option>
                            <option value="Others">Others</option>
                        </select>
                        {errors.purpose && <p className="text-red-500">{errors.purpose}</p>}

                        {/* Show input for custom purpose if "Others" is selected */}
                        {formData.purpose === "Others" && (
                            <div className="mb-4 mt-2">
                                <label className="block text-md font-medium text-gray-700">Specify Other Purpose</label>
                                <input
                                    type="text"
                                    name="otherPurpose"
                                    value={formData.otherPurpose}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                    placeholder="Enter purpose"
                                />
                                {errors.otherPurpose && <p className="text-red-500 text-xs">{errors.otherPurpose}</p>}
                            </div>
                        )}

                {/* File Upload */}
                    <div className="mb-4">
                        <label className="block text-md font-medium text-gray-700">Attachment</label>
                        <input 
                            type="file" 
                            name="ValidID" 
                            onChange={handleFileChange} 
                            ref={fileInputRef}
                            className="mt-1 block w-full border border-gray-300 rounded-md" 
                            multiple 
                        />
                        {errors.validID && <p className="text-red-500 text-xs">{errors.validID}</p>}
                    </div>

                    {/* Attached Files Display */}
                    <div className="mb-4">
                        <label className="block text-md font-medium text-gray-700">Attached Files</label>
                        {formData.validID.length > 0 ? (
                            <ul className="space-y-2">
                                {formData.validID.map((file, index) => (
                                    <div
                                        key={index}
                                        className="relative flex items-center justify-between p-2 border rounded-lg shadow-sm bg-gray-50"
                                    >
                                        <div className="flex items-center">
                                            <div className="w-10 h-10 bg-[#1346AC] text-white flex items-center justify-center rounded-full mr-3">
                                                <i className="fas fa-file-alt"></i>
                                            </div>
                                            <div className="truncate max-w-xs">
                                                <span className="text-blue-600 font-semibold truncate">
                                                {truncateFilename(file.name)}
                                                </span>
                                                <p className="text-gray-500 text-xs">{file.type}</p>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveFile(index)}
                                            className="absolute right-0 top-0 text-black hover:text-red-600 mr-2"
                                        >
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                ))}
                            </ul>
                        ) : (
                            <p>No files attached.</p>
                        )}
                    </div>

                    <div className="mt-4">
                        <input type="checkbox" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} className="mr-2" />
                        <span className="text-md">
                            I agree to the{' '}
                            <span
                                className="text-blue-600 cursor-pointer underline"
                                onClick={() => setShowTermsModal('terms')}
                            >
                                Terms and Conditions
                            </span>{' '}
                            and{' '}
                            <span
                                className="text-blue-600 cursor-pointer underline"
                                onClick={() => setShowTermsModal('privacy')} 
                            >
                                Data Privacy Agreement
                            </span>.
                        </span>
                    </div>
                    <div className="flex justify-end space-x-3 pt-4">
                    <button type="button" onClick={handleConfirm}  className="px-4 py-2 bg-[#1346AC] text-white rounded-full hover:bg-blue-700 font-semibold">Request Document</button>
                    <button onClick={onClose} className="px-4 py-2 border border-gray-300 rounded-full font-semibold bg-gray-200 hover:bg-gray-100">
                            Cancel
                    </button>
                    </div>
                </form>
            </div>

            {showTermsModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center" onClick={(e) => e.stopPropagation()}>
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        {showTermsModal === "terms" && <DocReqTermsandConditions closeModal={() => setShowTermsModal(null)} />}
                        {showTermsModal === "privacy" && <DocReqDataPrivAgreement closeModal={() => setShowTermsModal(null)} />}
                        <button onClick={() => setShowTermsModal(null)} className="bg-[#1346AC] text-white w-full px-4 py-2 rounded-full font-semibold hover:bg-blue-700">Close</button>
                    </div>
                </div>
            )}
                {showConfirmModal && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center modal-overlay">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                                <h3 className="text-xl font-semibold mb-4 text-start">Confirm Submission</h3>
                                <p className="text-start text-gray-600 mb-4">
                                    Are you sure you want to submit? 
                                </p>
                                <p className='text-start text-gray-600 mb-4'>Once the document request is processed, you won't be able to make any changes.</p>
                                <div className="flex justify-end items-end">
                                    <button
                                        onClick={closeConfirmModal}
                                        className="text-[#1346AC] px-4 py-2 rounded-md font-semibold hover:text-blue-700"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => {
                                            handleSubmit(); // Submit form
                                            closeConfirmModal(); 
                                        }}
                                        className="text-[#1346AC] px-4 py-2 rounded-md font-semibold hover:text-blue-700"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
        </div>
    );
};

export default AddDocumentRequestModal;
