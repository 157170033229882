import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/Header';
import Navigation from '../../component/Navigation';
import Nationalities from '../../assets/dropdowns/Nationalities';
import Religions from '../../assets/dropdowns/Religions';
import BarangayProfilingTableModal from '../../component/BarangayProfilingTableModal';
import axios from 'axios';
import logo from '../../assets/icons/logo192.png'
import { Pie, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
} from 'chart.js';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';

// ✅ Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const BarangayProfiling = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [adminData, setAdminData] = useState(null);
    const [residents, setResidents] = useState([]);

     // Filters State
     const [profilingYear, setProfilingYear] = useState('');
     const [sex, setSex] = useState('');
     const [ageRange, setAgeRange] = useState({ from: '', to: '' });
     const [civilStatus, setCivilStatus] = useState('');
     const [nationality, setNationality] = useState(''); 
     const [religion, setReligion] = useState(''); 
     const [voter, setVoter] = useState(false);
     const [indigent, setIndigent] = useState(false);
     const [soloParent, setSoloParent] = useState(false);
     const [fourps, setFourPs] = useState(false);
     const [pwd, setPWD] = useState(false);
     const [ageOption, setAgeOption] = useState('');
    const [barangay, setBarangay] = useState(null);
    const [filteredResidents, setFilteredResidents] = useState([]);
    const [showModal, setShowModal] = useState(false);

    // Chart Selection State
    const [selectedChart, setSelectedChart] = useState('');

    // Chart Data States
    const [ageGroupData, setAgeGroupData] = useState(null);
    const [genderData, setGenderData] = useState(null);
    const [occupationData, setOccupationData] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/');
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? capitalizeWords(user.middleName.charAt(0)) + '.' : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setAdminData(user);
            setUserRole(user.roleinBarangay);
        }
        fetchResidents();
    }, []);

    const fetchResidents = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents`);
            const residentsData = response.data.residents || [];
            setResidents(residentsData);
            processChartData(residentsData);
        } catch (error) {
            console.error('Error fetching residents:', error);
        }
    };

    const processChartData = (residents) => {
        const ageGroups = {
            '0-12 (Children)': 0,
            '13-19 (Teenagers)': 0,
            '20-39 (Young Adults)': 0,
            '40-59 (Middle-Aged Adults)': 0,
            '60+ (Senior Citizens)': 0
        };
    
        const genderCount = { Male: 0, Female: 0 };
        const occupationCount = {
            Employed: 0, Unemployed: 0, 'Self-Employed': 0, Freelancer: 0, 
            Student: 0, Retired: 0, Homemaker: 0, OFW: 0 
        };
    
        residents.forEach(resident => {
            if (resident.age <= 12) ageGroups['0-12 (Children)']++;
            else if (resident.age >= 13 && resident.age <= 19) ageGroups['13-19 (Teenagers)']++;
            else if (resident.age >= 20 && resident.age <= 39) ageGroups['20-39 (Young Adults)']++;
            else if (resident.age >= 40 && resident.age <= 59) ageGroups['40-59 (Middle-Aged Adults)']++;
            else if (resident.age >= 60) ageGroups['60+ (Senior Citizens)']++;
    
            if (resident.sex === 'Male') genderCount.Male++;
            else if (resident.sex === 'Female') genderCount.Female++;
    
            if (resident.occupation && occupationCount[resident.occupation] !== undefined) {
                occupationCount[resident.occupation]++;
            }
        });
    
        // ✅ Only keep occupations with counts > 0
        const filteredOccupations = Object.keys(occupationCount).filter(key => occupationCount[key] > 0);
        const filteredOccupationData = filteredOccupations.map(key => occupationCount[key]);
    
        setAgeGroupData({
            labels: Object.keys(ageGroups),
            datasets: [{
                data: Object.values(ageGroups),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF']
            }]
        });
    
        setGenderData({
            labels: ['Male', 'Female'],
            datasets: [{
                data: [genderCount.Male, genderCount.Female],
                backgroundColor: ['#36A2EB', '#FF6384']
            }]
        });
    
        setOccupationData({
            labels: filteredOccupations,
            datasets: [{
                label: '', // ✅ Add empty label to avoid null reference
                data: filteredOccupationData,
                backgroundColor: filteredOccupations.map((_, index) => [
                    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#8B0000', '#228B22', '#00008B'
                ][index % 8])
            }]
        });
    };    
    
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

     // Build Query Parameters for the API Call
     const buildQueryParams = () => {
        let params = {};

        if (profilingYear) params.profilingYear = profilingYear;
        if (sex) params.sex = sex;
        if (ageRange.from || ageRange.to) {
            if (ageRange.from) params.ageFrom = ageRange.from;
            if (ageRange.to) params.ageTo = ageRange.to;
        }
        if (civilStatus) params.civilStatus = civilStatus;
        if (nationality) params.nationality = nationality;
        if (religion) params.religion = religion;
        if (voter) params.voter = voter;
        if (indigent) params.indigent = indigent;
        if (soloParent) params.soloParent = soloParent;
        if (fourps) params.fourps = fourps;
        if (pwd) params.pwd = pwd;

        return params;
    };

    const handleViewList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents`);
            const residentsData = response.data.residents || [];
    
            if (residentsData.length === 0) {
                console.log('No residents found.');
                setFilteredResidents([]);
                return;
            }
    
            console.log('Total residents fetched:', residentsData.length);
    
            // Check if any boolean filters (checkboxes) are selected
            const isCheckboxFilterApplied = voter || indigent || soloParent || fourps || pwd;
    
            // Apply filters
            const filteredData = residentsData.filter((resident) => {
                const residentAge = resident.age ? parseInt(resident.age) : null;
                const residentCreatedYear = new Date(resident.created_at).getFullYear().toString();
    
                // Boolean Filters: If a checkbox is selected, only include matching residents
                const matchesCheckboxFilters =
                    (!voter || resident.voter === true) &&
                    (!indigent || resident.indigent === true) &&
                    (!soloParent || resident.soloparent === true) &&
                    (!fourps || resident.fourpsmember === true) &&
                    (!pwd || resident.pwd === true);
    
                return (
                    resident.accountStatus !== 'Archive' && // ✅ Exclude archived residents
                    (!profilingYear || residentCreatedYear === profilingYear) &&
                    (sex === '' || sex === 'All' || resident.sex === sex) && // ✅ Fix Sex filter
                    (!ageRange.from || (residentAge !== null && residentAge >= parseInt(ageRange.from))) &&
                    (!ageRange.to || (residentAge !== null && residentAge <= parseInt(ageRange.to))) &&
                    (!civilStatus || resident.civilStatus === civilStatus) &&
                    (!nationality || resident.nationality === nationality) &&
                    (!religion || resident.religion === religion) &&
                    (!isCheckboxFilterApplied || matchesCheckboxFilters) // ✅ Only apply checkbox filters if at least one is selected
                );
            });
    
            console.log('Filtered Residents Count:', filteredData.length);
            setFilteredResidents(filteredData);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching residents:', error);
        }
    };    
    
    useEffect(() => {
        const fetchBarangay = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/barangay`); 
                setBarangay(response.data.barangay);
            } catch (error) {
                console.error('Error fetching barangay data:', error);
            }
        };
    
        fetchBarangay();
    }, []);    
    
    const exportChartAsPDF = () => {
        const chartCanvas = document.querySelector('#printable-area canvas');
        if (!chartCanvas) {
            Swal.fire({
                title: "No Chart Detected!",
                text: "Please select a chart to export.",
                icon: "error"
              });
            return;
        }
    
        const chartImage = chartCanvas.toDataURL('image/png');
        const doc = new jsPDF('landscape');
        const img = new Image();
        img.src = logo;
    
        img.onload = () => {
            doc.addImage(img, 'PNG', 10, 10, 30, 30);
            doc.setFontSize(18);
            doc.text('Barangay Profiling Chart Report', 45, 25);
    
            doc.setFontSize(10);
            doc.text(
                `Barangay: ${barangay?.barangayName || ''}, Municipality: ${barangay?.municipality || ''}, Province: ${barangay?.province || ''}`,
                45, 33
            );
    
            const chartLabelMap = {
                age: 'Age Distribution',
                gender: 'Gender Ratio',
                occupation: 'Occupation/Employment Status',
            };
    
            doc.text(`Chart: ${chartLabelMap[selectedChart] || 'N/A'}`, 10, 45);
    
            // ✨ Responsive chart scaling
            const pageWidth = doc.internal.pageSize.getWidth();
            const chartWidth = 180;
            const chartHeight = 90;
            const chartX = (pageWidth - chartWidth) / 2;
            const chartY = 55;
    
            doc.addImage(chartImage, 'PNG', chartX, chartY, chartWidth, chartHeight);
            doc.save(`barangay_chart_report_${selectedChart}.pdf`);
        };
    
        img.onerror = () => {
            alert('Logo failed to load.');
        };
    };
    

    const handlePrint = () => {
         exportChartAsPDF();
    };
    
    return (
        <div className="flex flex-col min-h-screen">
           <Header userName={userName} userRole={userRole} handleLogout={handleLogout} getCurrentDate={getCurrentDate} profilePic={adminData?.profilepic}/>
            <div className="flex flex-1">
                <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                <main className="flex-1 p-6 bg-gray-100">
                    <div className="flex items-center mb-7">
                        <h1 className="text-4xl font-bold">Barangay Profiling</h1>
                    </div>
                    <div className="flex justify-evenly items-stretch space-x-10 min-h-[714px]">
                        <div className="bg-white flex flex-col p-6 rounded-lg shadow-md w-1/3 h-full">
                            <h2 className="text-2xl font-bold mb-4">Filter List</h2>
                            <div className="mb-4">
                                <label className="block mb-1 font-semibold">Profiling Year</label>
                                <select 
                                    className="border border-gray-300 rounded-md p-2 w-full"
                                    value={profilingYear}
                                    onChange={(e) => setProfilingYear(e.target.value)}
                                >
                                    <option value="">Select Profiling Year</option>
                                {Array.from({ length: new Date().getFullYear() - 2019 }, (_, i) => {
                                    const year = 2020 + i;
                                    return <option key={year} value={year}>{year}</option>;
                                })}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-1 font-semibold">Sex</label>
                                <select 
                                    className="border border-gray-300 rounded-md p-2 w-full"
                                    value={sex}
                                    onChange={(e) => setSex(e.target.value)}
                                >
                                    <option>Select Sex</option>
                                    <option value="All">All</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                            <div className="mb-4">
                            <label className="block mb-1 font-semibold">Age</label>
                            <select 
                                className="border border-gray-300 rounded-md p-2 w-full"
                                value={ageOption}
                                onChange={(e) => {
                                const selected = e.target.value;
                                setAgeOption(selected);
                                if (selected === 'range') {
                                    setAgeRange({ from: '', to: '' }); // Reset range
                                } else {
                                    // Convert age group label to actual range
                                    const mapping = {
                                    '0-12 (Children)': { from: 0, to: 12 },
                                    '13-19 (Teenagers)': { from: 13, to: 19 },
                                    '20-39 (Young Adults)': { from: 20, to: 39 },
                                    '40-59 (Middle-Aged Adults)': { from: 40, to: 59 },
                                    '60+ (Senior Citizens)': { from: 60, to: 200 },
                                    };
                                    setAgeRange(mapping[selected] || { from: '', to: '' });
                                }
                                }}
                            >
                                <option value="">Select Age Group</option>
                                <option value="0-12 (Children)">0-12 (Children)</option>
                                <option value="13-19 (Teenagers)">13-19 (Teenagers)</option>
                                <option value="20-39 (Young Adults)">20-39 (Young Adults)</option>
                                <option value="40-59 (Middle-Aged Adults)">40-59 (Middle-Aged Adults)</option>
                                <option value="60+ (Senior Citizens)">60+ (Senior Citizens)</option>
                                <option value="range">Range</option>
                            </select>
                            </div>

                            {/* Only show these inputs when "Range" is selected */}
                            {ageOption === 'range' && (
                            <div className="mb-4">
                                <label className="block mb-1 font-semibold">Custom Age Range</label>
                                <div className="flex">
                                <input 
                                    type="number" 
                                    className="border border-gray-300 rounded-md p-2 w-1/2 mr-2" 
                                    placeholder="From" 
                                    value={ageRange.from}
                                    onChange={(e) => setAgeRange(prev => ({ ...prev, from: e.target.value }))} 
                                />
                                <input 
                                    type="number" 
                                    className="border border-gray-300 rounded-md p-2 w-1/2" 
                                    placeholder="To" 
                                    value={ageRange.to}
                                    onChange={(e) => setAgeRange(prev => ({ ...prev, to: e.target.value }))} 
                                />
                                </div>
                            </div>
                            )}
                            <div className="mb-4">
                                <label className="block mb-1 font-semibold">Civil Status</label>
                                <select 
                                    className="border border-gray-300 rounded-md p-2 w-full"
                                    value={civilStatus}
                                    onChange={(e) => setCivilStatus(e.target.value)}
                                >
                                    <option>Select Civil Status</option>
                                    <option>Single</option>
                                    <option>Married</option>
                                    <option>Separated</option>
                                    <option>Annulled</option>
                                    <option>Divorced</option>
                                    <option>Widowed</option>
                                </select>
                            </div>
                            <div className="mb-4">
                            <label className="block mb-1 font-semibold">Nationalities</label>
                                <Nationalities
                                    selectedNationality={nationality}
                                    handleChange={(e) => setNationality(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                            <label className="block mb-1 font-semibold">Religions</label>
                                <Religions
                                    selectedReligion={religion}
                                    handleChange={(e) => setReligion(e.target.value)}
                                />
                            </div>
                            <label className="block mb-1 font-semibold">Others</label>
                            <div className="flex flex-wrap mb-4">
                                <label className="flex items-center">
                                    <input type="checkbox" checked={voter} onChange={() => setVoter(!voter)} />
                                    <span className="ml-2">Voter</span>
                                </label>
                                <label className="flex items-center ml-4">
                                    <input type="checkbox" checked={indigent} onChange={() => setIndigent(!indigent)} />
                                    <span className="ml-2">Indigent</span>
                                </label>
                                <label className="flex items-center ml-4">
                                    <input type="checkbox" checked={fourps} onChange={() => setFourPs(!fourps)} />
                                    <span className="ml-2">4Ps</span>
                                </label>
                                <label className="flex items-center ml-4">
                                    <input type="checkbox" checked={soloParent} onChange={() => setSoloParent(!soloParent)} />
                                    <span className="ml-2">Solo Parent</span>
                                </label>
                                <label className="flex items-center ml-4">
                                    <input type="checkbox" checked={pwd} onChange={() => setPWD(!pwd)} />
                                    <span className="ml-2">PWD</span>
                                </label>
                            </div>
                            <div className="flex flex-col w-full px-6 space-y-2">
                                <button 
                                    className="bg-[#1346AC] hover:bg-blue-700 text-white px-4 py-2 rounded-full font-semibold"
                                    onClick={handleViewList}
                                >
                                    View List
                                </button>
                                </div>
                            </div>
                        <div className="bg-white flex flex-col justify-between p-6 rounded-lg shadow-md w-2/3 h-auto">
                        <h2 className="text-2xl font-bold">Charts</h2>
                        <div className='flex flex-col'>
                        <select
                                className="border border-gray-400 rounded-md p-2 w-full"
                                value={selectedChart}
                                onChange={(e) => setSelectedChart(e.target.value)}
                            >
                                <option value="">Select Chart</option>
                                <option value="age">Population Distribution (Age)</option>
                                <option value="gender">Gender Ratio</option>
                                <option value="occupation">Occupation/Employment Status</option>
                            </select>

                            <div className="flex flex-col items-center justify-center min-h-[500px]" id="printable-area">
                            {selectedChart === 'age' && ageGroupData && (
                                <>
                                    <h3 className="text-lg font-semibold text-center mb-4">Age Distribution</h3>
                                    <div className="flex justify-center items-center space-x-4 w-full">
                                    {/* Pie Chart Container */}
                                    <div className="w-2/3 h-[400px] flex justify-end">
                                        <Pie 
                                            data={ageGroupData} 
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                layout: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                        top: 10,
                                                        bottom: 10
                                                    }
                                                },
                                                plugins: {
                                                    legend: {
                                                        position: 'right', // ✅ Moves the labels to the right
                                                        align: 'center', // Aligns legend vertically in the middle
                                                        labels: {
                                                            boxWidth: 15, // ✅ Smaller color boxes for labels
                                                            padding: 5 // ✅ Reduce spacing between labels
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                </>
                            )}

                            {selectedChart === 'gender' && genderData && (
                                <>
                                    <h3 className="text-lg font-semibold text-center mb-4">Gender Ratio</h3>
                                    <div className="w-2/3 h-[400px] flex justify-center items-center">
                                    <Pie 
                                    data={genderData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                            generateLabels: function (chart) {
                                                const data = chart.data;
                                                const dataset = data.datasets[0];
                                                return data.labels.map((label, index) => {
                                                const value = dataset.data[index];
                                                return {
                                                    text: `${label} ${value}`, // e.g., Female 4
                                                    fillStyle: dataset.backgroundColor[index],
                                                    strokeStyle: dataset.backgroundColor[index],
                                                    hidden: !chart.getDataVisibility(index),
                                                    index: index
                                                };
                                                });
                                            },
                                            color: '#FFFFFF',
                                            boxWidth: 15,
                                            padding: 8,
                                            font: {
                                                weight: 'bold',
                                                size: 12
                                            }
                                            }
                                        }
                                        }
                                    }}
                                    />
                                    </div>
                                </>
                            )}

                {selectedChart === 'occupation' && occupationData && (
                    <>
                        <h3 className="text-lg font-semibold text-center mb-4">Occupation Distribution</h3>
                        <div className="w-full flex justify-center items-center">
                            <Bar 
                                data={occupationData} 
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: { 
                                        y: { beginAtZero: true }
                                    },
                                    plugins: {
                                        legend: {
                                            display: true,
                                            position: 'top',
                                            onClick: (e, legendItem, legend) => {
                                                const chart = legend.chart;
                                                const index = legendItem.index;
                                                if (index !== undefined) {
                                                    // ✅ Toggle individual data visibility
                                                    chart.toggleDataVisibility(index);
                                                    chart.update();
                                                }
                                            },
                                            labels: {
                                                generateLabels: function(chart) {
                                                    const data = chart.data;
                                                    if (data.labels.length && data.datasets.length) {
                                                        return data.labels.map((label, index) => {
                                                            const backgroundColor = data.datasets[0].backgroundColor[index];
                                                            return {
                                                                text: label,
                                                                fillStyle: backgroundColor,
                                                                strokeStyle: backgroundColor,
                                                                hidden: !chart.getDataVisibility(index),
                                                                index: index
                                                            };
                                                        });
                                                    }
                                                    return [];
                                                }
                                            }
                                        }
                                    }
                                }}
                                height={250} 
                                width={500}
                            />
                        </div>
                    </>
                )}
                        </div>
                        </div>
                        <div className='flex justify-start w-full'>
                            <button 
                            onClick={handlePrint} 
                            className="w-1/4 p-2 text-white bg-[#1346AC] hover:bg-blue-700 rounded-full font-semibold border text-center ">
                               Export Chart as PDF
                            </button>
                        </div>
                        </div>
                    </div>
                </main>
            </div>
            {showModal && (
                <BarangayProfilingTableModal
                    residents={filteredResidents}
                    filters={{
                        sex: sex || 'N/A',
                        ageRange: ageOption === 'range'
                        ? `${ageRange.from || 'N/A'} to ${ageRange.to || 'N/A'}`
                        : ageOption || 'N/A',                      
                        civilStatus: civilStatus || 'N/A',
                        nationality: nationality || 'N/A',
                        religion: religion || 'N/A',
                        voter: voter ? 'Voter' : '',
                        indigent: indigent ? 'Indigent' : '',
                        soloParent: soloParent ? 'Solo Parent' : '',
                        fourps: fourps ? '4Ps' : '',
                        pwd: pwd ? 'PWD' : '',
                    }}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
};

export default BarangayProfiling;
