import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {  FaUser, FaUsers,  FaCopy } from 'react-icons/fa';
import { FaFilePen } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const DashboardCards = () => {
    const navigate = useNavigate();
    const [stats, setStats] = useState({
        totalPopulation: 0,
        totalVoters: 0,
        totalIndigent: 0,
        totalPWDs: 0,
        totalSeniorCitizens: 0,
        totalSoloParents: 0,
        total4Ps: 0,
    });

    const [pendingIncidentReports, setPendingIncidentReports] = useState(0);
    const [pendingDocumentRequests, setPendingDocumentRequests] = useState(0);
    const [pendingResidentVerifications, setPendingResidentVerifications] = useState(0);
    const [pendingProfileUpdates, setPendingProfileUpdates] = useState(0); // Placeholder if required later

    // Fetch the total population and other statistics
    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/barangays`);
                
                if (response.data.barangays && response.data.barangays.length > 0) {
                    const barangayStats = response.data.barangays[0];
                    setStats({
                        totalPopulation: barangayStats.population,
                        totalVoters: barangayStats.totalvoters,
                        totalIndigent: barangayStats.totalindigent,
                        totalPWDs: barangayStats.totalpwd,
                        totalSeniorCitizens: barangayStats.totalseniorcitizen,
                        totalSoloParents: barangayStats.totalsoloparent,
                        total4Ps: barangayStats.total4psbeneficiary,
                    });
                }
            } catch (error) {
                console.error('Error fetching dashboard statistics:', error);
            }
        };

        fetchStats();
    }, []);

    // Fetch pending incident reports, document requests, and resident verifications
    useEffect(() => {
        const fetchPendingCounts = async () => {
            try {
                // Fetch Pending Incident Reports
                const incidentResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/incident-reports`);
                const pendingIncidents = incidentResponse.data.filter(report => report.status === 'Pending');
                setPendingIncidentReports(pendingIncidents.length);

                // Fetch Pending Document Requests
                const documentResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/document-requests`);
                const pendingDocuments = documentResponse.data.requests.filter(request => request.status === 'Pending');
                setPendingDocumentRequests(pendingDocuments.length);

                // Fetch Pending Resident Verifications
                const residentResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents`);
    
                // Access the `residents` array inside the response
                const pendingResidents = residentResponse.data.residents.filter(resident => resident.accountStatus === 'Pending');
                setPendingResidentVerifications(pendingResidents.length);

                setPendingProfileUpdates(0); 
            } catch (error) {
                console.error('Error fetching pending counts:', error);
            }
        };

        fetchPendingCounts();
    }, []);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Pending Incident Reports */}
            <div className="bg-[#FEE1DF] p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between">
                <div>
                    <h2 className="text-lg font-bold text-red-600">Pending Complaints</h2>
                    <p className="absolute bottom-4 left-4 text-2xl font-bold text-red-600">{pendingIncidentReports}</p>
                </div>
                <div className="absolute bottom-4 right-4 bg-[#AC1313] hover:bg-red-600 text-[#FEE1DF] p-3 rounded-full flex items-center justify-center w-10 h-10
                cursor-pointer hover:scale-110 transition-transform duration-200"
                 onClick={() => navigate('/Complaint')}
                >
                    <FaFilePen className="w-6 h-6" />
                </div>
            </div>

            {/* Pending Document Requests */}
            <div className="bg-[#FEE1DF] p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between">
                <div>
                    <h2 className="text-lg font-bold text-red-600">Pending Document Requests</h2>
                    <p className="absolute bottom-4 left-4 text-2xl font-bold text-red-600">{pendingDocumentRequests}</p>
                </div>
                <div className="absolute bottom-4 right-4 bg-[#AC1313] hover:bg-red-600 text-[#FEE1DF] p-3 rounded-full flex items-center justify-center w-10 h-10
                cursor-pointer hover:scale-110 transition-transform duration-200"
                onClick={() => navigate('/Document-Request')}>
                    <FaCopy className="w-6 h-6" />
                </div>
            </div>

            {/* Pending Resident Verification */}
            <div className="bg-[#FEE1DF] p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between">
                <div>
                    <h2 className="text-lg font-bold text-red-600">Pending Resident Verification</h2>
                    <p className="absolute bottom-4 left-4 text-2xl font-bold text-red-600">{pendingResidentVerifications}</p>
                </div>
                <div className="absolute bottom-4 right-4 bg-[#AC1313] hover:bg-red-600 text-[#FEE1DF] p-3 rounded-full flex items-center justify-center w-10 h-10
                cursor-pointer hover:scale-110 transition-transform duration-200"
                onClick={() => navigate('/ResidentManagement', { state: { activeTab: 'Accounts for Verification' } })}>
                    <FaUser className="w-6 h-6" />
                </div>
            </div>

            {/* Pending Profile Update Requests */}
            <div className="bg-[#FEE1DF] p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between">
                <div>
                    <h2 className="text-lg font-bold text-red-600">Pending Profile Update Requests</h2>
                    <p className="absolute bottom-4 left-4 text-2xl font-bold text-red-600">{pendingProfileUpdates}</p>
                </div>
                <div className="absolute bottom-4 right-4 bg-[#AC1313] hover:bg-red-600 text-[#FEE1DF] p-3 rounded-full flex items-center justify-center w-10 h-10
                cursor-pointer hover:scale-110 transition-transform duration-200">
                    <FaUser className="w-6 h-6" />
                </div>
            </div>

            {/* Cards with improved color contrast */}
                {/* Total Population */}
                <div className="bg-[#aed8f7] hover:bg-blue-600 p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between transition duration-300 group">
                    <div>
                        <h2 className="text-lg font-bold text-blue-600 group-hover:text-white transition duration-300">Total Population</h2>
                        <p className="absolute bottom-4 left-4 text-2xl font-bold text-blue-600 group-hover:text-white transition duration-300">{stats.totalPopulation}</p>
                    </div>
                    <div className="absolute bottom-4 right-4 bg-blue-600 group-hover:bg-white text-[#aed8f7] group-hover:text-blue-600 p-3 rounded-full flex items-center justify-center w-10 h-10 transition duration-300
                    cursor-pointer hover:scale-110"
                    onClick={() => navigate('/ResidentManagement', { state: { activeTab: 'Residents' } })}>
                        <FaUsers className="w-6 h-6"/>
                    </div>
                </div>

                {/* Total Voters */}
                <div className="bg-[#aed8f7] hover:bg-blue-600 p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between transition duration-300 group">
                    <div>
                        <h2 className="text-lg font-bold text-blue-600 group-hover:text-white transition duration-300">Total Voters</h2>
                        <p className="absolute bottom-4 left-4 text-2xl font-bold text-blue-600 group-hover:text-white transition duration-300">{stats.totalVoters}</p>
                    </div>
                    <div className="absolute bottom-4 right-4 bg-blue-600 group-hover:bg-white text-[#aed8f7] group-hover:text-blue-600 p-3 rounded-full flex items-center justify-center w-10 h-10 transition duration-300
                    cursor-pointer hover:scale-110"
                    onClick={() => navigate('/ResidentManagement', { state: { activeTab: 'Residents' } })}>
                        <FaUsers className="w-6 h-6"/>
                    </div>
                </div>

                {/* Total Indigent */}
                <div className="bg-[#aed8f7] hover:bg-blue-600 p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between transition duration-300 group">
                    <div>
                        <h2 className="text-lg font-bold text-blue-600 group-hover:text-white transition duration-300">Total Indigent</h2>
                        <p className="absolute bottom-4 left-4 text-2xl font-bold text-blue-600 group-hover:text-white transition duration-300">{stats.totalIndigent}</p>
                    </div>
                    <div className="absolute bottom-4 right-4 bg-blue-600 group-hover:bg-white text-[#aed8f7] group-hover:text-blue-600 p-3 rounded-full flex items-center justify-center w-10 h-10 transition duration-300
                    cursor-pointer hover:scale-110"
                    onClick={() => navigate('/ResidentManagement', { state: { activeTab: 'Residents' } })}>
                        <FaUsers className="w-6 h-6"/>
                    </div>
                </div>

                {/* Total PWDs */}
                <div className="bg-[#aed8f7] hover:bg-blue-600 p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between transition duration-300 group">
                    <div>
                        <h2 className="text-lg font-bold text-blue-600 group-hover:text-white transition duration-300">Total PWDs</h2>
                        <p className="absolute bottom-4 left-4 text-2xl font-bold text-blue-600 group-hover:text-white transition duration-300">{stats.totalPWDs}</p>
                    </div>
                    <div className="absolute bottom-4 right-4 bg-blue-600 group-hover:bg-white text-[#aed8f7] group-hover:text-blue-600 p-3 rounded-full flex items-center justify-center w-10 h-10 transition duration-300
                    cursor-pointer hover:scale-110"
                    onClick={() => navigate('/ResidentManagement', { state: { activeTab: 'Residents' } })}>
                        <FaUsers className="w-6 h-6"/>
                    </div>
                </div>

                {/* Total Senior Citizens */}
                <div className="bg-[#aed8f7] hover:bg-blue-600 p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between transition duration-300 group">
                    <div>
                        <h2 className="text-lg font-bold text-blue-600 group-hover:text-white transition duration-300">Total Senior Citizens</h2>
                        <p className="absolute bottom-4 left-4 text-2xl font-bold text-blue-600 group-hover:text-white transition duration-300">{stats.totalSeniorCitizens}</p>
                    </div>
                    <div className="absolute bottom-4 right-4 bg-blue-600 group-hover:bg-white text-[#aed8f7] group-hover:text-blue-600 p-3 rounded-full flex items-center justify-center w-10 h-10 transition duration-300
                    cursor-pointer hover:scale-110"
                    onClick={() => navigate('/ResidentManagement', { state: { activeTab: 'Residents' } })}>
                        <FaUsers className="w-6 h-6"/>
                    </div>
                </div>

                {/* Total Solo Parents */}
                <div className="bg-[#aed8f7] hover:bg-blue-600 p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between transition duration-300 group">
                    <div>
                        <h2 className="text-lg font-bold text-blue-600 group-hover:text-white transition duration-300">Total Solo Parents</h2>
                        <p className="absolute bottom-4 left-4 text-2xl font-bold text-blue-600 group-hover:text-white transition duration-300">{stats.totalSoloParents}</p>
                    </div>
                    <div className="absolute bottom-4 right-4 bg-blue-600 group-hover:bg-white text-[#aed8f7] group-hover:text-blue-600 p-3 rounded-full flex items-center justify-center w-10 h-10 transition duration-300
                    cursor-pointer hover:scale-110"
                    onClick={() => navigate('/ResidentManagement', { state: { activeTab: 'Residents' } })}>
                        <FaUsers className="w-6 h-6"/>
                    </div>
                </div>

                {/* Total 4Ps Beneficiaries */}
                <div className="bg-[#aed8f7] hover:bg-blue-600 p-4 rounded-lg shadow-md relative min-h-[110px] flex flex-col justify-between transition duration-300 group">
                    <div>
                        <h2 className="text-lg font-bold text-blue-600 group-hover:text-white transition duration-300">Total 4Ps Beneficiaries</h2>
                        <p className="absolute bottom-4 left-4 text-2xl font-bold text-blue-600 group-hover:text-white transition duration-300">{stats.total4Ps}</p>
                    </div>
                    <div className="absolute bottom-4 right-4 bg-blue-600 group-hover:bg-white text-[#aed8f7] group-hover:text-blue-600 p-3 rounded-full flex items-center justify-center w-10 h-10 transition duration-300
                    cursor-pointer hover:scale-110"
                    onClick={() => navigate('/ResidentManagement', { state: { activeTab: 'Residents' } })}>
                        <FaUsers className="w-6 h-6"/>
                    </div>
                </div>
        </div>
    );
};

export default DashboardCards;
