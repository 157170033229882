import React from 'react';

const SignUpDataPrivAgreement = () => {
  return (
    <div className="p-6">
      <p>
        This Data Privacy Agreement outlines how we collect, use, store, and protect your personal information when you interact with our services. By using our services and submitting your information, you agree to the terms of this agreement.
      </p>

      <h2 className="text-xl font-semibold mt-4 mb-2">1. Collection of Personal Data</h2>
      <p>
        We collect and process the following personal information to provide and improve our services:
      </p>
      <ul className="list-disc ml-8">
        <li>Household Role</li>
        <li>Household Number</li>
        <li>Household Head Name and Contact Number</li>
        <li>First, Middle (optional), and Last Name</li>
        <li>Suffix (optional)</li>
        <li>Gender</li>
        <li>Birthdate and Age</li>
        <li>Birthplace</li>
        <li>Occupation</li>
        <li>Nationality</li>
        <li>Religion</li>
        <li>Civil Status</li>
        <li>Email Address</li>
        <li>Mobile Number</li>
        <li>PWD ID No. (optional)</li>
        <li>Solo Parent ID No. (optional)</li>
        <li>Senior Citizen ID No. (optional)</li>
        <li>Address</li>
        <li>Valid IDs</li>
        <li>PhilSys ID No. (optional)</li>
        <li>Voter's ID No. (optional)</li>
        <li>SSS ID No. (optional)</li>
        <li>Pag-IBIG MID No. (optional)</li>
        <li>PhilHealth No. (optional)</li>
        <li>TIN (optional)</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4 mb-2">2. Purpose of Data Collection</h2>
      <p>The data collected is used for:</p>
      <ul className="list-disc ml-8">
        <li>Verifying your identity</li>
        <li>Facilitating your registration in our services</li>
        <li>Processing your requests, applications, or transactions</li>
        <li>Communicating service-related updates</li>
        <li>Ensuring legal compliance and government reporting</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4 mb-2">3. Data Storage and Retention</h2>
      <p>
        Your personal data is securely stored in our systems. We retain your data only as long as necessary to fulfill the purposes outlined in this agreement or as required by law. Afterward, your data will be securely disposed of.
      </p>

      <h2 className="text-xl font-semibold mt-4 mb-2">4. Data Sharing</h2>
      <p>
        We will not disclose your personal data to third parties unless:
      </p>
      <ul className="list-disc ml-8">
        <li>It is required by law or court orders</li>
        <li>You have given explicit consent</li>
        <li>It is necessary to provide the services (e.g., sharing with government agencies)</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4 mb-2">5. Security Measures</h2>
      <p>
        We implement strict security measures, including encryption and access controls, to protect your personal data from unauthorized access, alteration, disclosure, or destruction.
      </p>

      <h2 className="text-xl font-semibold mt-4 mb-2">6. Your Rights</h2>
      <p>
        You have the right to:
      </p>
      <ul className="list-disc ml-8">
        <li>Access: Review the personal information we hold about you.</li>
        <li>Rectification: Request corrections to any inaccurate or incomplete data.</li>
        <li>Erasure: Request deletion of your personal data, subject to legal requirements.</li>
        <li>Objection: Withdraw consent or object to the processing of your personal data.</li>
        <li>Data Portability: Request a copy of your personal data in a machine-readable format.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4 mb-2">7. Cookies and Tracking</h2>
      <p>
        We may use cookies or similar technologies to enhance your experience and gather usage data. You can control cookie preferences in your browser settings.
      </p>

      <h2 className="text-xl font-semibold mt-4 mb-2">8. Changes to This Agreement</h2>
      <p>
        We may update this Data Privacy Agreement from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on our website, and where necessary, we will notify you of significant changes.
      </p>

      <p className="mt-6">
        By using our services, you confirm that you have read, understood, and agreed to the terms of this Data Privacy Agreement.
      </p>
    </div>
  );
};

export default SignUpDataPrivAgreement;
