import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MdRemoveCircle, MdEdit, MdRateReview, MdPersonAdd } from "react-icons/md";
import Navigation from './Navigation';
import Header from './Header';
import Swal from 'sweetalert2';

const EditHousehold = () => {
    const [household, setHousehold] = useState(null);
    const [householdHead, setHouseholdHead] = useState(null);
    const [householdMembers, setHouseholdMembers] = useState([]);
    const [formerHead, setFormerHead] = useState(null);
    const [contactNumber, setContactNumber] = useState("");
    const [resident, setResident] = useState(null);
    const [newHeadId, setNewHeadId] = useState(null);
    const [relationship, setRelationship] = useState(""); 
    const [userName, setUserName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [adminData, setAdminData] = useState(null);
    const [membersWithHead, setMembersWithHead] = useState([]);
    const [isPresentAddressOpen, setIsPresentAddressOpen] = useState(false);
    const [pendingRemovals, setPendingRemovals] = useState([]);

    const { householdID } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchHouseholdDetails();
    }, []);

    const fetchHouseholdDetails = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_KEY}/api/household/id/${householdID}`
            );
            if (response.data) {
                const { householdID, householdHead, members, householdHeadContactNumber } = response.data;
                setHousehold(householdID);
                setHouseholdHead(householdHead);
                setContactNumber(householdHeadContactNumber);
                setHouseholdMembers(members);

                if (householdHead && householdHead._id) {
                    fetchResidentDetails(householdHead._id);
                }
            } else {
                alert("Household information not found");
            }
        } catch (error) {
            console.error("Error fetching household details:", error);
            alert("Error fetching household information");
        }
    };

    const fetchResidentDetails = async (residentID) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/${residentID}`
            );
            setResident(response.data);
        } catch (error) {
            console.error("Error fetching resident details:", error);
        }
    };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        if (user.profilepic) {
            user.profilepic = user.profilepic.replace(/\\/g, '/');
        }
        const capitalizeWords = (str) =>
            str
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        const firstName = capitalizeWords(user.firstName);
        const lastName = capitalizeWords(user.lastName);
        const middleInitial = user.middleName ? capitalizeWords(user.middleName.charAt(0)) + '.' : '';
        setUserName(`${firstName} ${middleInitial} ${lastName}`);
        setAdminData(user);
        setUserRole(user.roleinBarangay);
    }
}, []);

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/");
    };

    const handleBackClick = () => {
        navigate("/ResidentManagement", { state: { activeTab: "Households" } });
    };

    const handleViewMember = (residentID) => {
        navigate(`/view-resident/${residentID}`);
    };

    const handleEditMember = (residentID) => {
        navigate(`/edit-resident/${residentID}`);
    };

    const handleRemoveMember = async (residentID) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "Do you really want to remove this member?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, remove",
            cancelButtonText: "Cancel",
        });

        if (result.isConfirmed) {
            setPendingRemovals([...pendingRemovals, residentID]); // Add to pending removals
            setHouseholdMembers(householdMembers.filter(member => member.residentID !== residentID)); // Remove from UI
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return "-";
        return new Date(dateString).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
    };
    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const togglePresentAddress = () => {
        setIsPresentAddressOpen(!isPresentAddressOpen);
    };

    const formatFullName = (member) => {
        return `${member.firstName} ${member.middleName || ''} ${member.lastName}${member.suffix ? ` ${member.suffix}` : ''}`.trim();
    };

    // Update useEffect to combine head and members
    useEffect(() => {
        if (householdHead && householdMembers) {
            // Filter out the current household head from the dropdown options
            const membersWithoutHead = householdMembers.filter(member => member._id !== householdHead._id);
            setMembersWithHead(membersWithoutHead);
        }
    }, [householdHead, householdMembers]);
    

    const handleHeadChange = async (event) => {
        const selectedId = event.target.value;
        if (selectedId === householdHead?._id) return; // No change

        const result = await Swal.fire({
            title: "Confirm Change",
            text: "Are you sure you want to change the household head?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, change it",
            cancelButtonText: "Cancel",
        });

        if (result.isConfirmed) {
            setNewHeadId(selectedId);
        }
    };

    const handleRelationshipChange = (event) => {
        setRelationship(event.target.value);
    };

    const handleSaveChanges = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({
                    icon: "error",
                    title: "Unauthorized!",
                    text: "Please login to perform this action",
                });
                return;
            }

            // Update household head if changed
            if (newHeadId && newHeadId !== householdHead?._id) {
                await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/${householdID}/change-head`, 
                    { newHeadId, formerHeadId: householdHead?._id, relationship },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
            }

            // Remove members from backend
            for (const residentID of pendingRemovals) {
                await axios.delete(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/${householdID}/member/${residentID}`, 
                    { headers: { Authorization: `Bearer ${token}` } }
                );
            }

            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Household changes saved successfully",
            });

            fetchHouseholdDetails(); // Refresh data
            setPendingRemovals([]); // Clear pending removals
            setNewHeadId(null);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: error.response?.data?.message || "Failed to save changes",
            });
        }
    };


    if (!household) return <div>Loading...</div>;


    return (
        <div className="flex flex-col min-h-screen">
            <Header userName={userName} userRole={userRole} handleLogout={handleLogout} profilePic={adminData?.profilepic}/>
            <div className="flex flex-1">
                <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                <main className="flex-1 p-8 bg-gray-100">
                    <div className="flex items-center mb-7">
                        <button
                            className="text-xl text-gray-500 hover:text-[#1346AC] cursor-pointer font-semibold mr-10"
                            onClick={handleBackClick}
                        >
                            &lt; Back
                        </button>
                        <h1 className="text-4xl font-bold">Edit Household</h1>
                    </div>

                    <div className="flex w-full bg-white rounded-lg shadow-md p-6">
                        <div className="rounded-lg w-1/5 flex flex-col items-center justify-between p-6">
                            {resident?.profilepic ? (
                                <img src={resident.profilepic} alt="Profile" className="w-52 h-52 rounded-sm object-cover" />
                            ) : (
                                <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
                                    <span className="text-gray-500">No Image</span>
                                </div>
                            )}

                        <div className='flex flex-col justify-evenly space-y-2 w-full px-4'>
                        <button className="bg-[#1346AC] hover:bg-blue-700 text-white px-4 py-2 rounded-full font-semibold mt-4" onClick={handleSaveChanges}>
                            Save Changes
                        </button>
                            </div>
                        </div>

                        <div className="w-full overflow-y-auto max-h-[800px] p-6 px-6">
                        <h2 className="text-2xl font-semibold mb-4">Household Information</h2>
                        {resident && (
                        <div className="flex justify-between w-full">
                            <div className='flex flex-col'>
                            <span className="font-medium">Household Role</span> 
                            {resident.roleinHousehold || '-'}
                        </div>
                        <div className='flex flex-col'>
                            <span className="font-medium">Household No.</span> 
                            {household || '-'}
                        </div>
                        <div className="flex flex-col">
                            <span className="font-medium">Household Head</span>
                            <select 
                                value={newHeadId || householdHead?._id} 
                                onChange={handleHeadChange} 
                                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                            >
                                <option value="">Select New Head</option>
                                {membersWithHead.map((member) => (
                                    <option key={member._id} value={member._id}>
                                        {`${member.firstName} ${member.middleName || ""} ${member.lastName}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='flex flex-col'>
                            <span className="font-medium">Contact Number</span> 
                            {resident.contactNumber || '-'}
                        </div>
                            </div>
                        )}

                    {newHeadId && newHeadId !== householdHead?._id && (
                        <div className="flex flex-col w-1/4">
                            <label className="block text-md font-medium text-gray-700">Relationship to New Household Head</label>
                            <select value={relationship} onChange={handleRelationshipChange} className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md">
                                <option value="">Select Relationship</option>
                                <option value="Spouse">Spouse</option>
                                <option value="Parent">Parent</option>
                                <option value="Child">Child</option>
                                <option value="Sibling">Sibling</option>
                                <option value="Grandchild">Grandchild</option>
                                <option value="Grand Parent">Grand Parent</option>
                                <option value="Niece">Niece</option>
                                <option value="Uncle">Uncle</option>
                                <option value="Aunt">Aunt</option>
                                <option value="Cousin">Cousin</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                            )}

                            <h2 className="text-2xl font-semibold mt-4 mb-2">Household Members</h2>
                            <table className="min-w-full bg-white">
                                <thead>
                                    <tr className="border text-center">
                                        <th className="py-2 border-r">Resident No.</th>
                                        <th className="py-2 border-r">Name</th>
                                        <th className="py-2 border-r">Sex</th>
                                        <th className="py-2 border-r">Age</th>
                                        <th className="py-2 border-r">Role</th>
                                        <th className="py-2 border-r">Contact Number</th>
                                        <th className="py-2 border-r">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {householdMembers.map((member, index) => (
                                        <tr key={index} className="border text-center">
                                            <td className="py-2 border-r">{member.residentID}</td>
                                            <td className="py-2 border-r">{`${member.firstName} ${member.middleName ? member.middleName + ' ' : ''}${member.lastName}`}</td>
                                            <td className="py-2 border-r">{member.sex}</td>
                                            <td className="py-2 border-r">{member.age}</td>
                                            <td className="py-2 border-r">{member.roleinHousehold}</td>
                                            <td className="py-2 border-r">{member.contactNumber}</td>
                                            <td className="border-b border-gray-400 space-x-2 text-center font-semibold actions-column">
                                            <div className="flex justify-center space-x-4 items-center">
                                            <div className="border-r border-gray-300 pr-4"> 
                                                <button onClick={() => handleViewMember(member._id)} 
                                                className="text-[#1346AC] hover:text-blue-500 inline-flex items-center"> 
                                                    <MdRateReview  className="mr-1 text-lg" />
                                                    VIEW
                                                </button> 
                                                </div>
                                                <div className="border-r border-gray-300 pr-4">
                                                <button 
                                                className="text-[#1346AC] hover:text-yellow-500 inline-flex items-center"
                                                onClick={() => handleEditMember(member._id)}>
                                                <MdEdit className="mr-1 text-lg" />
                                                Edit</button>
                                                </div>
                                                <button 
                                                className="text-[#1346AC] hover:text-red-500 inline-flex items-center"
                                                onClick={() => handleRemoveMember(member.residentID)} >
                                                    <MdRemoveCircle className="mr-1 text-lg" />
                                                    Remove</button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button
                        className="bg-[#1346AC] hover:bg-blue-700 text-white px-4 py-2 rounded-full font-semibold mt-4"
                        onClick={() => navigate(`/AddResident?householdID=${household}`)}
                        >
                        Add Member
                        </button>

                        {/* Household Head Personal Information - MOVED HERE */}
                        {resident && (
                        <div className="mt-8">
                            <h2 className="text-2xl font-semibold mb-4">Household Head Personal Information</h2>
                        <div className="grid grid-cols-4 gap-4">
                                <div>
                                    <span className="font-medium">Last Name</span>
                                    <p>{resident.lastName || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">First Name</span>
                                    <p>{resident.firstName || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Middle Name</span>
                                    <p>{resident.middleName || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Suffix</span>
                                    <p>{resident.suffix || 'None'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Sex</span>
                                    <p>{resident.sex || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Birthdate</span>
                                    <p>{formatDate(resident.birthday)}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Birthplace</span>
                                    <p>{resident.birthplace || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Age</span>
                                    <p>{resident.age || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Civil Status</span>
                                    <p>{resident.civilStatus || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Nationality</span>
                                    <p>{resident.nationality || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Religion</span>
                                    <p>{resident.religion || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Occupation</span>
                                    <p>{resident.occupation || '-'}</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 mt-4">
                                <div>
                                    <span className="font-medium">Email Address</span>
                                    <p>{resident.email || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Mobile Number</span>
                                    <p>{resident.contactNumber || '-'}</p>
                                </div>
                                <div className="flex space-x-4 items-center">
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.voter} readOnly />
                                        <span className="ml-2">Voter</span>
                                    </div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.indigent} readOnly />
                                        <span className="ml-2">Indigent</span>
                                    </div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.fourpsmember} readOnly />
                                        <span className="ml-2">4Ps</span>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 mt-4">
                                <div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.pwd} readOnly />
                                        <span className="ml-2">PWD</span>
                                    </div>
                                    <span className="font-medium">PWD ID No.</span>
                                    <p>{resident.pwdid_num || '-'}</p>
                                </div>
                                <div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.soloparent} readOnly />
                                        <span className="ml-2">Solo Parent</span>
                                    </div>
                                    <span className="font-medium">Solo Parent ID No.</span>
                                    <p>{resident.soloparentid_num || '-'}</p>
                                </div>
                                <div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.seniorCitizen} readOnly />
                                        <span className="ml-2">Senior Citizen</span>
                                    </div>
                                    <span className="font-medium">Senior Citizen ID No.</span>
                                    <p>{resident.seniorcitizenid_num || '-'}</p>
                                </div>
                            </div>

                            <h2 className="text-2xl font-semibold mt-6 mb-6">Permanent Address</h2>
                            <div className="grid grid-cols-4 gap-4 ">
                                <div>
                                    <span className="font-medium">Unit/Floor/Room No.</span>
                                    <p>{resident.permanentAddress.unitFloorRoomNo || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Building</span>
                                    <p>{resident.permanentAddress.building || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Block No.</span>
                                    <p>{resident.permanentAddress.blockNo ? `Block ${resident.permanentAddress.blockNo}` : '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Lot No.</span>
                                    <p>{resident.permanentAddress.lotNo ? `Lot ${resident.permanentAddress.lotNo}` : '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Phase No.</span>
                                    <p>{resident.permanentAddress.phaseNo || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">House No.</span>
                                    <p>{resident.permanentAddress.houseNo || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Street</span>
                                    <p>{resident.permanentAddress.street || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Subdivision</span>
                                    <p>{resident.permanentAddress.subdivision || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Barangay</span>
                                    <p>{resident.permanentAddress.barangay || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">City</span>
                                    <p>{resident.permanentAddress.city || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Province</span>
                                    <p>{resident.permanentAddress.province || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Region</span>
                                    <p>{resident.permanentAddress.region || '-'}</p>
                                </div>
                            </div>

                            <details className="dropdown mt-6">
                            <summary className="text-2xl font-semibold cursor-pointer" onClick={togglePresentAddress}>
                                Present Address
                            </summary>
                            {isPresentAddressOpen && (
                                <div className="grid grid-cols-4 gap-4">
                                    <div>
                                        <span className="font-medium">Unit/Floor/Room No.:</span>
                                        <p>{resident.presentAddress.unitFloorRoomNo || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Building</span>
                                        <p>{resident.presentAddress.building || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Block No.</span>
                                        <p>{resident.presentAddress.blockNo ? `Block ${resident.presentAddress.blockNo}` : '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Lot No.</span>
                                        <p>{resident.presentAddress.lotNo ? `Lot ${resident.presentAddress.lotNo}` : '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Phase No.</span>
                                        <p>{resident.presentAddress.phaseNo || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">House No.</span>
                                        <p>{resident.presentAddress.houseNo || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Street</span>
                                        <p>{resident.presentAddress.street || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Subdivision</span>
                                        <p>{resident.presentAddress.subdivision || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Barangay</span>
                                        <p>{resident.presentAddress.barangay || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">City</span>
                                        <p>{resident.presentAddress.city || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Province</span>
                                        <p>{resident.presentAddress.province || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Region</span>
                                        <p>{resident.presentAddress.region || '-'}</p>
                                    </div>
                                </div>
                            )}
                            </details>
                            <h2 className="text-2xl font-semibold mt-6 mb-2">Other Information</h2>
                            <div className="grid grid-cols-4 gap-4">
                                <div>
                                    <span className="font-medium">PhilSys No.</span>
                                    <p>{resident.philsys_num || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Voters ID No.</span>
                                    <p>{resident.voters_id || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">SSS ID No.</span>
                                    <p>{resident.sss_num || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Pag-IBIG MID No.</span>
                                    <p>{resident.pagibig_num || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Philhealth No.</span>
                                    <p>{resident.philhealth_num || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">TIN No.</span>
                                    <p>{resident.tin_num || '-'}</p>
                                </div>
                            </div>
                            <attachment>
                            <h2 className="text-2xl font-semibold mt-6 mb-4">Valid IDs</h2>
                            <div className="grid grid-cols-4 gap-4">
                                {resident.validIDs && resident.validIDs.length > 0 ? (
                                    resident.validIDs.map((idUrl, index) => (
                                        <div key={index} className="flex flex-col items-center">
                                            {/* Display Image Preview if ID is an image */}
                                            {idUrl.match(/\.(jpeg|jpg|png)$/) ? (
                                                <img
                                                    src={idUrl}
                                                    alt={`Valid ID ${index + 1}`}
                                                    className="min-w-52  object-contain border border-gray-300 rounded-md shadow-md"
                                                />
                                            ) : (
                                                /* Provide a Download Link for non-image IDs (e.g., PDFs) */
                                                <a
                                                    href={idUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-600 underline font-medium"
                                                >
                                                    Download Valid ID {index + 1}
                                                </a>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-500">No valid IDs uploaded</p>
                                )}
                            </div>
                            </attachment>
                    </div>
                    )}
                  </div>
                  </div>
                </main>
            </div>
        </div>
    );
};

export default EditHousehold;
