import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './user/screens/Home';
import ProtectedRoute from './ProtectedRoute';
import ResidentManagement from './user/screens/ResidentManagement';
import AddResident from './user/screens/AddResident';
import BarangayProfiling from './user/screens/BarangayProfiling';
import LoginOption from './user/screens/LoginOption';
import RegisterResident from './user/screens/Resident/RegisterResident';
import ResidentHome from './user/screens/Resident/ResidentHome';
import ViewRequest from './component/ViewRequest';
import Announcements from './user/screens/Announcements';
import EvacuationMap from './user/screens/Resident/Map/EvacuationMap';
import ResidentDocRequest from './user/screens/Resident/ResidentDocRequest';
import DocumentRequest from './user/screens/DocumentRequest';
import OfficialsEvacuationMap from './user/screens/Map/OfficialsEvacuationMap';
import ResidentBarangayDirectory from './user/screens/Resident/ResidentBarangayDirectory';
import ResidentAnnouncementScreen from './user/screens/Resident/ResidentAnnouncementScreen';
import ResidentMessages from './user/screens/Resident/ResidentMessages';
import ResidentIncidentReport from './user/screens/Resident/ResidentIncidentReport';
import IncidentReport from './user/screens/IncidentReport';
import Messages from './user/screens/Messages';
import ResetPasswordInput from './user/forgotpassword/ResetPasswordInput';
import ForgotpassEmailInput from './user/forgotpassword/ForgotpassEmailInput';
import EnterSecurityCode from './user/forgotpassword/EnterSecurityCode';
import BarangayInformation from './user/screens/BarangayInformation';
import AdminForgotpassEmailInput from './user/forgotpassword/AdminForgotpassEmailInput';
import AdminEnterSecurityCode from './user/forgotpassword/AdminEnterSecurityCode';
import AdminResetPasswordInput from './user/forgotpassword/AdminResetPasswordInput';
import EmergencyHotlines from './user/screens/EmergencyHotlines';
import Profile from './user/screens/Profile';
import BarangayDirectory from './user/screens/BarangayDirectory';
import AddOfficial from './user/screens/AddOfficial';
import ResidentBarangayInformation from './user/screens/Resident/ResidentBarangayInformation';
import ResidentProfile from './user/screens/Resident/ResidentProfile';
import ResidentEmergencyHotlines from './user/screens/Resident/ResidentEmergencyHotlines';
import ViewResident from './component/ViewResident';
import ViewHousehold from './component/ViewHousehold';

import TechadminLogin from './user/techadmin/TechadminLogin';
import CreateBarangay from './user/techadmin/CreateBarangay';
import CreateAdminForm from './user/techadmin/CreateAdminForm';
import EditResident from './user/screens/EditResident';
import EditHousehold from './component/EditHousehold';
import ViewOfficial from './component/ViewOfficial';
import EditOfficial from './user/screens/EditOfficial';


function App() {
  return (
   <BrowserRouter>
    <Routes>
    <Route path="/Tech-Admin/Login" element={<TechadminLogin/>} />
    <Route
        path="/Tech-Admin/Create-Barangay"
        element={
                <CreateBarangay />
        }
    />
    <Route
        path="/Tech-Admin/Create-Captain-Account"
        element={
                <CreateAdminForm />
        }
    />
      <Route path="/" element={<LoginOption/>} />
      <Route path="/Resident/Register" element={<RegisterResident/>} />

      {/* Residents Forgot password routes */}
      <Route path="/Resident/forgot-password" element={<ForgotpassEmailInput />} />
      <Route path="/Resident/enter-security-code" element={<EnterSecurityCode />} />
      <Route 
        path="/Resident/reset-password" 
        element={
          <ProtectedRoute requiredRole="reset-password">
            <ResetPasswordInput />
          </ProtectedRoute>
        }
      />

     {/* Routes for residents only */}
     <Route 
          path='/Resident/Home' 
          element={
            <ProtectedRoute requiredRole="resident">
              <ResidentHome />
            </ProtectedRoute>
          } 
        />
      <Route 
          path='/Resident/Profile' 
          element={
            <ProtectedRoute requiredRole="resident">
              <ResidentProfile />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Resident/EvacuationMap' 
          element={
            <ProtectedRoute requiredRole="resident">
              <EvacuationMap />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Resident/Messages' 
          element={
            <ProtectedRoute requiredRole="resident">
              <ResidentMessages />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Resident/Document-Request' 
          element={
            <ProtectedRoute requiredRole="resident">
              <ResidentDocRequest />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Resident/Announcements' 
          element={
            <ProtectedRoute requiredRole="resident">
              <ResidentAnnouncementScreen/>
            </ProtectedRoute>
          } 
        />
        <Route
        path='/Resident/Barangay-Officials-Directory'
        element={
          <ProtectedRoute requiredRole="resident">
            <ResidentBarangayDirectory/>
          </ProtectedRoute>
        }
        />
        <Route 
          path='/Resident/Barangay-Information' 
          element={
            <ProtectedRoute requiredRole="resident">
              <ResidentBarangayInformation />
            </ProtectedRoute>
          } 
        />
        <Route
        path='/Resident/Complaint'
        element={
          <ProtectedRoute requiredRole="resident">
            <ResidentIncidentReport/>
          </ProtectedRoute>
        }
        />
        <Route
        path='/Resident/Emergency-Hotlines'
        element={
          <ProtectedRoute requiredRole="resident">
            <ResidentEmergencyHotlines/>
          </ProtectedRoute>
        }
        />
       {/* Routes for admin only */}
      <Route 
          path='/home' 
          element={
            <ProtectedRoute requiredRole="admin">
              <Home />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Profile/:objectID' 
          element={
            <ProtectedRoute requiredRole="admin">
              <Profile />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/AddResident' 
          element={
            <ProtectedRoute requiredRole="admin">
              <AddResident />
            </ProtectedRoute>
          } 
        />

         <Route 
          path='/Emergency-Hotlines' 
          element={
            <ProtectedRoute requiredRole="admin">
              <EmergencyHotlines />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Barangay-Directory' 
          element={
            <ProtectedRoute requiredRole="admin">
              <BarangayDirectory/>
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Add-Barangay-Official' 
          element={
            <ProtectedRoute requiredRole="admin">
              <AddOfficial/>
            </ProtectedRoute>
          } 
        />
        
          <Route 
          path='/Messages' 
          element={
            <ProtectedRoute requiredRole="admin">
              <Messages/>
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/ResidentManagement' 
          element={
            <ProtectedRoute requiredRole="admin">
              <ResidentManagement />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/edit-resident/:residentID' 
          element={
            <ProtectedRoute requiredRole="admin">
              <EditResident />
            </ProtectedRoute>
          } 
        />
         <Route 
          path='/edit-household/:householdID' 
          element={
            <ProtectedRoute requiredRole="admin">
              <EditHousehold />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Announcement' 
          element={
            <ProtectedRoute requiredRole="admin">
              <Announcements />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Complaint' 
          element={
            <ProtectedRoute requiredRole="admin">
              <IncidentReport />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Document-Request' 
          element={
            <ProtectedRoute requiredRole="admin">
              <DocumentRequest />
            </ProtectedRoute>
          } 
        />
         <Route 
          path="/view-request/:residentID" 
          element={
            <ProtectedRoute requiredRole="admin">
              <ViewRequest />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/view-household/:residentID" 
          element={
            <ProtectedRoute requiredRole="admin">
              <ViewHousehold />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/view-resident/:residentID" 
          element={
            <ProtectedRoute requiredRole="admin">
              <ViewResident />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/view-official/:id" 
          element={
            <ProtectedRoute requiredRole="admin">
              <ViewOfficial />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/edit-official/:id' 
          element={
            <ProtectedRoute requiredRole="admin">
              <EditOfficial />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/EvacuationMap' 
          element={
            <ProtectedRoute requiredRole="admin">
              <OfficialsEvacuationMap />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/BarangayProfiling' 
          element={
            <ProtectedRoute requiredRole="admin">
              <BarangayProfiling />
            </ProtectedRoute>
          } 
        />
        <Route 
          path='/Barangay-Information' 
          element={
            <ProtectedRoute requiredRole="admin">
              <BarangayInformation/>
            </ProtectedRoute>
          } 
        />
      {/* Admin Forgot password routes */}
      <Route path="/Admin/forgot-password" element={<AdminForgotpassEmailInput />} />
      <Route path="/Admin/enter-security-code" element={<AdminEnterSecurityCode />} />
      <Route 
        path="/Admin/reset-password" 
        element={
        <ProtectedRoute requiredRole="reset-password">
            <AdminResetPasswordInput />
        </ProtectedRoute>
        } 
      />
    </Routes>
   </BrowserRouter>
  );
}

export default App;
