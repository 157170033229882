import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert

const ResidentDocumentRequestModal = ({ documentRequest, onClose, onSave }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [householdMembers, setHouseholdMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        documentType: documentRequest.documentType,
        otherDocumentType: '', // For 'Others' option
        purpose: documentRequest.purpose,
        otherPurpose: '', // For 'Others' option
        recipient: documentRequest.recipient,
        residentName: documentRequest.residentName,
        validID: documentRequest.ValidID || [], // Existing attachments (array of URLs/objects)
        newValidID: [], // New attachments to upload
        newValidIDDisplay: [], // To store displayed filenames of new uploads
        removedFiles: [], // Keep track of removed existing files
        recipientID: documentRequest.recipientID || '' // Default the recipientID if it exists
    });
    
    
     // Fetch household members when the modal is in edit mode
     useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && isEditMode) {
            fetchHouseholdMembers(user.householdID); // Fetch household members by household ID
        }
    }, [isEditMode]);

    const fetchHouseholdMembers = async (householdId) => {
        setLoading(true); // Set loading to true
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/id/${householdId}`);
            const members = response.data.members;
            setHouseholdMembers(members);  
        } catch (error) {
            console.error('Error fetching household members:', error);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle file upload changes for new files (added under the existing ones)
    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        const newDisplayFiles = newFiles.map((file) => ({
            name: file.name,
            type: file.type,
        }));
    
        setFormData((prevData) => ({
            ...prevData,
            newValidID: [...prevData.newValidID, ...newFiles], // Add new files to any existing ones
            newValidIDDisplay: [...prevData.newValidIDDisplay, ...newDisplayFiles], // Display newly added files
        }));
    };

    // Remove an existing attachment or newly added one
    const handleRemoveAttachment = (index, isNew = false) => {
        if (isNew) {
            // Remove newly added files
            const updatedNewValidID = formData.newValidID.filter((_, i) => i !== index);
            const updatedNewValidIDDisplay = formData.newValidIDDisplay.filter((_, i) => i !== index);
            setFormData({
                ...formData,
                newValidID: updatedNewValidID,
                newValidIDDisplay: updatedNewValidIDDisplay,
            });
        } else {
            // Add to removedFiles list and update validID list
            const removedFile = formData.validID[index];
            const updatedValidID = formData.validID.filter((_, i) => i !== index);
            setFormData((prevData) => ({
                ...prevData,
                validID: updatedValidID,
                removedFiles: [...prevData.removedFiles, removedFile], // Track the removed file
            }));
        }
    };

    // Toggle edit mode
    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    const handleSave = async () => {
        // Show loading alert
        Swal.fire({
            title: 'Saving...',
            text: 'Please wait while we save your changes.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    
        const finalData = {
            ...formData,
            documentType: formData.documentType === 'Others' ? formData.otherDocumentType : formData.documentType,
            purpose: formData.purpose === 'Others' ? formData.otherPurpose : formData.purpose,
            recipientID: formData.recipientID || documentRequest.recipientID, // Preserve recipientID if it's already set
            status: formData.status || 'Pending',  // Update the status to 'Pending' on save
        };
    
        const formDataToSend = new FormData();
        formDataToSend.append('documentType', finalData.documentType);
        formDataToSend.append('purpose', finalData.purpose);
        formDataToSend.append('recipient', finalData.recipient);
        formDataToSend.append('recipientID', finalData.recipientID);
        formDataToSend.append('residentName', finalData.residentName);
        formDataToSend.append('status', finalData.status);
    
        // Append existing valid ID files (those not removed)
        formData.validID.forEach((existingFile) => {
            formDataToSend.append('validID[]', JSON.stringify(existingFile));
        });
    
        // Append new files (new valid IDs)
        formData.newValidID.forEach((file) => {
            formDataToSend.append('ValidID', file);
        });
    
        // Append removed files
        formDataToSend.append('removedFiles', JSON.stringify(formData.removedFiles));
    
        try {
            // Pass the FormData object to the parent component's onSave
            await onSave(formDataToSend);
    
            // If save is successful, close loading and show success message
            Swal.fire({
                icon: 'success',
                title: 'Saved!',
                text: 'Your document request has been updated successfully and is now pending.',
            });
        } catch (error) {
            console.error('Error saving document request:', error);
            // Show error message if something goes wrong
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to save the document request. Please try again.',
            });
        }
    };
    
    
    const archiveDocumentRequest = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to archive this document request?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Archive it!',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/document-requests/${documentRequest._id}`, {
                        status: 'Archived',
                    });
    
                    Swal.fire('Archived!', 'The document request has been archived.', 'success');
    
                    // Refresh document request list after archiving
                    onSave();
                    onClose();
                } catch (error) {
                    console.error('Error archiving document request:', error);
                    Swal.fire('Error', 'There was an issue archiving the document request.', 'error');
                }
            }
        });
    };
    
    
    const handleRecipientChange = (e) => {
        const selectedMember = householdMembers.find(member => 
            `${member.firstName} ${member.middleName ? member.middleName.charAt(0) + '.' : ''} ${member.lastName}` === e.target.value
        );
        setFormData({
            ...formData,
            recipient: e.target.value,
            recipientID: selectedMember ? selectedMember.residentID : formData.recipientID
        });
    };
    


    // Format date for display
    const formattedDate = new Date(documentRequest.created_at).toLocaleDateString();

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto" onClick={onClose}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/4 md:w-2/2 max-h-[80vh] scrollbar-thin overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                <h2 className="text-xl font-semibold mb-4">{isEditMode ? 'Edit Document Request' : 'View Document Request'}</h2>
                <div className="text-left mb-4">
                    <p className="font-semibold">Reference No.</p>
                    <p>{documentRequest.ReferenceNo}</p>
                </div>
                <div className="text-left mb-4">
                    <p className="font-semibold">Date Requested</p>
                    <p>{formattedDate}</p>
                </div>

                {documentRequest.status === 'Rejected' && documentRequest.remarks && (
                    <div className="text-left mb-4">
                        <p className="font-semibold">Remarks</p>
                        <p>{documentRequest.remarks}</p>
                    </div>
                )}

                {/* Document Type Section */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Type</p>
                    {isEditMode ? (
                        <div>
                            <select
                                name="documentType"
                                value={formData.documentType}
                                onChange={handleInputChange}
                                className="form-select mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                            >
                                <option value="">Select Document Type</option>
                                <option value="Certificate of Indigency">Certificate of Indigency</option>
                                <option value="Certificate of Residency">Certificate of Residency</option>
                                <option value="Certificate of Good Moral Character">Certificate of Good Moral Character</option>
                                <option value="Certificate of Local Employment">Certificate of Local Employment</option>
                                <option value="Certificate of Financial Assistance">Certificate of Financial Assistance</option>
                                <option value="Certificate of First Time Jobseeker">Certificate of First Time Jobseeker</option>
                                <option value="Barangay Clearance">Barangay Clearance</option>
                                <option value="Barangay Business Permit">Barangay Business Permit</option>
                                <option value="Others">Others</option>
                            </select>
                            {formData.documentType === 'Others' && (
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Specify Other Document Type</label>
                                    <input
                                        type="text"
                                        name="otherDocumentType"
                                        value={formData.otherDocumentType}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                        placeholder="Enter document type"
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <p>{documentRequest.documentType}</p>
                    )}
                </div>

                {/* Purpose Section */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Purpose</p>
                    {isEditMode ? (
                        <div>
                            <select
                                name="purpose"
                                value={formData.purpose}
                                onChange={handleInputChange}
                                className="form-select mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                            >
                                <option value="">Select Purpose</option>
                                <option value="Work">Work</option>
                                <option value="Open Bank Account">Open Bank Account</option>
                                <option value="Business">Business</option>
                                <option value="School">School</option>
                                <option value="Travel">Travel</option>
                                <option value="Others">Others</option>
                            </select>
                            {formData.purpose === 'Others' && (
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Specify Other Purpose</label>
                                    <input
                                        type="text"
                                        name="otherPurpose"
                                        value={formData.otherPurpose}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                                        placeholder="Enter purpose"
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <p>{documentRequest.purpose}</p>
                    )}
                </div>

                {/* Recipient Section */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Recipient</p>
                    {isEditMode ? (
                        <select
                            name="recipient"
                            value={formData.recipient}
                            onChange={handleRecipientChange}
                            className="form-select mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Select a Recipient</option>
                            {householdMembers.map((member) => (
                                <option key={member._id} value={`${member.firstName} ${member.middleName ? member.middleName.charAt(0) + '.' : ''} ${member.lastName}`}>
                                    {`${member.firstName} ${member.middleName ? member.middleName.charAt(0) + '.' : ''} ${member.lastName}`}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <p>{documentRequest.recipient}</p>
                    )}
                </div>

                <div className="text-left mb-4">
                    <p className="font-semibold">Resident Name</p>
                    {isEditMode ? (
                        <input
                            type="text"
                            name="residentName"
                            value={formData.residentName}
                            onChange={handleInputChange}
                            className="form-input mt-1 block w-full"
                            disabled={true}
                        />
                    ) : (
                        <p>{documentRequest.residentName}</p>
                    )}
                </div>

                {/* Attachments */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Attachment</p>
                    <div className="flex flex-col space-y-2">
                        {formData.validID.length > 0 ? (
                            formData.validID.map((id, index) => (
                                <div key={index} className="relative flex items-center justify-between p-2 border rounded-lg shadow-sm bg-gray-50">
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 bg-[#1346AC] text-white flex items-center justify-center rounded-full mr-3">
                                            <i className="fas fa-file-pdf"></i> 
                                        </div>
                                        <div className="truncate max-w-xs">
                                            <a href={id.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 font-semibold hover:underline truncate">
                                                {id.originalname}
                                            </a>
                                            <p className="text-gray-500 text-xs">PDF</p>
                                        </div>
                                    </div>
                                    {isEditMode && (
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveAttachment(index)}
                                            className="absolute right-0 top-0 text-black hover:text-red-600 mr-2"
                                        >
                                            <i className="fas fa-times"></i>
                                        </button>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className="text-gray-500">No attachments available</div>
                        )}
                    </div>
                </div>
                
                {isEditMode && (
                    <div className="mt-2">
                        <div className="flex flex-col space-y-2 mt-2">
                            {formData.newValidIDDisplay.map((file, index) => (
                                <div key={index} className="relative flex items-center justify-between p-2 border rounded-lg shadow-sm bg-gray-50">
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 bg-[#1346AC] text-white flex items-center justify-center rounded-full mr-3">
                                            <i className="fas fa-file-pdf"></i> 
                                        </div>
                                        <div className="truncate max-w-xs">
                                            <span className="text-blue-600 font-semibold hover:underline truncate">
                                                {file.name}
                                            </span>
                                            <p className="text-gray-500 text-xs">{file.type === 'application/pdf' ? 'PDF' : 'Image'}</p>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveAttachment(index, true)}
                                        className="absolute right-0 top-0 text-black hover:text-red-600 mr-2"
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            ))}
                        </div>
                        <input type="file" multiple onChange={handleFileChange} className="mt-2 block w-full p-2 border border-gray-300 rounded-md" />
                    </div>
                )}

                {/* Buttons */}
                <div className="flex flex-col space-y-2 text-center mt-6">
                    {isEditMode ? (
                        <>
                            <button onClick={handleSave} className="bg-[#1346AC] hover:bg-blue-700 text-white px-4 py-2 rounded-full font-semibold">
                                Save Changes
                            </button>
                            <button onClick={() => setIsEditMode(false)} className="bg-[#1346AC] hover:bg-blue-700 text-white px-4 py-2 rounded-full font-semibold">
                                Cancel
                            </button>
                        </>
                    ) : (
                        <button onClick={toggleEditMode} className="bg-[#1346AC] hover:bg-blue-700 text-white px-4 py-2 rounded-full font-semibold">
                            Edit Document Request
                        </button>
                    )}

                    <button 
                        onClick={archiveDocumentRequest} 
                        className="border text-red-500 border-red-500 px-4 py-2 rounded-full hover:bg-red-500 hover:text-white transition font-semibold"
                    >
                        Archive
                    </button>

                    <button onClick={onClose} className="bg-white text-[#1346AC] hover:bg-[#1346AC] hover:text-white border border-[#1346AC] px-4 py-2 rounded-full font-semibold mt-2">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ResidentDocumentRequestModal;
