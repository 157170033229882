import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const IncidentReport = () => {
    const [incidentReports, setIncidentReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredIncidents, setFilteredIncidents] = useState([]);
    const reportsPerPage = 5; // Set the max content per page
    const [pageRange, setPageRange] = useState([1, 2, 3, 4]); // Set the visible page range
    const navigate = useNavigate();

    // Fetch incident reports from the backend when the component mounts
    useEffect(() => {
        const fetchIncidentReports = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/incident-reports`);
                const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setIncidentReports(sortedData);
                setFilteredIncidents(sortedData); // Set the filtered incidents initially
            } catch (error) {
                console.error("Error fetching incident reports", error);
            }
        };

        fetchIncidentReports();
    }, []);

    // Calculate the indexes of the first and last incidents for the current page
    const indexOfLastIncident = currentPage * reportsPerPage;
    const indexOfFirstIncident = indexOfLastIncident - reportsPerPage;
    const currentIncidents = filteredIncidents.slice(indexOfFirstIncident, indexOfLastIncident);

    // Calculate total pages
    const totalPages = Math.ceil(filteredIncidents.length / reportsPerPage);

    // Handle page change
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        if (pageNumber > pageRange[3] && pageNumber <= totalPages) {
            setPageRange(pageRange.map(page => page + 1));
        }
        if (pageNumber < pageRange[0] && pageNumber >= 1) {
            setPageRange(pageRange.map(page => page - 1));
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
            if (currentPage <= pageRange[0]) {
                setPageRange(pageRange.map(page => page - 1));
            }
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
            if (currentPage >= pageRange[3]) {
                setPageRange(pageRange.map(page => page + 1));
            }
        }
    };

    return (
        <div className="bg-white p-6  rounded shadow">
            <div className="flex justify-between mb-4">
                <h2 className="text-lg font-semibold">Complaints</h2>
                <button
                    className="bg-[#1346AC] text-white px-4 py-2 rounded-full hover:bg-blue-700 font-semibold"
                    onClick={() => navigate('/Complaint')}
                >
                    View Complaints
                </button>
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border text-center">
                    <thead>
                        <tr>
                            <th className="py-2 border-b border-r border-gray-200">Date Requested</th>
                            <th className="py-2 border-b border-r border-gray-200">Type</th>
                            <th className="py-2 border-b border-r border-gray-200">Complainant Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentIncidents.length > 0 ? (
                            currentIncidents.map((report, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-4 border-b border-r">{new Date(report.created_at).toLocaleDateString()}</td>
                                    <td className="py-2 px-4 border-b border-r truncate">{report.typeofcomplaint}</td>
                                    <td className="py-2 px-4 border-b truncate">{report.complainantname[0]}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="py-2 px-4 text-center">No complaints available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>  

            <div className="flex justify-between items-end mt-4">
                <div className="text-sm text-gray-600" >
                    Showing {Math.min(indexOfLastIncident, filteredIncidents.length)} of {filteredIncidents.length} entries
                </div>
                <div className="flex items-center space-x-2 font-semibold">
                    <button
                        onClick={handlePrevious}
                        className="px-3 py-1 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    {pageRange.map((pageNumber) => (
                        <button
                            key={pageNumber}
                            onClick={() => paginate(pageNumber)}
                            className={`px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-[#1346AC] text-white' : 'bg-gray-200 hover:bg-[#1346AC] hover:text-white'}`}
                            disabled={pageNumber > totalPages}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    <button
                        onClick={handleNext}
                        className="px-3 py-1 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IncidentReport;
