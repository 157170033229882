import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navigation from './Navigation';
import Header from './Header';
import Swal from 'sweetalert2';

const ViewResident = () => {
    const [resident, setResident] = useState(null);
    const [householdMembers, setHouseholdMembers] = useState([]);
    const [householdHead, setHouseholdHead] = useState('');
    const [householdHeadContactNumber, setHouseholdHeadContactNumber] = useState('');
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [adminData, setAdminData] = useState(null);
    const [isPresentAddressOpen, setIsPresentAddressOpen] = useState(false);
    const [isOfficial, setIsOfficial] = useState(false);
    const [officialId, setOfficialId] = useState(null);
    const { residentID } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchResidentAndHouseholdDetails();
    }, []);

    const fetchResidentAndHouseholdDetails = async () => {
        try {
            // Fetch resident details
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/${residentID}`);
            const residentData = response.data;
            setResident(residentData);
    
            // ✅ Check if the resident's email exists in the Admin collection
            if (residentData.email) {
                checkIfResidentIsOfficial(residentData.email);
            }
    
            // Fetch household details if applicable
            if (residentData.householdID) {
                fetchHouseholdByResident(residentData._id);
            } else {
                setHouseholdHead('');
                setHouseholdHeadContactNumber('');
                setHouseholdMembers([]);
            }
        } catch (error) {
            console.error('Error fetching resident details:', error);
        }
    };
    

    const checkIfResidentIsOfficial = async (email) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/check-official/${email}`);
            
            if (response.data.isOfficial) {
                setIsOfficial(true);
                setOfficialId(response.data.officialId); // ✅ Store official's ID
            } else {
                setIsOfficial(false);
                setOfficialId(null);
            }
        } catch (error) {
            console.error('Error checking official status:', error);
            setIsOfficial(false);
            setOfficialId(null);
        }
    };
    

    
    const fetchHouseholdByResident = async (residentId) => {
        try {
            // Use the new API endpoint to fetch household info by resident ID
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/resident/${residentId}`);
            if (response.data) {
                const { householdHeadName, householdHeadContactNumber, members } = response.data;
                setHouseholdHead(householdHeadName);
                setHouseholdHeadContactNumber(householdHeadContactNumber);
                setHouseholdMembers(members);
            } else {
                console.warn('Household information not found for this resident.');
                setHouseholdHead('');
                setHouseholdHeadContactNumber('');
                setHouseholdMembers([]);
            }
        } catch (error) {
            console.error('Error fetching household info:', error.response?.data?.message || error.message);
        }
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

      useEffect(() => {
          const user = JSON.parse(localStorage.getItem('user'));
          if (user) {
              if (user.profilepic) {
                  user.profilepic = user.profilepic.replace(/\\/g, '/');
              }
              const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
              const firstName = capitalizeWords(user.firstName);
              const lastName = capitalizeWords(user.lastName);
              const middleInitial = user.middleName ? capitalizeWords(user.middleName.charAt(0)) + '.' : '';
              setUserName(`${firstName} ${middleInitial} ${lastName}`);
              setAdminData(user);
              setUserRole(user.roleinBarangay);
          }
      }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    const handleBackClick = () => {
        navigate('/ResidentManagement', { state: { activeTab: 'Residents' } });
    };

    const handleEditClick = () => {
        if (isOfficial && officialId) {
            Swal.fire({
                title: 'This is an official',
                text: 'You are about to edit an official’s information. Do you want to continue?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, proceed',
                cancelButtonText: 'No, stay here',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/edit-official/${officialId}`); // ✅ Navigate using official's ID
                }
            });
        } else {
            navigate(`/edit-resident/${residentID}`);
        }
    };
    
    

    // Toggle the dropdown for Present Address
    const togglePresentAddress = () => {
        setIsPresentAddressOpen(!isPresentAddressOpen);
    };

    if (!resident) return <div>Loading...</div>;

    return (
        <div className="flex flex-col min-h-screen">
            <Header userName={userName} userRole={userRole} handleLogout={handleLogout} profilePic={adminData?.profilepic}/>
            <div className="flex flex-1">
                <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                <main className="flex-1 p-8 bg-gray-100">
                    <div className="flex items-center mb-7">
                        <button
                            className="text-xl text-gray-500 hover:text-[#1346AC] cursor-pointer font-semibold mr-10"
                            onClick={handleBackClick}
                        >
                            &lt; Back
                        </button>
                        <h1 className="text-4xl font-bold">View Resident</h1>
                    </div>

                    <div className='flex w-full bg-white rounded-lg shadow-md'>
                        <div className="bg-white rounded-lg w-1/5 flex flex-col items-center justify-between p-6">
                            {resident.profilepic ? (
                                <img 
                                    src={resident.profilepic} 
                                    alt="Profile" 
                                    className="w-52 h-52 rounded-sm object-cover"
                                />
                            ) : (
                                <div className="w-32 h-32 rounded-full bg-gray-200 flex mt-2 items-center justify-center">
                                    <span className="text-gray-500">No Image</span>
                                </div>
                            )}
                            <div className='flex flex-col justify-evenly space-y-2 w-full px-4'>
                            <button 
                                onClick={handleEditClick} 
                                className="px-4 py-2 bg-[#1346AC] text-white font-semibold rounded-full hover:bg-blue-600"
                            >
                                Edit Resident
                            </button>
                            </div>
                        </div>
                        <div className="w-4/5 overflow-y-auto max-h-[800px] p-6 px-6">
                            <h2 className="text-2xl font-semibold mb-4">Household Information</h2>
                            <div className="flex justify-between w-full">
                                <div className='flex flex-col'><span className="font-medium">Household Role</span> {resident.roleinHousehold || '-'}</div>
                                <div className='flex flex-col' ><span className="font-medium">Household No.</span> {resident.householdID?.householdID || '-'}</div>
                                <div className='flex flex-col' ><span className="font-medium">Household Head</span> {householdHead || '-'}</div>
                                <div className='flex flex-col'><span className="font-medium">Relationship to Household Head</span> {resident.reltohouseholdhead || '-'}</div>
                            </div>
                            <h2 className="text-2xl font-semibold mt-4 mb-2">Household Members</h2>
                            <table className="min-w-full bg-white">
                                <thead>
                                    <tr className="border text-center">
                                        <th className="py-2 border-r">Resident No.</th>
                                        <th className="py-2 border-r">Name</th>
                                        <th className="py-2 border-r">Sex</th>
                                        <th className="py-2 border-r">Age</th>
                                        <th className="py-2 border-r">Role</th>
                                        <th className="py-2 border-r">Contact Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {householdMembers.map((member, index) => (
                                        <tr key={index} className="border text-center">
                                            <td className="py-2 border-r">{member.residentID}</td>
                                            <td className="py-2 border-r">{`${member.firstName} ${member.middleName ? member.middleName + ' ' : ''}${member.lastName}`}</td>
                                            <td className="py-2 border-r">{member.sex}</td>
                                            <td className="py-2 border-r">{member.age}</td>
                                            <td className="py-2 border-r">{member.roleinHousehold}</td>
                                            <td className="py-2 border-r">{member.contactNumber}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <h2 className="text-2xl font-semibold mt-4 mb-4">Personal Information</h2>
                            <div className="grid grid-cols-4 gap-4">
                                <div>
                                    <span className="font-medium">Last Name</span>
                                    <p>{resident.lastName || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">First Name</span>
                                    <p>{resident.firstName || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Middle Name</span>
                                    <p>{resident.middleName || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Suffix</span>
                                    <p>{resident.suffix || 'None'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Sex</span>
                                    <p>{resident.sex || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Birthdate</span>
                                    <p>{formatDate(resident.birthday)}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Birthplace</span>
                                    <p>{resident.birthplace || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Age</span>
                                    <p>{resident.age || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Civil Status</span>
                                    <p>{resident.civilStatus || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Nationality</span>
                                    <p>{resident.nationality || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Religion</span>
                                    <p>{resident.religion || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Occupation</span>
                                    <p>{resident.occupation || '-'}</p>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 mt-4">
                                <div>
                                    <span className="font-medium">Email Address</span>
                                    <p>{resident.email || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Mobile Number</span>
                                    <p>{resident.contactNumber || '-'}</p>
                                </div>
                                <div className="flex space-x-4 items-center">
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.voter} readOnly />
                                        <span className="ml-2">Voter</span>
                                    </div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.indigent} readOnly />
                                        <span className="ml-2">Indigent</span>
                                    </div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.fourpsmember} readOnly />
                                        <span className="ml-2">4Ps</span>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 mt-4">
                                <div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.pwd} readOnly />
                                        <span className="ml-2">PWD</span>
                                    </div>
                                    <span className="font-medium">PWD ID No.</span>
                                    <p>{resident.pwdid_num || '-'}</p>
                                </div>
                                <div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.soloparent} readOnly />
                                        <span className="ml-2">Solo Parent</span>
                                    </div>
                                    <span className="font-medium">Solo Parent ID No.</span>
                                    <p>{resident.soloparentid_num || '-'}</p>
                                </div>
                                <div>
                                    <div className="flex items-center">
                                        <input type="checkbox" checked={resident.seniorCitizen} readOnly />
                                        <span className="ml-2">Senior Citizen</span>
                                    </div>
                                    <span className="font-medium">Senior Citizen ID No.</span>
                                    <p>{resident.seniorcitizenid_num || '-'}</p>
                                </div>
                            </div>

                            <h2 className="text-2xl font-semibold mt-6 mb-6">Permanent Address</h2>
                            <div className="grid grid-cols-4 gap-4 ">
                                <div>
                                    <span className="font-medium">Unit/Floor/Room No.</span>
                                    <p>{resident.permanentAddress.unitFloorRoomNo || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Building</span>
                                    <p>{resident.permanentAddress.building || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Block No.</span>
                                    <p>{resident.permanentAddress.blockNo ? `Block ${resident.permanentAddress.blockNo}` : '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Lot No.</span>
                                    <p>{resident.permanentAddress.lotNo ? `Lot ${resident.permanentAddress.lotNo}` : '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Phase No.</span>
                                    <p>{resident.permanentAddress.phaseNo || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">House No.</span>
                                    <p>{resident.permanentAddress.houseNo || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Street</span>
                                    <p>{resident.permanentAddress.street || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Subdivision</span>
                                    <p>{resident.permanentAddress.subdivision || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Barangay</span>
                                    <p>{resident.permanentAddress.barangay || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">City</span>
                                    <p>{resident.permanentAddress.city || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Province</span>
                                    <p>{resident.permanentAddress.province || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Region</span>
                                    <p>{resident.permanentAddress.region || '-'}</p>
                                </div>
                            </div>

                            <details className="dropdown mt-6">
                            <summary className="text-2xl font-semibold cursor-pointer" onClick={togglePresentAddress}>
                                Present Address
                            </summary>
                            {isPresentAddressOpen && (
                                <div className="grid grid-cols-4 gap-4">
                                    <div>
                                        <span className="font-medium">Unit/Floor/Room No.:</span>
                                        <p>{resident.presentAddress.unitFloorRoomNo || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Building</span>
                                        <p>{resident.presentAddress.building || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Block No.</span>
                                        <p>{resident.presentAddress.blockNo ? `Block ${resident.presentAddress.blockNo}` : '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Lot No.</span>
                                        <p>{resident.presentAddress.lotNo ? `Lot ${resident.presentAddress.lotNo}` : '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Phase No.</span>
                                        <p>{resident.presentAddress.phaseNo || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">House No.</span>
                                        <p>{resident.presentAddress.houseNo || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Street</span>
                                        <p>{resident.presentAddress.street || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Subdivision</span>
                                        <p>{resident.presentAddress.subdivision || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Barangay</span>
                                        <p>{resident.presentAddress.barangay || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">City</span>
                                        <p>{resident.presentAddress.city || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Province</span>
                                        <p>{resident.presentAddress.province || '-'}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium">Region</span>
                                        <p>{resident.presentAddress.region || '-'}</p>
                                    </div>
                                </div>
                            )}
                            </details>
                            <h2 className="text-2xl font-semibold mt-6 mb-2">Other Information</h2>
                            <div className="grid grid-cols-4 gap-4">
                                <div>
                                    <span className="font-medium">PhilSys No.</span>
                                    <p>{resident.philsys_num || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Voters ID No.</span>
                                    <p>{resident.voters_id || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">SSS ID No.</span>
                                    <p>{resident.sss_num || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Pag-IBIG MID No.</span>
                                    <p>{resident.pagibig_num || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">Philhealth No.</span>
                                    <p>{resident.philhealth_num || '-'}</p>
                                </div>
                                <div>
                                    <span className="font-medium">TIN No.</span>
                                    <p>{resident.tin_num || '-'}</p>
                                </div>
                            </div>
                            <attachment>
                            <h2 className="text-2xl font-semibold mt-6 mb-4">Valid IDs</h2>
                            <div className="grid grid-cols-4 gap-4">
                                {resident.validIDs && resident.validIDs.length > 0 ? (
                                    resident.validIDs.map((idUrl, index) => (
                                        <div key={index} className="flex flex-col items-center">
                                            {/* Display Image Preview if ID is an image */}
                                            {idUrl.match(/\.(jpeg|jpg|png)$/) ? (
                                                <img
                                                    src={idUrl}
                                                    alt={`Valid ID ${index + 1}`}
                                                    className="min-w-52  object-contain border border-gray-300 rounded-md shadow-md"
                                                />
                                            ) : (
                                                /* Provide a Download Link for non-image IDs (e.g., PDFs) */
                                                <a
                                                    href={idUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-600 underline font-medium"
                                                >
                                                    Download Valid ID {index + 1}
                                                </a>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-500">No valid IDs uploaded</p>
                                )}
                            </div>
                            </attachment>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ViewResident;
