import React, { useEffect, useState } from 'react'
import Navigation from '../../component/Navigation'
import Header from '../../component/Header'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import defaultProfile from '../../assets/icons/default-profile.png';
import ChangePasswordModal from '../../component/ChangePasswordModal'


const Profile = () => {
    const [adminData, setAdminData] = useState(null);
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [showPresentAddress, setShowPresentAddress] = useState(false);
    const [householdHead, setHouseholdHead] = useState('');
    const [householdHeadContactNumber, setHouseholdHeadContactNumber] = useState('');
    const [householdMembers, setHouseholdMembers] = useState([]);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [household, setHousehold] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const residentHouseholdID = localStorage.getItem('residentHouseholdID');  

        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, '/');
            }
            const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
            const firstName = capitalizeWords(user.firstName);
            const lastName = capitalizeWords(user.lastName);
            const middleInitial = user.middleName ? `${capitalizeWords(user.middleName.charAt(0))}.` : '';
            setUserName(`${firstName} ${middleInitial} ${lastName}`);
            setAdminData(user);
            setUserRole(user.roleinBarangay);

            // Fetch household information
            if (residentHouseholdID) {
                fetchHouseholdById(residentHouseholdID);
            }
        }
    }, []);

    const fetchHouseholdById = async (householdId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/id/${householdId}`);
            if (response.data) {
                const { householdHead, contactNumber, members } = response.data;
                setHouseholdHead(`${householdHead.firstName} ${householdHead.middleName ? householdHead.middleName.charAt(0) + '.' : ''} ${householdHead.lastName} ${householdHead.suffix}`);
                setHouseholdHeadContactNumber(contactNumber);
                setHouseholdMembers(members);
            } else {
                alert('No household information available');
                setHouseholdHead('');
                setHouseholdHeadContactNumber('');
                setHouseholdMembers([]);
            }
        } catch (error) {
            console.error('Error fetching household:', error.response || error.message);
            alert('Error fetching household information');
        }
    };
    
      const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('residentHouseholdID'); // Clear resident household ID on logout
        navigate('/');
      };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const togglePresentAddress = () => {
        setShowPresentAddress(!showPresentAddress); // Toggle state
    };
    
      const openChangePasswordModal = () => {
        setShowChangePasswordModal(true);
      };
    
      const closeChangePasswordModal = () => {
        setShowChangePasswordModal(false);
      };

  return (
    <div className="flex flex-col min-h-screen scrollbar-thick overflow-y-auto h-64">
    <Header userName={userName} userRole={userRole} handleLogout={handleLogout}  profilePic={adminData?.profilepic}/>
    <div className="flex flex-1">
        <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
        {/* Profile Information on the right */}
            <div className="flex-1 p-6 bg-white shadow-md rounded-lg">
                    {/* User Profile Section */}
                    <div className="flex items-center mb-6">
                        <img
                            src={adminData?.profilepic || defaultProfile} 
                            alt="Profile"
                            className="w-24 h-24 rounded-full border-2 border-gray-300 mr-4"
                        />
                        <div className="flex flex-col w-auto">
                            <div className='flex'>
                            <h1 className="text-3xl font-bold">{userName}</h1>
                            </div>
                            <div className="flex justify-start rounded-full w-auto">
                            <h1 className="font-semibold">
                                {adminData?.roleinBarangay}
                            </h1>
                        </div>
                        </div>
                    </div>

                    {/* Personal Information Section */}
                    <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Personal Information</h2>
                            <p>Resident ID No: {adminData?.adminID}</p>
                            <p>Birthdate: {adminData?.birthday}</p>
                            <p>Sex: {adminData?.sex}</p>
                            <p>Age: {adminData?.age}</p>
                            <p>Birthplace: {adminData?.birthplace}</p>
                            <p>Occupation Status: {adminData?.occupationStatus}</p>
                            <p>Nationality: {adminData?.nationality}</p>
                            <p>Civil Status: {adminData?.civilStatus}</p>
                            <p>Email Address: {adminData?.email}</p>
                            <p>Contact Number: {adminData?.contactNumber}</p>
                        </div>
                    </div>

                    <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Special Information</h2>
                            <p>PWD: {adminData?.pwd ? 'Yes' : 'No'}</p>
                            <p>Solo Parent: {adminData?.soloparent ? 'Yes' : 'No'}</p>
                            <p>Indigent: {adminData?.indigent ? 'Yes' : 'No'}</p>
                            <p>Senior Citizen: {adminData?.seniorCitizen ? 'Yes' : 'No'}</p>
                            <p>Indigenous People: {adminData?.indigenouspeople ? 'Yes' : 'No'}</p>
                            <p>4Ps Member: {adminData?.fourpsmember ? 'Yes' : 'No'}</p>
                            <p>Special Status: {adminData?.specialstatus ? 'Yes' : 'No'}</p>
                        </div>
                    </div>
                    {/* Permanent Address Section */}
                    <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Permanent Address</h2>
                            <p>House No.: {adminData?.permanentAddress?.houseNo}</p>
                            <p>Street: {adminData?.permanentAddress?.street}</p>
                            <p>Unit/Floor/Room No.: {adminData?.permanentAddress?.unitFloorRoomNo || 'N/A'}</p>
                            <p>Building: {adminData?.permanentAddress?.building || 'N/A'}</p>
                            <p>Block No.: {adminData?.permanentAddress?.blockNo || 'N/A'}</p>
                            <p>Lot No.: {adminData?.permanentAddress?.lotNo || 'N/A'}</p>
                            <p>Phase No.: {adminData?.permanentAddress?.phaseNo || 'N/A'}</p>
                            <p>Subdivision: {adminData?.permanentAddress?.subdivision || 'N/A'}</p>
                            <p>Barangay: {adminData?.permanentAddress?.barangay || 'N/A'}</p>
                            <p>City/Municipality: {adminData?.permanentAddress?.city || 'N/A'}</p>
                            <p>Province: {adminData?.permanentAddress?.province || 'N/A'}</p>
                            <p>Region: {adminData?.permanentAddress?.region || 'N/A'}</p>
                        </div>
                    </div>

                      <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <div className="flex items-center justify-between">
                                <h2 className="text-lg font-bold mb-2">Present Address</h2>
                                <button 
                                onClick={togglePresentAddress} 
                                className="px-4 py-2 text-white bg-[#1346AC] hover:bg-blue-700 transition-colors duration-300 rounded-md shadow-md"
                                >
                                {showPresentAddress ? 'Hide' : 'Show'}
                                </button>
                            </div>
                            {showPresentAddress && (
                                <div>
                                    <p>House No.: {adminData?.presentAddress?.houseNo || 'N/A'}</p>
                                    <p>Street: {adminData?.presentAddress?.street || 'N/A'}</p>
                                    <p>Unit/Floor/Room No.: {adminData?.presentAddress?.unitFloorRoomNo || 'N/A'}</p>
                                    <p>Building: {adminData?.presentAddress?.building || 'N/A'}</p>
                                    <p>Block No.: {adminData?.presentAddress?.blockNo || 'N/A'}</p>
                                    <p>Lot No.: {adminData?.presentAddress?.lotNo || 'N/A'}</p>
                                    <p>Phase No.: {adminData?.presentAddress?.phaseNo || 'N/A'}</p>
                                    <p>Subdivision: {adminData?.presentAddress?.subdivision || 'N/A'}</p>
                                    <p>Barangay: {adminData?.presentAddress?.barangay || 'N/A'}</p>
                                    <p>City/Municipality: {adminData?.presentAddress?.city || 'N/A'}</p>
                                    <p>Province: {adminData?.presentAddress?.province || 'N/A'}</p>
                                    <p>Region: {adminData?.presentAddress?.region || 'N/A'}</p>
                                    <p>Postal Code: {adminData?.presentAddress?.postalcode || 'N/A'}</p>
                                </div>
                            )}
                        </div>
                    </div>

               {/* Household Information Section */}
               <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Household Information</h2>
                            <p>Household Head: {householdHead || 'N/A'}</p>
                            <p>Household Head Contact: {householdHeadContactNumber || 'N/A'}</p>
                            <h3 className="mt-4 font-bold">Members:</h3>
                            <ul>
                                {householdMembers.length > 0 ? (
                                    householdMembers.map((member, index) => (
                                        <li key={index}>{member.firstName} {member.lastName} - {member.age} years old </li>
                                    ))
                                ) : (
                                    <p>No household members found.</p>
                                )}
                            </ul>
                        </div>
                    </div>

                    <div className="flex mb-4">
                        <div className="w-1 bg-blue-600"></div>
                        <div className="flex-1 p-4 bg-gray-100 rounded-lg shadow-inner">
                            <h2 className="text-lg font-bold mb-2">Other Information</h2>
                            <p>PWD ID No.: {adminData?.pwdid_num}</p>
                            <p>Solo Parent No.: {adminData?.soloparentid_num}</p>
                            <p>Senior Citizen ID No.: {adminData?.seniorcitizenid_num}</p>
                            <p>PhilSys No.: {adminData?.philsys_num}</p>
                            <p>Voter's ID no: {adminData?.voters_id}</p>
                            <p>SSS: {adminData?.sss_num}</p>
                            <p>Pag-IBIG: {adminData?.pagibig_num}</p>
                            <p>PhilHealth: {adminData?.philhealth_num}</p>
                            <p>TIN: {adminData?.tin_num}</p>
                        </div>
                    </div>
                    {/* Update Info Button */}
                    <div className="flex flex-col items-center justify-center mt-6 space-y-2">
                        <button
                            className="bg-[#1346AC] hover:bg-blue-700 w-1/4 text-white font-bold py-2 px-6 rounded-lg"
                            onClick={openChangePasswordModal}
                            >
                            Change Password
                            </button>
                    </div>
                </div>
            </div>
            <ChangePasswordModal isOpen={showChangePasswordModal} onClose={closeChangePasswordModal} />
        </div>
  )
}

export default Profile
