import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Navigation from "./Navigation";
import Header from "./Header";

const ViewOfficial = () => {
    const [official, setOfficial] = useState(null);
    const [userName, setUserName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [adminData, setAdminData] = useState(null);
    const [isPresentAddressOpen, setIsPresentAddressOpen] = useState(false);
    const { id: adminID } = useParams(); // Get adminID from URL params
    const navigate = useNavigate();

  // Debugging: Check if adminID is received correctly
    useEffect(() => {
        if (adminID && adminID !== "undefined") {
            fetchOfficialDetails(adminID);
        } else {
            console.error("Invalid adminID:", adminID);
        }
    }, [adminID]);

    // Fetch official details
    const fetchOfficialDetails = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/admin/id/${id}`);
            setOfficial(response.data);
        } catch (error) {
            console.error("Error fetching official details:", error.response?.data?.message || error.message);
        }
    };
    
    
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

      // Fetch user data from localStorage
      useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, "/");
            }
            const capitalizeWords = (str) =>
                str
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(" ");
            setUserName(`${capitalizeWords(user.firstName)} ${user.middleName ? capitalizeWords(user.middleName.charAt(0)) + "." : ""} ${capitalizeWords(user.lastName)}`);
            setAdminData(user);
            setUserRole(user.roleinBarangay);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const handleBackClick = () => {
        navigate('/Barangay-Directory');
    };

    const handleEditClick = () => {
        navigate(`/edit-official/${adminID}`);
    };    

    // Toggle the dropdown for Present Address
    const togglePresentAddress = () => {
        setIsPresentAddressOpen(!isPresentAddressOpen);
    };

    if (!official) return <div>Loading...</div>;

  return (
           <div className="flex flex-col min-h-screen">
               <Header userName={userName} userRole={userRole} handleLogout={handleLogout} profilePic={adminData?.profilepic}/>
               <div className="flex flex-1">
                   <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                   <main className="flex-1 p-8 bg-gray-100">
                       <div className="flex items-center mb-7">
                           <button
                               className="text-xl text-gray-500 hover:text-[#1346AC] cursor-pointer font-semibold mr-10"
                               onClick={handleBackClick}
                           >
                               &lt; Back
                           </button>
                           <h1 className="text-4xl font-bold">View Official</h1>
                       </div>
   
                       <div className='flex w-full bg-white rounded-lg shadow-md'>
                           <div className="bg-white rounded-lg w-1/5 flex flex-col items-center justify-between p-6">
                           {official.profilepic ? (
                                <img src={official.profilepic} alt="Profile" className="w-52 h-52 rounded-sm object-cover" />
                            ) : (
                                <div className="w-32 h-32 rounded-full bg-gray-200 flex mt-2 items-center justify-center">
                                    <span className="text-gray-500">No Image</span>
                                </div>
                            )}
                               <div className='flex flex-col justify-evenly space-y-2 w-full px-4'>
                               {userRole === "Barangay Captain" && (
                                    <button 
                                        onClick={handleEditClick} 
                                        className="px-4 py-2 bg-[#1346AC] text-white font-semibold rounded-full hover:bg-blue-600"
                                    >
                                        Edit Official
                                    </button>
                                )}
                               </div>
                           </div>
                           <div className="w-4/5 overflow-y-auto max-h-[800px] p-6 px-6">                            
   
                               <h2 className="text-2xl font-semibold mt-4 mb-4">Personal Information</h2>
                               <div className="grid grid-cols-4 gap-4">
                                   <div>
                                       <span className="font-medium">Last Name</span>
                                       <p>{official.lastName || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">First Name</span>
                                       <p>{official.firstName || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Middle Name</span>
                                       <p>{official.middleName || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Suffix</span>
                                       <p>{official.suffix || 'None'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Sex</span>
                                       <p>{official.sex || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Birthdate</span>
                                       <p>{formatDate(official.birthday)}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Birthplace</span>
                                       <p>{official.birthplace || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Age</span>
                                       <p>{official.age || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Civil Status</span>
                                       <p>{official.civilStatus || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Nationality</span>
                                       <p>{official.nationality || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Religion</span>
                                       <p>{official.religion || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Occupation</span>
                                       <p>{official.occupation || '-'}</p>
                                   </div>
                               </div>
   
                               <div className="grid grid-cols-4 gap-4 mt-4">
                                   <div>
                                       <span className="font-medium">Email Address</span>
                                       <p>{official.email || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Mobile Number</span>
                                       <p>{official.contactNumber || '-'}</p>
                                   </div>
                                   <div className="flex space-x-4 items-center">
                                       <div className="flex items-center">
                                           <input type="checkbox" checked={official.voter} readOnly />
                                           <span className="ml-2">Voter</span>
                                       </div>
                                       <div className="flex items-center">
                                           <input type="checkbox" checked={official.indigent} readOnly />
                                           <span className="ml-2">Indigent</span>
                                       </div>
                                       <div className="flex items-center">
                                           <input type="checkbox" checked={official.fourpsmember} readOnly />
                                           <span className="ml-2">4Ps</span>
                                       </div>
                                   </div>
                               </div>
   
                               <div className="grid grid-cols-4 gap-4 mt-4">
                                   <div>
                                       <div className="flex items-center">
                                           <input type="checkbox" checked={official.pwd} readOnly />
                                           <span className="ml-2">PWD</span>
                                       </div>
                                       <span className="font-medium">PWD ID No.</span>
                                       <p>{official.pwdid_num || '-'}</p>
                                   </div>
                                   <div>
                                       <div className="flex items-center">
                                           <input type="checkbox" checked={official.soloparent} readOnly />
                                           <span className="ml-2">Solo Parent</span>
                                       </div>
                                       <span className="font-medium">Solo Parent ID No.</span>
                                       <p>{official.soloparentid_num || '-'}</p>
                                   </div>
                                   <div>
                                       <div className="flex items-center">
                                           <input type="checkbox" checked={official.seniorCitizen} readOnly />
                                           <span className="ml-2">Senior Citizen</span>
                                       </div>
                                       <span className="font-medium">Senior Citizen ID No.</span>
                                       <p>{official.seniorcitizenid_num || '-'}</p>
                                   </div>
                               </div>
   
                               <h2 className="text-2xl font-semibold mt-6 mb-6">Permanent Address</h2>
                               <div className="grid grid-cols-4 gap-4 ">
                                   <div>
                                       <span className="font-medium">Unit/Floor/Room No.</span>
                                       <p>{official.permanentAddress.unitFloorRoomNo || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Building</span>
                                       <p>{official.permanentAddress.building || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Block No.</span>
                                       <p>{official.permanentAddress.blockNo ? `Block ${official.permanentAddress.blockNo}` : '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Lot No.</span>
                                       <p>{official.permanentAddress.lotNo ? `Lot ${official.permanentAddress.lotNo}` : '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Phase No.</span>
                                       <p>{official.permanentAddress.phaseNo || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">House No.</span>
                                       <p>{official.permanentAddress.houseNo || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Street</span>
                                       <p>{official.permanentAddress.street || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Subdivision</span>
                                       <p>{official.permanentAddress.subdivision || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Barangay</span>
                                       <p>{official.permanentAddress.barangay || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">City</span>
                                       <p>{official.permanentAddress.city || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Province</span>
                                       <p>{official.permanentAddress.province || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Region</span>
                                       <p>{official.permanentAddress.region || '-'}</p>
                                   </div>
                               </div>
   
                               <details className="dropdown mt-6">
                               <summary className="text-2xl font-semibold cursor-pointer" onClick={togglePresentAddress}>
                                   Present Address
                               </summary>
                               {isPresentAddressOpen && (
                                   <div className="grid grid-cols-4 gap-4">
                                       <div>
                                           <span className="font-medium">Unit/Floor/Room No.:</span>
                                           <p>{official.presentAddress.unitFloorRoomNo || '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">Building</span>
                                           <p>{official.presentAddress.building || '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">Block No.</span>
                                           <p>{official.presentAddress.blockNo ? `Block ${official.presentAddress.blockNo}` : '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">Lot No.</span>
                                           <p>{official.presentAddress.lotNo ? `Lot ${official.presentAddress.lotNo}` : '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">Phase No.</span>
                                           <p>{official.presentAddress.phaseNo || '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">House No.</span>
                                           <p>{official.presentAddress.houseNo || '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">Street</span>
                                           <p>{official.presentAddress.street || '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">Subdivision</span>
                                           <p>{official.presentAddress.subdivision || '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">Barangay</span>
                                           <p>{official.presentAddress.barangay || '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">City</span>
                                           <p>{official.presentAddress.city || '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">Province</span>
                                           <p>{official.presentAddress.province || '-'}</p>
                                       </div>
                                       <div>
                                           <span className="font-medium">Region</span>
                                           <p>{official.presentAddress.region || '-'}</p>
                                       </div>
                                   </div>
                               )}
                               </details>
                               <h2 className="text-2xl font-semibold mt-6 mb-2">Other Information</h2>
                               <div className="grid grid-cols-4 gap-4">
                                   <div>
                                       <span className="font-medium">PhilSys No.</span>
                                       <p>{official.philsys_num || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Voters ID No.</span>
                                       <p>{official.voters_id || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">SSS ID No.</span>
                                       <p>{official.sss_num || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Pag-IBIG MID No.</span>
                                       <p>{official.pagibig_num || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">Philhealth No.</span>
                                       <p>{official.philhealth_num || '-'}</p>
                                   </div>
                                   <div>
                                       <span className="font-medium">TIN No.</span>
                                       <p>{official.tin_num || '-'}</p>
                                   </div>
                               </div>
                               <attachment>
                               <h2 className="text-2xl font-semibold mt-6 mb-4">Valid IDs</h2>
                               <div className="grid grid-cols-4 gap-4">
                                   {official.validIDs && official.validIDs.length > 0 ? (
                                       official.validIDs.map((idUrl, index) => (
                                           <div key={index} className="flex flex-col items-center">
                                               {/* Display Image Preview if ID is an image */}
                                               {idUrl.match(/\.(jpeg|jpg|png)$/) ? (
                                                   <img
                                                       src={idUrl}
                                                       alt={`Valid ID ${index + 1}`}
                                                       className="min-w-52  object-contain border border-gray-300 rounded-md shadow-md"
                                                   />
                                               ) : (
                                                   /* Provide a Download Link for non-image IDs (e.g., PDFs) */
                                                   <a
                                                       href={idUrl}
                                                       target="_blank"
                                                       rel="noopener noreferrer"
                                                       className="text-blue-600 underline font-medium"
                                                   >
                                                       Download Valid ID {index + 1}
                                                   </a>
                                               )}
                                           </div>
                                       ))
                                   ) : (
                                       <p className="text-gray-500">No valid IDs uploaded</p>
                                   )}
                               </div>
                               </attachment>
                           </div>
                       </div>
                   </main>
               </div>
           </div>
       );
   };

export default ViewOfficial;
