import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Navigation from "../../component/Navigation";
import Header from "../../component/Header";
import Swal from "sweetalert2";
import Occupation from "../../assets/dropdowns/Occupation";
import Religions from "../../assets/dropdowns/Religions";
import Nationalities from "../../assets/dropdowns/Nationalities";
import PresentAddress from "../../assets/dropdowns/PresentAddress";

const EditOfficial = () => {
    const [formData, setFormData] = useState(null);
    const [originalData, setOriginalData] = useState(null); // Store original data for comparison
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [userName, setUserName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [adminData, setAdminData] = useState(null);
    const [errors, setErrors] = useState({});
    const [attachedFiles, setAttachedFiles] = useState([]); // Valid ID storage
    const [sameAsPermanent, setSameAsPermanent] = useState(false); // Toggle for address
    const [showModal, setShowModal] = useState(false);
    const [changes, setChanges] = useState({});
    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [removedValidIDs, setRemovedValidIDs] = useState([]);
    const {  id: adminID } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = localStorage.getItem("token");

        if (!token || !user) {
            setAccessDenied(true);
            setLoading(false);
            return;
        }

        if (user) {
            if (user.profilepic) {
                user.profilepic = user.profilepic.replace(/\\/g, "/");
            }
            const capitalizeWords = (str) =>
                str.split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");

            setUserName(`${capitalizeWords(user.firstName)} ${user.middleName ? capitalizeWords(user.middleName.charAt(0)) + "." : ""} ${capitalizeWords(user.lastName)}`);
            setAdminData(user);
            setUserRole(user.roleinBarangay);

            // 🚨 Restrict access if the user is NOT a Barangay Captain
            if (user.roleinBarangay !== "Barangay Captain") {
                setAccessDenied(true);
            }
        }

        fetchOfficialDetails();
    }, []);

    const fetchOfficialDetails = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/admin/id/${adminID}`);
          if (response.data) {
            setFormData(response.data);
            setOriginalData(response.data);
            setPreview(response.data.profilepic);
            setAttachedFiles(response.data.validIDs ? response.data.validIDs.map(file => ({ url: file })) : []);
          } else {
            setFormData({});
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching official details:", error);
          setFormData({});
          setLoading(false);
        }
      };
      

    const handleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
      const updatedValue = type === 'checkbox' ? checked : value;
    
      if (name === 'sameAsPermanent') {
        setSameAsPermanent(checked);
    
        setFormData((prevData) => ({
            ...prevData,
            presentAddress: checked
                ? { ...prevData.permanentAddress } // Ensure deep copy
                : {
                      unitFloorRoomNo: '',
                      building: '',
                      blockNo: '',
                      lotNo: '',
                      phaseNo: '',
                      houseNo: '',
                      street: '',
                      subdivision: '',
                      barangay: '',
                      city: '',
                      province: '',
                      region: '',
                      postalcode: '',
                  },
        }));
        return;
    }
    
          // ✅ First Name Validation
          if (name === "firstName") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                firstName: value.trim() === "" ? "First Name is required" : "",
            }));
        }
    
        // ✅ Last Name Validation
        if (name === "lastName") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                lastName: value.trim() === "" ? "Last Name is required" : "",
            }));
        }
    
        // ✅ Email Validation
        if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: emailRegex.test(value) ? "" : "Invalid email format",
            }));
        }
    
        // ✅ Street Validation
        if (name === "permanentAddress.street") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                street: value.trim() === "" ? "Street is required" : "",
            }));
        }
    
        // ✅ House No. Validation
        if (name === "permanentAddress.houseNo") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                houseNo: value.trim() === "" ? "House No. is required" : "",
            }));
        }
      
         // ✅ Handle Birthday Change: Auto-Update Age & Senior Citizen Checkbox
         if (name === 'birthday') {
          const birthDate = new Date(value);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();
    
          if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
              age--;
          }
    
          if (birthDate > today) {
              Swal.fire({
                  icon: 'error',
                  title: 'Invalid Date',
                  text: 'Birthdate cannot be in the future.',
              });
              return;
          }
    
          setFormData((prevData) => ({
              ...prevData,
              birthday: value,
              age: age,
              seniorCitizen: age >= 60 ? true : false, // ✅ Automatically enable Senior Citizen checkbox
              seniorcitizenid_num: age >= 60 ? prevData.seniorcitizenid_num : '', // ✅ Restore or clear Senior Citizen ID
          }));
          return;
      }
    
      // ✅ Handle PWD Checkbox Toggle (Restore Previous Values)
      if (name === 'pwd') {
          setFormData((prevData) => ({
              ...prevData,
              pwd: checked,
              pwdid_num: checked ? prevData.pwdid_num || originalData.pwdid_num || '' : '', // Restore old or clear
              typeofdisability: checked ? prevData.typeofdisability || originalData.typeofdisability || '' : '', // Restore old or clear
          }));
          return;
      }
    
      // ✅ Handle Solo Parent Checkbox Toggle (Restore Previous Values)
      if (name === 'soloparent') {
          setFormData((prevData) => ({
              ...prevData,
              soloparent: checked,
              soloparentid_num: checked ? prevData.soloparentid_num || originalData.soloparentid_num || '' : '', // Restore old or clear
          }));
          return;
      }
    
    
        // Handling nested object updates (permanentAddress and presentAddress)
        if (name.startsWith('permanentAddress.') || name.startsWith('presentAddress.')) {
          const [addressType, addressField] = name.split('.');
          setFormData((prevData) => ({
              ...prevData,
              [addressType]: {
                  ...prevData[addressType],
                  [addressField]: updatedValue,
              },
          }));
    
          // If "Same as Permanent Address" is checked, update presentAddress too
          if (sameAsPermanent && addressType === 'permanentAddress') {
              setFormData((prevData) => ({
                  ...prevData,
                  presentAddress: { ...prevData.permanentAddress, [addressField]: updatedValue },
              }));
          }
          return;
      }
    
      setFormData((prevData) => ({
        ...prevData,
        [name]: updatedValue
      }));
    };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleValidIDChange = (e) => {
        const files = Array.from(e.target.files);
        setAttachedFiles((prevFiles) => [
            ...prevFiles,
            ...files.map(file => ({ file })),
        ]);
    };

    const handleRemoveFile = (index) => {
        const fileToRemove = attachedFiles[index];
        if (fileToRemove.url) {
            setRemovedValidIDs((prev) => [...prev, fileToRemove.url]);
        }
        setAttachedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!formData) return;
    
        let updatedChanges = {};
    
        // Check for changes in all fields
        Object.keys(formData).forEach((key) => {
            if (typeof formData[key] === "object" && formData[key] !== null) {
                // Deep compare nested objects (addresses)
                let objectChanges = {};
                Object.keys(formData[key]).forEach((nestedKey) => {
                    if (formData[key][nestedKey] !== originalData[key][nestedKey]) {
                        objectChanges[nestedKey] = {
                            old: originalData[key][nestedKey] || "Not Set",
                            new: formData[key][nestedKey] || "Not Set",
                        };
                    }
                });
    
                if (Object.keys(objectChanges).length > 0) {
                    updatedChanges[key] = objectChanges;
                }
            } else if (formData[key] !== originalData[key]) {
                updatedChanges[key] = {
                    old: originalData[key] || "Not Set",
                    new: formData[key] || "Not Set",
                };
            }
        });
    
        if (selectedFile) {
            updatedChanges.profilepic = {
                old: originalData.profilepic || "No Image",
                new: URL.createObjectURL(selectedFile),
            };
        }
          
    
        // ✅ Detect if valid IDs were changed
        const originalValidIDs = originalData.validIDs || [];
        const newValidIDs = attachedFiles.map((file) => file.url || file.file.name);
    
        if (JSON.stringify(originalValidIDs) !== JSON.stringify(newValidIDs)) {
            updatedChanges.validIDs = {
                old: originalValidIDs.length > 0 ? originalValidIDs : "No Valid IDs",
                new: newValidIDs.length > 0 ? newValidIDs : "No Valid IDs",
            };
        }
    
        // ✅ Prevent submission if no changes are detected
        if (Object.keys(updatedChanges).length === 0) {
            Swal.fire("No Changes", "No updates were made.", "info");
            return;
        }
    
        setChanges(updatedChanges);
        setShowModal(true);
    };
    

    const confirmSubmit = async () => {
        setShowModal(false);
        const token = localStorage.getItem("token");
        const updatedFormData = new FormData();
    
        // Always append permanent and present addresses
        updatedFormData.append("permanentAddress", JSON.stringify(formData.permanentAddress));
        updatedFormData.append("presentAddress", JSON.stringify(formData.presentAddress));
    
        // Append only fields that have changed
        Object.keys(changes).forEach((key) => {
            if (key === "profilepic" && selectedFile) {
                updatedFormData.append("profilepic", selectedFile);
            } else if (
                key !== "permanentAddress" &&
                key !== "presentAddress" &&
                typeof formData[key] === "object"
            ) {
                updatedFormData.append(key, JSON.stringify(formData[key]));
            } else if (key !== "permanentAddress" && key !== "presentAddress") {
                updatedFormData.append(key, formData[key]);
            }
        });
    
        // ✅ Add newly selected files (valid IDs)
        attachedFiles.forEach((item) => {
            if (item.file) {
                updatedFormData.append("validIDs", item.file);
            }
        });
    
        // ✅ Send removed valid IDs if any
        if (removedValidIDs.length > 0) {
            updatedFormData.append("removedValidIDs", JSON.stringify(removedValidIDs));
        }
    
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_API_KEY}/api/admin/${adminID}`,
                updatedFormData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
    
            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Official details updated successfully.",
                });
                navigate("/Barangay-Directory");
            }
        } catch (error) {
            console.error("Error updating official:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to update official details. Please try again.",
            });
        }
    };
    
    
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/");
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toLocaleDateString("en-US", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric"
        });
    };

    const handleBackClick = () => {
        navigate("/Barangay-Directory");
    };

    // Show a loading state while checking access
    if (loading) {
        return <div className="flex justify-center items-center min-h-screen text-xl font-semibold">Loading...</div>;
    }

    // If access is denied, show an error message and prevent rendering the page
    if (accessDenied) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="text-center bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                    <strong className="font-bold">Access Denied!</strong>
                    <p className="text-sm mt-2">You do not have permission to access this page.</p>
                    <button 
                        className="mt-4 bg-[#1346AC] text-white px-4 py-2 rounded hover:bg-blue-700"
                        onClick={() => navigate("/Barangay-Directory")}
                    >
                        Go Back
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-screen">
            <Header userName={userName} userRole={userRole} handleLogout={handleLogout} profilePic={adminData?.profilepic} />
            <div className="flex flex-1">
                <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
                <main className="flex-1 p-8 bg-gray-100">
                <div className="flex items-center mb-7">
            <button
                className="text-xl text-gray-500 hover:text-[#1346AC] cursor-pointer font-semibold mr-10"
                onClick={handleBackClick}
            >
                &lt; Back
            </button>
            <h1 className="text-4xl font-bold">Edit Official</h1>
        </div>
          <form onSubmit={handleSubmit}>
          
          <div className='flex w-full bg-white rounded-lg shadow-md'>
          <div className="bg-white rounded-lg w-1/5 flex flex-col justify-between items-center p-6">
          <div className='w-full items-center flex flex-col'>
              {preview ? (
                  <img src={preview} alt="Profile" className="w-52 h-52 rounded-md object-cover border border-gray-300 shadow-md" />
              ) : (
                  <div className="w-52 h-52 rounded-md bg-gray-200 flex items-center justify-center border border-gray-300 shadow-md">
                      <span className="text-gray-500">No Image</span>
                  </div>
              )}

              {/* Hidden File Input */}
              <input 
                  type="file" 
                  accept="image/*" 
                  onChange={handleFileChange} 
                  id="profile-pic-upload" 
                  className="hidden" 
              />

              {/* Custom Button to Trigger File Input */}
              <button 
                  type="button" 
                  onClick={() => document.getElementById('profile-pic-upload').click()} 
                  className="mt-2 px-4 py-1 bg-[#1346AC] text-white font-semibold rounded-full hover:bg-blue-600"
              >
                  Change Profile Picture
              </button>
          </div>

          <div className='w-full'>
              <button type="button" onClick={handleSubmit} className="mt-6 w-full px-4 py-2 bg-[#1346AC] text-white font-semibold rounded-full hover:bg-blue-600">
                  Save Changes
              </button>
          </div>
      </div>
            <div className="w-4/5 overflow-y-auto max-h-[800px] p-6 px-6">
              <h2 className="text-2xl font-semibold mt-6 mb-6">Personal Information</h2>
              <div className="grid grid-cols-4 gap-4">
              <div >
                <label className="block text-md font-medium text-gray-700">First Name</label>
                <input type="text" name="firstName" value={formData.firstName} onChange={handleInputChange}
                 className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md" 
                 required />
                 {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Middle Name</label>
                <input type="text" name="middleName" value={formData.middleName} onChange={handleInputChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md" />
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Last Name</label>
                <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange}
                 className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md" 
                 required />
                 {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Suffix</label>
                <select name="suffix" value={formData.suffix} onChange={handleInputChange} 
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md">
                  <option value="">None</option>
                  <option value="JR.">Jr.</option>
                  <option value="SR.">Sr.</option>
                  <option value="III">III</option>
                  <option value="IV">IV</option>
                  <option value="V">V</option>
                </select>
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Sex</label>
                <select name="sex" value={formData.sex} onChange={handleInputChange} 
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Birthdate</label>
                <input type="date" name="birthday" value={formData.birthday.split('T')[0]} onChange={handleInputChange}
                 className="mt-1 block w-full pl-3 pr-2 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md" required />
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Birthplace</label>
                <input type="text" name="birthplace" value={formData.birthplace} onChange={handleInputChange}
                  className="border mt-1 block w-full p-2 rounded-md" required />
              </div>

              <div>
                  <label className="block text-md font-medium text-gray-700">Age</label>
                  <input
                      type="number"
                      name="age"
                      value={formData.age}
                      onChange={handleInputChange}
                      readOnly
                      className="mt-1 block w-full pl-3 pr-2 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                  />
              </div>

              <div>
                <Occupation selectedOccupation={formData.occupation} handleChange={handleInputChange} />
              </div>

              <div>
                <Nationalities selectedNationality={formData.nationality} handleChange={handleInputChange} />
              </div>

              <div>
                <Religions selectedReligion={formData.religion} handleChange={handleInputChange} />
              </div>

              <div>
                  <label className="block text-md font-medium text-gray-700">Civil Status</label>
                  <select
                      name="civilStatus"
                      value={formData.civilStatus}
                      onChange={handleInputChange}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                  >
                      <option value="">Select Civil Status</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Separated">Separated</option>
                      <option value="Annulled">Annulled</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                  </select>
              </div>
              
              <div>
                <label className="block text-md font-medium text-gray-700">Email Address</label>
                <input type="email" name="email" value={formData.email} onChange={handleInputChange}
                  className="border mt-1 block w-full p-2 rounded-md" required />
                  {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
              </div>

              <div>
                <label className="block text-md font-medium text-gray-700">Contact Number</label>
                <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleInputChange}
                  className="border mt-1 block w-full p-2 rounded-md" required />
              </div>
              <div className="flex items-center">
                <input
                        type="checkbox"
                        name="voter"
                        checked={formData.voter}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                        disabled={formData.age < 16} 
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Voter</label>
                    <input
                        type="checkbox"
                        name="indigent"
                        checked={formData.indigent}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Indigent</label>
                    <input
                        type="checkbox"
                        name="fourpsmember"
                        checked={formData.fourpsmember}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">4Ps</label>

                    <input
                        type="checkbox"
                        name="outofschoolchildren"
                        checked={formData.outofschoolchildren}
                        disabled
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Out-of-School <br/> Children</label>
                    <input
                        type="checkbox"
                        name="outofschoolyouth"
                        checked={formData.outofschoolyouth}
                        disabled={!(formData.age >= 15 && formData.age <= 24)}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Out-of-School <br/> Youth</label>
                    <input
                        type="checkbox"
                        name="indigenouspeople"
                        checked={formData.indigenouspeople}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-md text-gray-900 whitespace-nowrap mr-2">Indigenous <br/> People</label>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 mt-4">
              {/* PWD Section */}
              <div className="space-y-2">
                  <div className="flex items-center">
                      <input
                          type="checkbox"
                          name="pwd"
                          checked={formData.pwd}
                          onChange={handleInputChange}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label className="ml-2 block text-md text-gray-900">PWD</label>
                  </div>
                  <div>
                      <label className="block text-md font-medium text-gray-700">PWD ID No.</label>
                      <input
                          type="text"
                          name="pwdid_num"
                          value={formData.pwdid_num}
                          onChange={handleInputChange}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                          placeholder="Enter PWD ID No."
                          disabled={!formData.pwd}
                      />
                  </div>

                  {formData.pwd && (
                      <div>
                          <label className="block text-md font-medium text-gray-700">Type of Disability</label>
                          <input
                              type="text"
                              name="typeofdisability"
                              value={formData.typeofdisability}
                              onChange={handleInputChange}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                              placeholder="Enter Type of Disability"
                          />
                      </div>
                  )}
              </div>

              {/* Solo Parent Section */}
              <div className="space-y-2">
                  <div className="flex items-center">
                      <input
                          type="checkbox"
                          name="soloparent"
                          checked={formData.soloparent}
                          onChange={handleInputChange}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label className="ml-2 block text-md text-gray-900">Solo Parent</label>
                  </div>
                  <div>
                      <label className="block text-md font-medium text-gray-700">Solo Parent ID No.</label>
                      <input
                          type="text"
                          name="soloparentid_num"
                          value={formData.soloparentid_num}
                          onChange={handleInputChange}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                          placeholder="Enter Solo Parent ID No."
                          disabled={!formData.soloparent}
                      />
                  </div>
              </div>

              {/* Senior Citizen Section */}
              <div className="space-y-2">
                  <div className="flex items-center">
                      <input
                          type="checkbox"
                          name="seniorCitizen"
                          checked={formData.seniorCitizen}
                          onChange={handleInputChange}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                          disabled
                      />
                      <label className="ml-2 block text-md text-gray-900">Senior Citizen</label>
                  </div>
                  <div>
                      <label className="block text-md font-medium text-gray-700">Senior Citizen ID No.</label>
                      <input
                          type="text"
                          name="seniorcitizenid_num"
                          value={formData.seniorcitizenid_num}
                          onChange={handleInputChange}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                          placeholder="Enter Senior Citizen ID No."
                          disabled={!formData.seniorCitizen}
                      />
                  </div>
              </div>
            </div>
            <h2 className="text-2xl font-semibold mt-6 mb-6">Permanent Address</h2>
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <label className="block text-md font-medium text-gray-700">Unit/Floor/Room No.</label>
                    <input
                        type="text"
                        name="permanentAddress.unitFloorRoomNo"
                        value={formData.permanentAddress.unitFloorRoomNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Unit/Floor/Room No."
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Building</label>
                    <input
                        type="text"
                        name="permanentAddress.building"
                        value={formData.permanentAddress.building}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Building"
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Block No.</label>
                    <input
                        type="text"
                        name="permanentAddress.blockNo"
                        value={formData.permanentAddress.blockNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Block No."
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Lot No.</label>
                    <input
                        type="text"
                        name="permanentAddress.lotNo"
                        value={formData.permanentAddress.lotNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Lot No."
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Phase No.</label>
                    <input
                        type="text"
                        name="permanentAddress.phaseNo"
                        value={formData.permanentAddress.phaseNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Phase No."
                    />
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">House No.</label>
                    <input
                        type="text"
                        name="permanentAddress.houseNo"
                        value={formData.permanentAddress.houseNo}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter House No."
                        required
                    />
                     {errors.houseNo && <p className="text-red-500 text-xs mt-1">{errors.houseNo}</p>}
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Street</label>
                    <input
                        type="text"
                        name="permanentAddress.street"
                        value={formData.permanentAddress.street}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Street"
                        required
                    />
                    {errors.street && <p className="text-red-500 text-xs mt-1">{errors.street}</p>}
                </div>
                <div>
                    <label className="block text-md font-medium text-gray-700">Subdivision</label>
                    <input
                        type="text"
                        name="permanentAddress.subdivision"
                        value={formData.permanentAddress.subdivision}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Subdivision"
                    />
                </div>
            </div>

            <PresentAddress formData={formData} handleInputChange={handleInputChange} errors={errors} />
                  <div className="flex items-center justify-start mt-8 flex-wrap">
                      <label className="text-md font-medium text-gray-700 mr-2">
                          Same as Permanent Address
                      </label>
                      <input
                          type="checkbox"
                          name="sameAsPermanent"
                          checked={sameAsPermanent}
                          onChange={handleInputChange}
                          className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                  </div>

            <h2 className="text-2xl font-semibold mt-6 mb-6">Other Information</h2>
                <div className="grid grid-cols-3 gap-4">
                    <div>
                        <label className="block text-md font-medium text-gray-700">PhilSys No.</label>
                        <input
                            type="text"
                            name="philsys_num"
                            value={formData.philsys_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter PhilSys No."
                        />
                    </div>
                    <div>
                    <label className="block text-md font-medium text-gray-700">Voters ID No. (Optional)</label>
                    <input
                        type="text"
                        name="voters_id"
                        value={formData.voters_id}
                        onChange={handleInputChange}
                        className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                        placeholder="Enter Voters ID No. (Optional)"
                        disabled={!formData.voter} 
                    />
                    </div>
                    <div>
                        <label className="block text-md font-medium text-gray-700">SSS ID No.</label>
                        <input
                            type="text"
                            name="sss_num"
                            value={formData.sss_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter SSS ID No."
                        />
                    </div>
                    <div>
                        <label className="block text-md font-medium text-gray-700">Pag-IBIG MID No.</label>
                        <input
                            type="text"
                            name="pagibig_num"
                            value={formData.pagibig_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter Pag-IBIG MID No."
                        />
                    </div>
                    <div>
                        <label className="block text-md font-medium text-gray-700">Philhealth No.</label>
                        <input
                            type="text"
                            name="philhealth_num"
                            value={formData.philhealth_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter Philhealth No."
                        />
                    </div>
                    <div>
                        <label className="block text-md font-medium text-gray-700">TIN</label>
                        <input
                            type="text"
                            name="tin_num"
                            value={formData.tin_num}
                            onChange={handleInputChange}
                            className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                            placeholder="Enter TIN"
                        />
                    </div>
                </div>

                <div className='mt-4'>
                <h2 className="text-2xl font-semibold mb-4">Valid IDs</h2>
              <input type="file" multiple onChange={handleValidIDChange} className="hidden" id="valid-id-upload" />
              <label htmlFor="valid-id-upload" className="cursor-pointer bg-[#1346AC] hover:bg-blue-700 text-white py-2 px-4 rounded-full">
                Upload Valid IDs
              </label>
              <div className="mt-4 flex flex-wrap gap-4">
                {attachedFiles.map((item, index) => (
                  <div key={index} className="flex flex-col items-center">
                    {item.file ? (
                      <img src={URL.createObjectURL(item.file)} alt="Valid ID" className="max-w-xs max-h-xs object-cover" />
                    ) : (
                      <img src={item.url} alt="Valid ID" className="max-w-xs max-h-xs object-cover" />
                    )}
                    <button type="button" className="bg-red-500 text-white px-2 py-1 rounded-full mt-2" onClick={() => handleRemoveFile(index)}>
                      Remove
                    </button>
                  </div>
                ))}
              </div>
                </div>
            </div>
            </div>
          </form>
        </main>
        {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[500px]">
            <h2 className="text-2xl font-semibold mb-4">Confirm Changes</h2>
            <p className="text-red-600 mb-4 text-center font-semibold">
            Please note: Updating this official’s information will also update their resident account.
            </p>
            
            <div className="max-h-60 overflow-y-auto border p-3">
              
              {/* ✅ Display Profile Picture Changes Separately */}
              {changes.profilepic && (
                  <div className="mb-4">
                      <p className="font-medium">Profile Picture:</p>
                      <div className="flex items-center space-x-4">
                          <div className="flex flex-col items-center">
                              <p className="text-sm text-gray-600"><b>Old:</b></p>
                              <img src={changes.profilepic.old} alt="Old Profile" className="w-24 h-24 object-cover border border-gray-300 shadow-md" />
                          </div>
                          <div className="flex flex-col items-center">
                              <p className="text-sm text-green-600"><b>New:</b></p>
                              <img  src={changes.profilepic.new || preview} alt="New Profile" className="w-24 h-24 object-cover border border-gray-300 shadow-md" />
                          </div>
                      </div>
                  </div>
              )}

                {changes.validIDs && (
                <div className="mb-4">
                    <p className="font-medium">Valid IDs Changed:</p>
                    <div className="grid grid-cols-2 gap-4">
                    {attachedFiles.map((file, index) => (
                        <div key={index} className="flex flex-col items-center">
                        <p className="text-sm text-green-600">New:</p>
                        <img
                            src={file.file ? URL.createObjectURL(file.file) : file.url}
                            alt="Valid ID"
                            className="w-24 h-24 object-cover border border-gray-300 shadow-md"
                        />
                        </div>
                    ))}
                    </div>
                </div>
                )}

              {/* ✅ Display Address Changes Separately */}
              {changes.permanentAddress && (
                  <div className="mb-4">
                      <p className="font-medium">Permanent Address Changes:</p>
                      {Object.entries(changes.permanentAddress).map(([field, values]) => (
                          <div key={field}>
                              <p className="text-sm text-gray-600"><b>{field.replace(/([A-Z])/g, " $1")}:</b></p>
                              <p className="text-sm text-gray-600"><b>Old:</b> {values.old}</p>
                              <p className="text-sm text-green-600"><b>New:</b> {values.new}</p>
                          </div>
                      ))}
                  </div>
              )}

              {changes.presentAddress && (
                  <div className="mb-4">
                      <p className="font-medium">Present Address Changes:</p>
                      {Object.entries(changes.presentAddress).map(([field, values]) => (
                          <div key={field}>
                              <p className="text-sm text-gray-600"><b>{field.replace(/([A-Z])/g, " $1")}:</b></p>
                              <p className="text-sm text-gray-600"><b>Old:</b> {values.old}</p>
                              <p className="text-sm text-green-600"><b>New:</b> {values.new}</p>
                          </div>
                      ))}
                  </div>
              )}

              {/* ✅ Display Other Changes */}
              {Object.entries(changes).map(([key, { old, new: newValue }]) => (
                key !== "profilepic" && key !== "validIDs" && key !== "permanentAddress" && key !== "presentAddress" && (
                  <div key={key} className="mb-4">
                    <p className="font-medium">{key.replace(/([A-Z])/g, " $1")}:</p>
                    <p className="text-sm text-gray-600">
                      <b>Old:</b> {typeof old === 'object' ? JSON.stringify(old, null, 2) : old}
                    </p>
                    <p className="text-sm text-green-600">
                      <b>New:</b> {typeof newValue === 'object' ? JSON.stringify(newValue, null, 2) : newValue}
                    </p>
                  </div>
                )
              ))}
            </div>

            <div className="mt-4 flex justify-end space-x-3">
              <button onClick={() => setShowModal(false)} className="px-4 py-2 bg-gray-400 hover:bg-gray-300 text-gray-700 rounded-md">
                Cancel
              </button>
              <button onClick={confirmSubmit} className="px-4 py-2 bg-[#1346AC] text-white rounded-md hover:bg-blue-600">
                Confirm & Save
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default EditOfficial;
