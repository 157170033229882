import React, { useEffect, useState } from 'react'
import Navigation from '../../component/Navigation';
import Header from '../../component/Header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MdRateReview , MdArchive, MdRestore } from "react-icons/md";
import Swal from 'sweetalert2';

const BarangayDirectory = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [adminData, setAdminData] = useState(null);
  const [barangayOfficials, setBarangayOfficials] = useState([]); // Store barangay officials data
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [pageRange, setPageRange] = useState([1, 2, 3, 4]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('Official ID'); // Default sort field
  const [sortDirection, setSortDirection] = useState('asc'); // Default sort direction
  const [selectedPosition, setSelectedPosition] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("Active"); 

  // Fetch the barangay officials from the API
  useEffect(() => {
    const fetchBarangayOfficials = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/admins`);
        setBarangayOfficials(response.data.admins);
      } catch (error) {
        console.error('Error fetching barangay officials:', error);
      }
    };

    fetchBarangayOfficials();

    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      const capitalizeWords = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
      const firstName = capitalizeWords(user.firstName);
      const lastName = capitalizeWords(user.lastName);
      const middleInitial = user.middleName ? capitalizeWords(user.middleName.charAt(0)) + '.' : '';
      setUserName(`${firstName} ${middleInitial} ${lastName}`);
      setAdminData(user);
      setUserRole(user.roleinBarangay);
    }
  }, []);

    // Archive function
    const handleArchive = async (official) => {
      Swal.fire({
        title: "Are you sure?",
        text: `Archiving ${official.firstName} ${official.lastName} will restrict access!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Archive it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const token = localStorage.getItem("token");
            await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/admin/archive/${official._id}`, {}, {
              headers: { Authorization: `Bearer ${token}` },
            });
  
            Swal.fire("Archived!", `${official.firstName} has been archived.`, "success");
  
            // Update the status in the list
            setBarangayOfficials(prevOfficials =>
              prevOfficials.map(item =>
                item._id === official._id
                  ? { ...item, accountStatus: 'Archive' }
                  : item
              )
            );
          } catch (error) {
            console.error("Error archiving official:", error);
            Swal.fire("Error!", "Failed to archive official. Please try again.", "error");
          }
        }
      });
    };
  
    // Restore function
    const handleRestore = async (official) => {
      Swal.fire({
        title: "Are you sure?",
        text: `Restoring ${official.firstName} ${official.lastName} will re-enable access!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Restore it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const token = localStorage.getItem("token");
            await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/admin/restore/${official._id}`, {}, {
              headers: { Authorization: `Bearer ${token}` },
            });
  
            Swal.fire("Restored!", `${official.firstName} has been restored.`, "success");
  
            // Update the status in the list
            setBarangayOfficials(prevOfficials =>
              prevOfficials.map(item =>
                item._id === official._id
                  ? { ...item, accountStatus: 'Active' }
                  : item
              )
            );
          } catch (error) {
            console.error("Error restoring official:", error);
            Swal.fire("Error!", "Failed to restore official. Please try again.", "error");
          }
        }
      });
    };

  // ✅ Reset Filters
  const resetFilters = () => {
    setSelectedStatus("Active");
    setSelectedPosition("All");
    setSearchTerm("");
  };

  const handlePositionChange = (event) => {
    setSelectedPosition(event.target.value);
  };

  // ✅ Filters Officials List
  const filteredOfficials = barangayOfficials
    .filter(official => 
      (selectedStatus === "All" || official.accountStatus === selectedStatus) &&
      (selectedPosition === "All" || official.roleinBarangay === selectedPosition) &&
      (`${official.firstName} ${official.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()))
    );



    // Handle sorting changes
  const handleSortOrderChange = (event) => setSortOrder(event.target.value);
  const handleSortDirectionChange = (event) =>
    setSortDirection(event.target.value);

  const sortedOfficials = [...filteredOfficials].sort((a, b) => {
    let fieldA, fieldB;
  
    switch (sortOrder) {
      case "Official ID":
        const numA = parseInt(a.adminID.split("-")[1], 10) || 0;
        const numB = parseInt(b.adminID.split("-")[1], 10) || 0;
        return sortDirection === "asc" ? numA - numB : numB - numA;
  
      case "Name":
        fieldA = `${a.firstName || ""} ${a.lastName || ""}`.toLowerCase();
        fieldB = `${b.firstName || ""} ${b.lastName || ""}`.toLowerCase();
        return sortDirection === "asc"
          ? fieldA.localeCompare(fieldB)
          : fieldB.localeCompare(fieldA);
  
      case "Address":
        // Handle cases where address fields might be missing
        fieldA = `${a.permanentAddress?.street || ""} ${a.permanentAddress?.barangay || ""} ${a.permanentAddress?.city || ""}`.toLowerCase();
        fieldB = `${b.permanentAddress?.street || ""} ${b.permanentAddress?.barangay || ""} ${b.permanentAddress?.city || ""}`.toLowerCase();
        return sortDirection === "asc"
          ? fieldA.localeCompare(fieldB)
          : fieldB.localeCompare(fieldA);
  
      default:
        return 0;
    }
  });
  
  const handleLogout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/');
  };

  const getCurrentDate = () => {
      const date = new Date();
      return date.toLocaleDateString('en-US', {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
      });
  };
 
  // Pagination Logic
  const totalPages = Math.ceil(sortedOfficials.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEntries = sortedOfficials.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (pageRange[3] < totalPages) {
        setPageRange(pageRange.map(page => page + 1));
      }
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (pageRange[0] > 1) {
        setPageRange(pageRange.map(page => page - 1));
      }
    }
  };

  const handlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
    const newRangeStart = Math.max(1, pageNum - 1);
    const newRangeEnd = Math.min(totalPages, newRangeStart + 3);
    setPageRange(Array.from({ length: newRangeEnd - newRangeStart + 1 }, (_, i) => newRangeStart + i));
  };



  return (
      <div className="flex flex-col min-h-screen">
          <Header userName={userName} userRole={userRole} handleLogout={handleLogout} getCurrentDate={getCurrentDate} profilePic={adminData?.profilepic}/>
          <div className="flex flex-1">
              <Navigation adminData={adminData} getCurrentDate={getCurrentDate} />
              <main className="flex-1 p-8 bg-gray-100">
                  <h1 className="text-4xl font-bold mb-5 text-gray-700">Barangay Officials Directory</h1>
                  <div className="flex justify-around mb-1">
                      <div className="bg-white p-6 rounded-lg shadow w-full">
                          <div className='flex-col justify-between items-center mb-4'>
                          <div className='flex justify-between items-center text-center mb-2'>
                          <h2 className="text-2xl font-semibold ">List of Barangay Officials</h2>
                          {userRole !== "Kagawad" && (
                            <button
                              className="bg-[#1346AC] text-white px-8 py-2 rounded-full font-semibold"
                              onClick={() => navigate('/Add-Barangay-Official')}
                            >
                              Add Barangay Official
                            </button>
                          )}
                          </div>
                          <div className='flex justify-between'>
                          <div className="flex space-x-4 items-center">
                              <div className="w-48">
                                <label htmlFor="date" className="block text-sm font-medium text-gray-700">Position</label>
                                <select
                                id="position"
                                name="position"
                                value={selectedPosition}
                                onChange={e => setSelectedPosition(e.target.value)}
                                className="mt-1 block w-full pl-3 pr-8 py-2 border border-gray-300 rounded-md"
                              >
                                <option value="All">All</option>
                                <option value="Barangay Captain">Barangay Captain</option>
                                <option value="Secretary">Secretary</option>
                                <option value="Kagawad">Kagawad</option>
                              </select>
                                </div>
                                <div className="w-48">
                                    <label htmlFor="accountStatus" className="block text-sm font-medium text-gray-700">Status</label>
                                    <div className="relative">
                                        <select
                                            id="accountStatus"
                                            name="accountStatus"
                                            value={selectedStatus}
                                            onChange={e => setSelectedStatus(e.target.value)}
                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md appearance-none focus:ring-0 focus:border-gray-300"
                                        >
                                            <option>All</option>
                                            <option>Active</option>
                                            <option>Archive</option>
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                  <button className="text-[#1346AC] hover:text-blue-500 cursor-pointer font-semibold mt-4" onClick={resetFilters}>
                                      Reset Filters
                                  </button>
                              </div>
                              <div className="flex flex-col items-end space-y-2">
                              <input
                                  type="text"
                                  placeholder="Search requestor name"
                                  className="w-80 px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500"
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              {/* Sort By Dropdown */}
                              <div className="flex items-center space-x-2">
                                <label htmlFor="sortBy" className="text-sm font-medium text-gray-700">
                                  Sort by
                                </label>
                                <div className="relative flex items-center">
                                  <select
                                    id="sortBy"
                                    name="sortBy"
                                    className="block appearance-none w-full bg-white text-[#1346AC] font-semibold py-2 px-1 pr-8 rounded leading-tight focus:outline-none"
                                    value={sortOrder}
                                    onChange={handleSortOrderChange}
                                  >
                                    <option value="Official ID">Official ID</option>
                                    <option value="Name">Name</option>
                                    <option value="Address">Address</option>
                                  </select>
                                  <div className="absolute inset-y-0 right-2 flex items-center">
                                    <svg
                                      className="fill-current h-4 w-4 text-gray-700"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M7 10l5 5 5-5H7z" />
                                    </svg>
                                  </div>
                                </div>

                              {/* Sort Direction Dropdown */}
                              <div className="relative flex items-center">
                                <select
                                  id="sortDirection"
                                  name="sortDirection"
                                  className="block w-auto bg-white text-[#1346AC] font-semibold py-2 px-4 pr-6 rounded leading-tight focus:outline-none appearance-none"
                                  value={sortDirection}
                                  onChange={handleSortDirectionChange}
                                >
                                  {sortOrder === "Official ID" && (
                                    <>
                                      <option value="asc">Ascending</option>
                                      <option value="desc">Descending</option>
                                    </>
                                  )}
                                  {sortOrder === "Name" && (
                                    <>
                                      <option value="asc">A-Z</option>
                                      <option value="desc">Z-A</option>
                                    </>
                                  )}
                                  {sortOrder === "Address" && (
                                    <>
                                      <option value="asc">A-Z</option>
                                      <option value="desc">Z-A</option>
                                      <option value="numeric">Sort by Lot Number</option>
                                    </>
                                  )}
                                </select>
                                <div className="absolute inset-y-0 right-2 flex items-center">
                                  <svg
                                    className="fill-current h-4 w-4 text-gray-700"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M7 10l5 5 5-5H7z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                              </div>
                          </div>
                          </div>
                          <div className="overflow-x-auto scrollbar-thin text-center">
                          <table className="min-w-full bg-white border border-gray-400 text-center">
                            <thead>
                              <tr className='bg-gray-300'>
                                <th className="py-2 border-b border-r border-gray-400">Official ID</th>
                                <th className="py-2 border-b border-r border-gray-400">Name</th>
                                <th className="py-2 border-b border-r border-gray-400">Position</th>
                                <th className="py-2 border-b border-r border-gray-400">Contact Number</th>
                                <th className="py-2 border-b border-r border-gray-400">Address</th>
                                <th className="py-2 border-b border-r border-gray-400">Acount Status</th>
                                <th className="py-2 border-b border-r border-gray-400">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {sortedOfficials.length > 0 ? (
                                sortedOfficials.slice(indexOfFirstItem, indexOfLastItem).map((official, index) => (
                                  <tr key={official.adminID} className={`border-t border-gray-400 text-center ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100' }`}>
                                    <td className="py-2 px-4 border-b border-r border-gray-400">{official.adminID}</td>
                                    <td className="py-2 px-4 border-b border-r border-gray-400">
                                      {official.firstName} {official.middleName && `${official.middleName.charAt(0)}.`} {official.lastName}
                                    </td>
                                    <td className="py-2 px-4 border-b border-r border-gray-400">{official.roleinBarangay}</td>
                                    <td className="py-2 px-4 border-b border-r border-gray-400">{official.contactNumber}</td>
                                    <td className="py-2 px-4 border-b border-r border-gray-400">
                                      {official.permanentAddress.street}, {official.permanentAddress.barangay}, {official.permanentAddress.city}
                                    </td>
                                    <td className="py-2 px-4 border-l border-b border-r border-gray-400 status-column">
                                      <span className={`px-2 py-1 rounded-full font-semibold 
                                      ${official.accountStatus === 'Active' ? 'bg-[#1346AC] text-white' : 
                                        official.accountStatus === 'Archive' ? 'bg-red-700 text-white' : 
                                          'bg-gray-400 text-white'}`}>
                                          {official.accountStatus}
                                      </span>
                                  </td>
                                    <td 
                                    className={`px-2 w-72 border-b border-gray-400 text-center font-semibold actions-column ${
                                      userRole === "Barangay Captain" ? "border-r" : ""
                                    }`}
                                  >
                                    <div className={`flex ${userRole === "Barangay Captain" ? "justify-between" : "justify-center"} items-center w-full`}>
                                      {/* VIEW Button - Always Visible */}
                                      <div className={`w-1/2 ${userRole === "Barangay Captain" ? "border-r border-gray-400 pr-4 text-center" : ""}`}>
                                        <button  
                                          className="text-[#1346AC] hover:text-blue-500 inline-flex items-center"
                                          onClick={() => { navigate(`/view-official/${official._id}`); }}
                                        >
                                          <MdRateReview className="mr-1 text-lg" />
                                          VIEW
                                        </button>
                                      </div>

                                      {userRole === "Barangay Captain" && (
                                      <div className="flex justify-center space-x-4 items-center py-2 px-4">
                                        {official.accountStatus === 'Active' ? (
                                          <button className="text-[#1346AC] hover:text-red-500 inline-flex items-center" onClick={() => handleArchive(official)}>
                                            <MdArchive className="mr-1 text-lg" />
                                            ARCHIVE
                                          </button>
                                        ) : (
                                          <button className="text-[#1346AC] hover:text-green-800 inline-flex items-center" onClick={() => handleRestore(official)}>
                                            <MdRestore className="mr-1 text-lg" />
                                            RESTORE
                                          </button>
                                        )}
                                      </div>
                                    )}
                                    </div>
                                  </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="6" className="py-4 text-center text-gray-500">
                                    No officials found.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                  </div>

            
                <div className="flex justify-between items-center mt-4 font-semibold">
                <div className="text-sm text-gray-600">
                  Showing {Math.min(indexOfLastItem, barangayOfficials.length)} of {barangayOfficials.length} entries
                </div>
                <div className="flex space-x-2 font-semibold">
                  <button onClick={handlePrevPage} disabled={currentPage === 1} className="px-2 py-1 bg-gray-200 rounded hover:bg-[#1A50BE]">Previous</button>
                  {pageRange.map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={`px-2 py-1 rounded ${page === currentPage ? 'bg-[#1A50BE] text-white' : 'bg-gray-200 hover:bg-[#1A50BE]'}`}
                    >
                      {page}
                    </button>
                  ))}
                  <button onClick={handleNextPage} disabled={currentPage === totalPages} className="px-2 py-1 bg-gray-200 rounded hover:bg-[#1A50BE]">Next</button>        
            </div>
            </div>
            </div>
          </div>
        </main>
      </div>
    </div>

  );
};

export default BarangayDirectory;
