import React, { useState, useEffect } from 'react';
import axios from 'axios';
import defaultProfile from '../../src/assets/icons/default-profile.png';
import Swal from 'sweetalert2';

const HotlineModal = ({ hotline, onClose, onUpdate }) => {
  // Whether we are currently editing, or just viewing
  const [isEditMode, setIsEditMode] = useState(false);

  // Form data for editing
  const [formData, setFormData] = useState({
    name: '',
    contactNo: '',
    photo: null,
    label: '',
    customLabel: '',
  });

  const [photoPreview, setPhotoPreview] = useState('');
  const [showCustomLabel, setShowCustomLabel] = useState(false);
  const [errors, setErrors] = useState({});

  // Load the hotline data into formData
  useEffect(() => {
    if (!hotline) return;
    setFormData({
      name: hotline.name || '',
      contactNo: hotline.contactNo || '',
      photo: null,
      label: hotline.label || '',
      customLabel: '',
    });
    setPhotoPreview(hotline.photo || defaultProfile);

    // If the label is NOT one of the known categories => show custom label
    if (!['Police', 'Fire', 'Hospital', 'Red Cross', 'Disaster', 'Barangay'].includes(hotline.label)) {
      setShowCustomLabel(true);
      setFormData((prev) => ({ ...prev, customLabel: hotline.label, label: 'Others' }));
    }
  }, [hotline]);

  // Toggle between view-only and edit mode
  const handleEdit = () => {
    setIsEditMode(true);
  };
  const handleCancel = () => {
    setIsEditMode(false);
  };

  // When fields change...
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'label') {
      if (value === 'Others') {
        setShowCustomLabel(true);
        setFormData((prev) => ({ ...prev, label: 'Others', customLabel: '' }));
      } else {
        setShowCustomLabel(false);
        setFormData((prev) => ({ ...prev, label: value, customLabel: '' }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData((prev) => ({ ...prev, photo: file }));
      setPhotoPreview(URL.createObjectURL(file));
    }
  };

  // Confirm & then Save changes
  const handleSave = async (e) => {
    e.preventDefault();

    // Show SweetAlert confirmation
    Swal.fire({
      title: 'Are you sure you want to save changes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Save it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // 1. Validate fields
        let newErrors = {};
        if (!formData.name) newErrors.name = 'Hotline name is required.';
        if (!formData.contactNo) newErrors.contactNo = 'Contact number is required.';
        if (!formData.label && !formData.customLabel) {
          newErrors.label = 'Category is required.';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        // 2. Build form data
        const updatedData = new FormData();
        updatedData.append('name', formData.name);
        updatedData.append('contactNo', formData.contactNo);

        // If "Others", store custom label
        if (formData.label === 'Others') {
          updatedData.append('label', formData.customLabel);
        } else {
          updatedData.append('label', formData.label);
        }

        // If there's a new photo
        if (formData.photo) {
          updatedData.append('photo', formData.photo);
        }

        // 3. If this hotline was archived, confirm the user wants to unarchive => status: Active
        let statusToSend = hotline.status;
        if (hotline.status === 'Archive') {
          // We'll prompt a second time or do an auto-confirm?
          // Let's do an auto-prompt:
          const unarchiveResult = await Swal.fire({
            title: 'This hotline is archived!',
            text: 'By saving changes, do you want to set this hotline to Active?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, set Active',
            cancelButtonText: 'No, keep archived',
          });

          if (unarchiveResult.isConfirmed) {
            statusToSend = 'Active';
          }
        }

        // 4. Send PUT request
        try {
          // Append status if changed
          updatedData.append('status', statusToSend);

          await axios.put(
            `${process.env.REACT_APP_BACKEND_API_KEY}/api/hotlines/${hotline._id}`,
            updatedData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          );
          onUpdate(); // e.g. refetch the hotlines in parent
          setIsEditMode(false);

          Swal.fire('Saved!', 'Hotline changes have been saved.', 'success');
        } catch (error) {
          console.error('Error updating hotline:', error);
          Swal.fire('Error!', 'Failed to update the hotline.', 'error');
        }
      }
    });
  };

   // Confirm & then Archive
   const handleArchive = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to archive this hotline?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Archive it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Send archive request
          await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/hotlines/${hotline._id}`, {
            status: 'Archive',
          });
          onUpdate(); // e.g. refetch the hotlines in parent
          onClose();
          Swal.fire('Archived!', 'This hotline has been archived.', 'success');
        } catch (error) {
          console.error('Error archiving hotline:', error);
          Swal.fire('Error!', 'Failed to archive the hotline.', 'error');
        }
      }
    });
  };

  // Conditionally render read-only or edit UI
  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Title */}
        <h2 className="text-2xl font-bold mb-4">Hotline Details</h2>

        {/* If not in edit mode => read-only info */}
        {!isEditMode && (
          <div className="mb-4">
            <div className="flex items-center mb-4">
              <img
                src={photoPreview || defaultProfile}
                alt="Hotline"
                className="h-24 w-24 object-cover rounded-md mr-4"
              />
              <div>
                <p className="font-bold text-lg">{hotline.name}</p>
                <p>Contact No: {hotline.contactNo}</p>
                <p>Category: {hotline.label}</p>
              </div>
            </div>
          </div>
        )}

        {/* If in edit mode => show form */}
        {isEditMode && (
          <form onSubmit={handleSave}>
            <div className="mb-4">
              <label className="block text-md font-medium text-gray-700">
                Hotline Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-md font-medium text-gray-700">
                Contact Number
              </label>
              <input
                type="text"
                name="contactNo"
                value={formData.contactNo}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
              {errors.contactNo && <p className="text-red-500 text-sm">{errors.contactNo}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-md font-medium text-gray-700">Category</label>
              <select
                name="label"
                value={formData.label === 'Others' ? 'Others' : formData.label}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Select a category</option>
                <option value="Police">Police</option>
                <option value="Fire">Bureau of Fire Protection</option>
                <option value="Hospital">Hospital / Medical Emergency</option>
                <option value="Red Cross">Philippine Red Cross</option>
                <option value="Disaster">Disaster Risk Reduction</option>
                <option value="Barangay">Barangay Assistance</option>
                <option value="Others">Others</option>
              </select>
              {showCustomLabel && (
                <input
                  type="text"
                  name="customLabel"
                  value={formData.customLabel}
                  onChange={handleInputChange}
                  className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter custom category"
                />
              )}
              {errors.label && <p className="text-red-500 text-sm">{errors.label}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-md font-medium text-gray-700">Photo</label>
              <input type="file" name="photo" onChange={handleFileChange} />
              {photoPreview && (
                <div className="mt-2 flex justify-center">
                  <img
                    src={photoPreview}
                    alt="Hotline"
                    className="h-24 w-24 object-cover rounded-md"
                  />
                </div>
              )}
            </div>
          </form>
        )}

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2 mt-4 font-semibold">
          {isEditMode ? (
            <>
              {/* Save + Cancel */}
              <button
                type="button"
                onClick={handleSave}
                className="px-4 py-2 bg-[#1346AC] text-white rounded-md hover:bg-blue-700"
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="px-4 py-2 border border-[#1346AC] rounded-md text-gray-700 hover:bg-gray-100"
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              {/* Edit */}
              <button
                type="button"
                onClick={handleEdit}
                className="px-4 py-2 bg-[#1346AC] text-white rounded-md hover:bg-blue-700"
              >
                Edit
              </button>
            </>
          )}
          {/* Archive (both modes) */}
          <button
            type="button"
            onClick={handleArchive}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Archive
          </button>
          {/* Close (both modes) */}
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-gray-400 rounded-md text-gray-700 hover:bg-gray-100"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default HotlineModal;
