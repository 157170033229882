import React, { useState } from 'react';
import Swal from 'sweetalert2';

const AnnouncementModal = ({ announcement, onClose, onEdit }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        announcementCategory: announcement.announcementCategory,
        title: announcement.title,
        content: announcement.content,
        Importance: announcement.Importance,
        endDate: announcement.endDate ? announcement.endDate.split('T')[0] : '',
        endTime: announcement.endDate
            ? new Date(announcement.endDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) // ✅ Ensure consistent time format (24-hour)
            : '23:59',
        attachments: null,
    });

    const handleCancelClick = () => {
        setFormData({
            announcementCategory: announcement.announcementCategory,
            title: announcement.title,
            content: announcement.content,
            Importance: announcement.Importance,
            endDate: announcement.endDate ? announcement.endDate.split('T')[0] : '',
            endTime: announcement.endDate
                ? new Date(announcement.endDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) // Keep original time format
                : '23:59',
            attachments: null,
        });
    
        setIsEditing(false); // ✅ Exit edit mode without saving
    };
    

    const handleArchiveClick = () => {

        // ✅ Check if the announcement is already archived
        if (announcement.status === 'Archived') {
            Swal.fire({
                title: 'Already Archived',
                text: 'You have already archived this announcement!',
                icon: 'info',
                confirmButtonText: 'OK'
            });
            return; // 🔹 Stop execution if it's already archived
        }

        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to archive this announcement?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Archive it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                const loggedInUserId = JSON.parse(localStorage.getItem('user'))._id;
    
                // Prepare update data
                const updatedData = new FormData();
                updatedData.append('announcementCategory', formData.announcementCategory);
                updatedData.append('title', formData.title);
                updatedData.append('content', formData.content);
                updatedData.append('Importance', formData.Importance);
                updatedData.append('status', 'Archived'); // ✅ Mark as Archived
                updatedData.append('updated_by', loggedInUserId);
                updatedData.append('endDate', new Date().toISOString()); // Archive date
    
                // ✅ **Ensure onEdit returns a Promise**
                onEdit(updatedData, 'Archived')
                    .then(() => {  // ✅ **Now `.then()` works because it's returning a Promise**
                        Swal.fire({
                            title: 'Announcement Archived',
                            text: 'The announcement has been successfully archived!',
                            icon: 'success',
                            confirmButtonText: 'OK'
                        });
                    })
                    .catch(error => {
                        Swal.fire({
                            title: 'Error',
                            text: 'There was an issue archiving the announcement.',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        });
                        console.error('Archive error:', error);
                    });
            }
        });
    };
    
    
    // Handle input change for text fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle file input change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && !file.type.startsWith('image/')) {
            Swal.fire({
                title: 'Invalid File Type',
                text: 'Only image files (JPEG, PNG, GIF, etc.) are allowed.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            e.target.value = ''; // Reset file input
            return;
        }
        setFormData({ ...formData, attachments: file });
    };
    

    // Handle edit button click
    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        const loggedInUserId = JSON.parse(localStorage.getItem('user'))._id;
        const formattedEndDate = new Date(`${formData.endDate}T${formData.endTime}`);
        const selectedEndDate = new Date(`${formData.endDate}T${formData.endTime}`);
        const now = new Date();
    
        if (selectedEndDate < now) {
            Swal.fire({
                title: 'Invalid Date & Time',
                text: 'The selected end date and time must be in the future. If using today’s date, ensure the time is later than the current time.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }
    
        // Confirm with user before saving changes
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to save these changes to the announcement?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Save it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (!result.isConfirmed) return;
    
            // If status is Inactive and new endDate is in the future, prompt reactivation
            if (announcement.status === 'Inactive' && formattedEndDate > now) {
                Swal.fire({
                    title: 'Confirm Status Change',
                    text: 'This announcement is currently inactive. If you update the end date to a future date, it will be reactivated. Proceed?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Activate Announcement',
                    cancelButtonText: 'Cancel',
                }).then((res) => {
                    if (res.isConfirmed) {
                        saveAnnouncement(formattedEndDate, loggedInUserId, 'Active');
                    }
                });
            } else {
                saveAnnouncement(formattedEndDate, loggedInUserId, announcement.status);
            }
        });
    };    
    
    // ✅ Separate function to handle saving
    const saveAnnouncement = (formattedEndDate, userId, status) => {
        const updatedData = new FormData();
        updatedData.append('announcementCategory', formData.announcementCategory);
        updatedData.append('title', formData.title);
        updatedData.append('content', formData.content);
        updatedData.append('Importance', formData.Importance);
        updatedData.append('updated_by', userId);
        updatedData.append('endDate', formattedEndDate.toISOString());
        updatedData.append('status', status); // ✅ Set new status
    
        if (formData.attachments) {
            updatedData.append('attachments', formData.attachments);
        }
    
        // Call the onEdit function passed as a prop
        onEdit(updatedData)
            .then(() => {
                Swal.fire({
                    title: 'Announcement Updated',
                    text: `The announcement has been successfully updated${status === 'Active' ? ' and reactivated.' : '.'}`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            })
            .catch(error => {
                Swal.fire({
                    title: 'Error',
                    text: 'There was an issue updating the announcement.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                console.error('Update error:', error);
            });
    
        setIsEditing(false);
    };
    

    if (!announcement) return null;

    return (
        <>
            {/* Modal Overlay (Backdrop) */}
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

            {/* Modal Content */}
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg p-6 w-11/12 max-w-lg h-auto max-h-[90vh] overflow-y-auto shadow-xl">
                    <h2 className="text-xl font-bold mb-2">{isEditing ? 'Edit Announcement' : 'View Announcement'}</h2>
                    
                    {/* Category */}
                    <div className="mb-3">
                        <h3 className="text-lg font-semibold">Category</h3>
                        {isEditing ? (
                            <input type="text" name="announcementCategory" value={formData.announcementCategory} onChange={handleInputChange} className="w-full border rounded px-2 py-1" />
                        ) : (
                            <p>{announcement.announcementCategory}</p>
                        )}
                    </div>

                    {/* Title */}
                    <div className="mb-3">
                        <h3 className="text-lg font-semibold">Title</h3>
                        {isEditing ? (
                            <input type="text" name="title" value={formData.title} onChange={handleInputChange} className="w-full border rounded px-2 py-1" />
                        ) : (
                            <p>{announcement.title}</p>
                        )}
                    </div>

                    {/* Content */}
                    <div className="mb-3">
                        <h3 className="text-lg font-semibold">Content</h3>
                        {isEditing ? (
                            <textarea name="content" value={formData.content} onChange={handleInputChange} className="w-full border rounded px-2 py-1 min-h-28" />
                        ) : (
                            <p className="max-h-32 overflow-y-auto">{announcement.content}</p>
                        )}
                    </div>

                    <div className="mb-3">
                        <h3 className="text-lg font-semibold">End Date & Time</h3>
                        {isEditing ? (
                            <div className="flex space-x-4">
                                <input
                                    type="date"
                                    name="endDate"
                                    value={formData.endDate}
                                    onChange={handleInputChange}
                                    className="w-1/2 border rounded px-2 py-1"
                                />
                                <input
                                    type="time"
                                    name="endTime"
                                    value={formData.endTime}
                                    onChange={handleInputChange}
                                    className="w-1/3 border rounded px-2 py-1"
                                />
                            </div>
                        ) : (
                            <p>{new Date(announcement.endDate).toLocaleString()}</p>
                        )}
                    </div>


                    {/* Attachments */}
                    {isEditing ? (
                        <div className="mb-3">
                            <h3 className="text-lg font-semibold">Attachment</h3>
                            <input type="file" name="attachments" accept="image/*" onChange={handleFileChange} />
                        </div>
                    ) : (
                        announcement.attachments && (
                            <div className="mb-3">
                                <h3 className="text-lg font-semibold">Attachment</h3>
                                <img src={announcement.attachments} alt="Attachment" className="w-24 h-24 object-cover rounded-md" />
                            </div>
                        )
                    )}

                    {/* Buttons */}
                    <div className="flex justify-center space-x-4 mt-8">
                       {/* When in Edit Mode */}
                        {isEditing ? (
                            <>
                                {/* Save Button */}
                                <button 
                                    onClick={handleSaveClick} 
                                    className="bg-[#1346AC] text-white px-4 py-2 rounded-full hover:bg-blue-700 transition"
                                >
                                    Save
                                </button>

                                {/* Archive Button - Always Visible */}
                                <button 
                                    onClick={handleArchiveClick} 
                                    className="border border-red-500 px-4 py-2 rounded-full hover:bg-red-500 hover:text-white transition"
                                >
                                    Archive
                                </button>

                                {/* Cancel Button - Only Visible in Edit Mode */}
                                <button 
                                    onClick={() => setIsEditing(false)} // ✅ Change edit mode to false
                                    className="border border-gray-500 px-4 py-2 rounded-full hover:bg-gray-200 transition"
                                >
                                    Cancel
                                </button>

                                {/* Close Button - Always Visible */}
                                <button 
                                    onClick={onClose} 
                                    className="border border-[#1346AC] px-4 py-2 rounded-full hover:bg-gray-200 transition"
                                >
                                    Close
                                </button>
                            </>
                        ) : (
                            <>
                                {/* Edit Button - First when NOT in edit mode */}
                                <button 
                                    onClick={handleEditClick} 
                                    className={`px-4 py-2 rounded-full transition ${
                                        announcement.status === 'Archived' 
                                        ? 'bg-gray-400 text-white cursor-not-allowed'  // Disabled look if Archived
                                        : 'bg-[#1346AC] text-white hover:bg-blue-700'
                                    }`}
                                    disabled={announcement.status === 'Archived'} // ✅ Disable button if Archived
                                >
                                    Edit Announcement
                                </button>

                                {/* Archive Button - Always Visible */}
                                <button 
                                    onClick={handleArchiveClick} 
                                    className="border border-red-500 px-4 py-2 rounded-full hover:bg-red-500 hover:text-white transition"
                                >
                                    Archive
                                </button>

                                {/* Close Button - Always Visible */}
                                <button 
                                    onClick={onClose} 
                                    className="border border-[#1346AC] px-4 py-2 rounded-full hover:bg-gray-200 transition"
                                >
                                    Close
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};


export default AnnouncementModal;
