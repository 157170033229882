import React, { useState, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import IncidentReportDataPrivAgreement from "../assets/dataprivacyandtermsandconditions/IncidentReportDataPrivAgreement";
import IncidentReportTermsandConditions from "../assets/dataprivacyandtermsandconditions/IncidentReportTermsandConditions";

const AddComplaintModal = ({ onClose, onSubmit, adminData }) => {
  const fileInputRef = useRef(null);

  // If an admin is creating the complaint, set complainantByType to 'Admin'
  // We also attach the admin's _id to "complainantID" so we know who filed it
  const [formData, setFormData] = useState({
    complainantID: adminData?._id || "",
    complainantByType: "Admin",
    complainantname: [],  // Empty at start
    respondentname: [],
    typeofcomplaint: "",
    otherComplaintType: "",
    incidentdescription: "",
    relieftobegranted: "",
    dateAndTimeofIncident: "",
    attachments: []
  });

  const [complainantInput, setComplainantInput] = useState("");
  const [respondentInput, setRespondentInput] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState({});

  // ------------------- HANDLERS -------------------
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddComplainant = () => {
    if (complainantInput.trim() !== "") {
      setFormData((prev) => ({
        ...prev,
        complainantname: [...prev.complainantname, complainantInput.trim()],
      }));
      setComplainantInput("");
    }
  };

  const handleRemoveComplainant = (index) => {
    setFormData((prev) => ({
      ...prev,
      complainantname: prev.complainantname.filter((_, i) => i !== index),
    }));
  };

  const handleAddRespondent = () => {
    if (respondentInput.trim() !== "") {
      setFormData((prev) => ({
        ...prev,
        respondentname: [...prev.respondentname, respondentInput.trim()],
      }));
      setRespondentInput("");
    }
  };

  const handleRemoveRespondent = (index) => {
    setFormData((prev) => ({
      ...prev,
      respondentname: prev.respondentname.filter((_, i) => i !== index),
    }));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFormData((prev) => ({
      ...prev,
      attachments: [...prev.attachments, ...newFiles],
    }));
  };

  const handleRemoveFile = (fileIndex) => {
    setFormData((prev) => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== fileIndex),
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const openTermsModal = () => setShowTermsModal("terms");
  const openPrivacyModal = () => setShowTermsModal("privacy");
  const closeModal = () => setShowTermsModal(null);

  // ------------------- VALIDATION -------------------
  const validateForm = () => {
    let newErrors = {};
    const allowedFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "video/mp4",
      "video/mov",
      "video/avi",
      "video/mkv",
    ];
    const maxFileSize = 10 * 1024 * 1024; // 10MB

    // 1. Type of complaint
    if (!formData.typeofcomplaint) {
      newErrors.typeofcomplaint = "Type of complaint is required";
    } else if (
      formData.typeofcomplaint === "Others" &&
      !formData.otherComplaintType
    ) {
      newErrors.otherComplaintType = "Please specify the complaint type";
    }

    // 2. Complainants / Respondents
    if (formData.complainantname.length === 0) {
      newErrors.complainantname = "At least one complainant is required";
    }
    if (formData.respondentname.length === 0) {
      newErrors.respondentname = "At least one respondent is required";
    }

    // 3. Incident description & relief
    if (!formData.incidentdescription || formData.incidentdescription.length < 8) {
      newErrors.incidentdescription =
        "Statement of Complaint must be at least 8 characters";
    }
    if (!formData.relieftobegranted || formData.relieftobegranted.length < 8) {
      newErrors.relieftobegranted =
        "Prayer for Relief must be at least 8 characters";
    }

    // 4. Date/time
    if (!formData.dateAndTimeofIncident) {
      newErrors.dateAndTimeofIncident = "Date and Time of Incident are required";
    } else {
      const chosenDate = new Date(formData.dateAndTimeofIncident);
      const now = new Date();
      const fiveYearsAgo = new Date();
      fiveYearsAgo.setFullYear(now.getFullYear() - 5);

      if (chosenDate > now) {
        newErrors.dateAndTimeofIncident = "You cannot select a future date";
      } else if (chosenDate < fiveYearsAgo) {
        newErrors.dateAndTimeofIncident = "The incident is more than 5 years old";
      }
    }

    // 5. Attachments
    if (formData.attachments.length === 0) {
      newErrors.attachments = "At least one supporting file is required";
    } else {
      for (let file of formData.attachments) {
        if (!allowedFileTypes.includes(file.type)) {
          newErrors.attachments = `Invalid file type: ${file.type}`;
          break;
        }
        if (file.size > maxFileSize) {
          newErrors.attachments = `File size must not exceed ${maxFileSize / (1024 * 1024)}MB`;
          break;
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

   // ------------------- SUBMIT -------------------
   const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    if (!termsAccepted) {
      setShowError(true);
      return;
    }

    // Show a minimal loading indicator instead of the default spinner
    Swal.fire({
      title: "Uploading...",
      html: "Please wait while we create this complaint.",
      allowOutsideClick: false,
      showConfirmButton: false,
      // Using 'willOpen' to show loading immediately without large overhead
      willOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const fd = new FormData();
      fd.append("complainantID", formData.complainantID);
      fd.append("complainantByType", formData.complainantByType);
      fd.append("complainantname", JSON.stringify(formData.complainantname));
      fd.append("respondentname", JSON.stringify(formData.respondentname));
      fd.append(
        "typeofcomplaint",
        formData.typeofcomplaint === "Others"
          ? formData.otherComplaintType
          : formData.typeofcomplaint
      );
      fd.append("incidentdescription", formData.incidentdescription);
      fd.append("relieftobegranted", formData.relieftobegranted);
      fd.append("dateAndTimeofIncident", formData.dateAndTimeofIncident);

      // files
      formData.attachments.forEach((file) => {
        fd.append("attachments", file);
      });

      // POST to your /api/new/incident-report
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_KEY}/api/new/incident-report`,
        fd,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // If successful
      Swal.fire({
        icon: "success",
        title: "Complaint Created",
        text: `Reference No.: ${response.data?.referenceNo || "N/A"}`,
      });

      // Trigger parent's refresh
      onSubmit();
      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error creating complaint:", error);
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text:
          error.response?.data?.message ||
          "An error occurred while creating the complaint. Please try again.",
      });
    }
  };

   // ------------------- RENDER -------------------
   return (
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={onClose}
    >
      <div
        className="bg-white w-full max-w-2xl rounded-lg shadow-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button */}
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          ✕
        </button>

        <h2 className="text-2xl font-semibold mt-6 ml-6">Add Complaint</h2>

        {/* Scrollable form container */}
        <div className="max-h-[70vh] overflow-y-auto p-6 pt-2">
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Type of Complaint */}
            <div>
              <label className="block text-md font-medium text-gray-700">
                Nature of Complaint
              </label>
              <select
                name="typeofcomplaint"
                value={formData.typeofcomplaint}
                onChange={handleInputChange}
                className="w-full border p-2 rounded"
              >
                <option value="">Select Type of Complaint</option>
                <option value="Vehicular Accident">Vehicular Accident</option>
                <option value="Disturbance of Peace">Disturbance of Peace</option>
                <option value="Physical Altercation">Physical Altercation</option>
                <option value="Harrassment">Harrassment</option>
                <option value="Property Damage">Property Damage</option>
                <option value="Unsettled Debts">Unsettled Debts</option>
                <option value="Petty Crimes">Petty Crimes</option>
                <option value="Others">Others</option>
              </select>
              {errors.typeofcomplaint && (
                <p className="text-red-500 text-xs">{errors.typeofcomplaint}</p>
              )}
            </div>

            {/* If "Others" */}
            {formData.typeofcomplaint === "Others" && (
              <div>
                <label className="block text-md font-medium text-gray-700">
                  Specify Other Complaint Type
                </label>
                <input
                  type="text"
                  name="otherComplaintType"
                  value={formData.otherComplaintType}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded"
                />
                {errors.otherComplaintType && (
                  <p className="text-red-500 text-xs">
                    {errors.otherComplaintType}
                  </p>
                )}
              </div>
            )}

            {/* Complainants */}
            <div>
              <label className="block text-md font-medium text-gray-700">
                Complainant(s)
              </label>
              <div className="flex space-x-2 mb-1">
                <input
                  type="text"
                  placeholder="Enter complainant's name"
                  value={complainantInput}
                  onChange={(e) => setComplainantInput(e.target.value)}
                  className="flex-grow border p-2 rounded"
                />
                <button
                  type="button"
                  onClick={handleAddComplainant}
                  className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
                >
                  Add
                </button>
              </div>
              <ul className="space-y-1">
                {formData.complainantname.map((name, idx) => (
                  <li key={idx} className="flex items-center justify-between">
                    <span>{name}</span>
                    <button
                      type="button"
                      className="text-red-500 hover:text-red-700"
                      onClick={() => handleRemoveComplainant(idx)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
              {errors.complainantname && (
                <p className="text-red-500 text-xs">{errors.complainantname}</p>
              )}
            </div>

            {/* Respondents */}
            <div>
              <label className="block text-md font-medium text-gray-700">
                Respondent(s)
              </label>
              <div className="flex space-x-2 mb-1">
                <input
                  type="text"
                  placeholder="Enter respondent's name"
                  value={respondentInput}
                  onChange={(e) => setRespondentInput(e.target.value)}
                  className="flex-grow border p-2 rounded"
                />
                <button
                  type="button"
                  onClick={handleAddRespondent}
                  className="bg-[#1346AC] hover:bg-blue-700 text-white px-4 py-2 rounded"
                >
                  Add
                </button>
              </div>
              <ul className="space-y-1">
                {formData.respondentname.map((name, idx) => (
                  <li key={idx} className="flex items-center justify-between">
                    <span>{name}</span>
                    <button
                      type="button"
                      className="text-red-500 hover:text-red-700"
                      onClick={() => handleRemoveRespondent(idx)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
              {errors.respondentname && (
                <p className="text-red-500 text-xs">{errors.respondentname}</p>
              )}
            </div>

            {/* Statement */}
            <div>
              <label className="block text-md font-medium text-gray-700">
                Statement of Complaint
              </label>
              <textarea
                name="incidentdescription"
                value={formData.incidentdescription}
                onChange={handleInputChange}
                rows={3}
                className="w-full border p-2 rounded"
              />
              {errors.incidentdescription && (
                <p className="text-red-500 text-xs">
                  {errors.incidentdescription}
                </p>
              )}
            </div>

            {/* Relief */}
            <div>
              <label className="block text-md font-medium text-gray-700">
                Prayer for Relief
              </label>
              <textarea
                name="relieftobegranted"
                value={formData.relieftobegranted}
                onChange={handleInputChange}
                rows={2}
                className="w-full border p-2 rounded"
              />
              {errors.relieftobegranted && (
                <p className="text-red-500 text-xs">{errors.relieftobegranted}</p>
              )}
            </div>

            {/* Date and Time of Incident */}
            <div>
              <label className="block text-md font-medium text-gray-700">
                Date and Time of Incident
              </label>
              <input
                type="datetime-local"
                name="dateAndTimeofIncident"
                value={formData.dateAndTimeofIncident}
                onChange={handleInputChange}
                className="w-full border p-2 rounded"
              />
              {errors.dateAndTimeofIncident && (
                <p className="text-red-500 text-xs">
                  {errors.dateAndTimeofIncident}
                </p>
              )}
            </div>

            {/* Attachments */}
            <div>
              <label className="block text-md font-medium text-gray-700">
                Supporting Evidence
              </label>
              {formData.attachments.length > 0 && (
                <ul className="space-y-2 mt-1">
                  {formData.attachments.map((file, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between bg-gray-50 p-2 border rounded"
                    >
                      <div className="truncate">
                        <strong>{file.name}</strong>
                        <p className="text-xs">{file.type}</p>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              )}
              <input
                type="file"
                multiple
                ref={fileInputRef}
                onChange={handleFileChange}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
              />
              {errors.attachments && (
                <p className="text-red-500 text-xs">{errors.attachments}</p>
              )}
            </div>

            <div className="mt-2 flex items-center">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              className="mr-2"
            />
            <span className="text-md">
              I agree to the{" "}
              <span className="text-blue-600 cursor-pointer underline" onClick={openTermsModal}>
                Terms and Conditions
              </span>{" "}
              and{" "}
              <span className="text-blue-600 cursor-pointer underline" onClick={openPrivacyModal}>
                Data Privacy Agreement
              </span>.
            </span>
          </div>
          {showError && !termsAccepted && (
            <p className="text-red-500 text-sm mb-4">
              You must agree to the Terms and Conditions and Data Privacy Agreement.
            </p>
          )}
          <div className="flex justify-end space-x-3">
            <button
              type="submit"
              className="px-4 py-2 bg-[#1346AC] text-white rounded-full hover:bg-blue-700 font-semibold"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-full font-semibold bg-gray-200 hover:bg-gray-100"
            >
              Cancel
            </button>
          </div>
          </form>
          {showTermsModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
              {showTermsModal === "terms" ? (
                <IncidentReportTermsandConditions closeModal={closeModal} />
              ) : (
                <IncidentReportDataPrivAgreement closeModal={closeModal} />
              )}
            </div>
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default AddComplaintModal;
