import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { MdRateReview , MdEdit, MdArchive, MdAutorenew } from "react-icons/md";
import logo from '../assets/icons/logo192.png'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const HouseholdsList = () => {
    const navigate = useNavigate();
    const [households, setHouseholds] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [filters, setFilters] = useState({
        sex: 'All',
        civilStatus: 'All',
         status: 'Active',
    });
    const [searchQuery, setSearchQuery] = useState(''); 
    const [loadingMembers, setLoadingMembers] = useState(false);
    const [barangay, setBarangay] = useState(null);

    useEffect(() => {
        const fetchBarangay = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/barangay`); 
                setBarangay(response.data.barangay);
            } catch (error) {
                console.error('Error fetching barangay data:', error);
            }
        };
    
        fetchBarangay();
    }, []);    

    useEffect(() => {
        fetchHouseholds();
    }, []);

    const fetchHouseholds = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/all/households`);
            setHouseholds(response.data.households);
        } catch (error) {
            console.error('Error fetching households:', error);
        }
    };

    const formatAddress = (permanentAddress) => {
        let address = [
            permanentAddress?.houseNo,
            permanentAddress?.street
        ].filter(Boolean).join(', ');

        const fullAddress = address;
        return fullAddress.length > 50 ? `${fullAddress.slice(0, 50)}...` : fullAddress;
    };

// ✅ Open Archive Modal with Household Members
const openArchiveModal = async (household) => {
    setLoadingMembers(true);
    const token = localStorage.getItem('token');

    if (!token) {
        Swal.fire('Unauthorized', 'You need to be logged in to archive a household.', 'error');
        return;
    }

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/id/${household._id}`, {
            headers: { Authorization: `Bearer ${token}` } // ✅ Include token in headers
        });
        const householdData = {
            ...household,
            members: response.data.members,
            householdHead: response.data.householdHead
        };
        
        // ✅ Show SweetAlert with Members List
        Swal.fire({
            title: 'Are you sure?',
            html: `
                <p>Archiving this household will also archive the following residents:</p>
                <ul class="text-left mt-3">
                    <li><b>Household Head:</b> ${householdData.householdHead?.firstName} ${householdData.householdHead?.lastName}</li>
                    ${householdData.members.map(member => `
                        <li> ${member.firstName} ${member.lastName} (${member.roleinHousehold})</li>
                    `).join('')}
                </ul>
                <br><b>This action cannot be undone.</b>
            `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Archive it!',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                await axios.put(
                        `${process.env.REACT_APP_BACKEND_API_KEY}/api/household/archive/${household._id}`,
                        {}, // ✅ No request body needed
                        { headers: { Authorization: `Bearer ${token}` } } // ✅ Include token
                    );
                    fetchHouseholds(); // Refresh list
                    Swal.fire('Archived!', 'The household and its members have been archived.', 'success');
                } catch (error) {
                    console.error('Error archiving household:', error);
                    Swal.fire('Error', 'Something went wrong while archiving.', 'error');
                }
            }
        });
    } catch (error) {
        console.error('Error fetching household members:', error);
        Swal.fire('Error', 'Failed to load household members.', 'error');
    } finally {
        setLoadingMembers(false);
    }
};


    const resetFilters = () => {
        setFilters({
            sex: 'All',
            civilStatus: 'All',
        });
    };

    const handleSortChange = (e) => {
        const value = e.target.value;
        setSortBy(value);
        let sortedHouseholds = [...households];
    
        if (value === 'Household No.') {
            // Sort household numbers numerically (e.g., 2024-0001, 2024-0002)
            sortedHouseholds.sort((a, b) => {
                const [yearA, numA] = a.householdID.split('-').map(Number);
                const [yearB, numB] = b.householdID.split('-').map(Number);
                return yearA - yearB || numA - numB;
            });
        } else if (value === 'Name') {
            // Sort by the first name of the household head alphabetically
            sortedHouseholds.sort((a, b) => 
                a.householdHead?.firstName.localeCompare(b.householdHead?.firstName)
            );
        } else if (value === 'Sex') {
            // Sort to show males first, then females
            sortedHouseholds.sort((a, b) => {
                const sexA = a.householdHead?.sex.toLowerCase();
                const sexB = b.householdHead?.sex.toLowerCase();
                if (sexA === sexB) return 0;
                if (sexA === 'male') return -1;
                return 1;
            });
        } else if (value === 'Civil Status') {
            // Custom order for civil status
            const order = ['Single', 'Married', 'Separated', 'Divorced', 'Widowed', 'Annulled'];
            sortedHouseholds.sort((a, b) => {
                const statusA = a.householdHead?.civilStatus;
                const statusB = b.householdHead?.civilStatus;
                return order.indexOf(statusA) - order.indexOf(statusB);
            });
        }
    
        setHouseholds(sortedHouseholds);
    };
    

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

// ✅ Update Filtering Logic to Include Status Filter
const filteredHouseholds = households.filter((household) => {
    const matchesSearch = (
        household.householdID.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        `${household.householdHead?.firstName || ''} ${household.householdHead?.middleName ? household.householdHead.middleName + ' ' : ''}${household.householdHead?.lastName || ''}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const matchesSex = filters.sex === 'All' || household.householdHead?.sex === filters.sex;
    const matchesCivilStatus = filters.civilStatus === 'All' || household.householdHead?.civilStatus === filters.civilStatus;
    const matchesStatus = filters.status === 'All' || household.status === filters.status; // ✅ Status filter added

    return matchesSearch && matchesSex && matchesCivilStatus && matchesStatus;
});

const formatFullAddress = (addressObj) => {
    return [
        addressObj.houseNo,
        addressObj.lotNo,
        addressObj.unitFloorRoomNo,
        addressObj.building,
        addressObj.street,
        addressObj.barangay
    ].filter(Boolean).join(', ');
};


const handlePrint = () => {
    const doc = new jsPDF('landscape');
    const image = new Image();
    image.src = logo;

    image.onload = () => {
        // Add logo
        doc.addImage(image, 'PNG', 10, 10, 30, 30);

        // Title and Barangay Info
        doc.setFontSize(18);
        doc.text('List of Barangay Households', 45, 25);

        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(
            `Barangay: ${barangay?.barangayName || ''} | Municipality: ${barangay?.municipality || ''} | Province: ${barangay?.province || ''}`,
            45,
            33
        );

        // Table Data
        const tableData = filteredHouseholds.map((hh) => [
            hh.householdID || 'N/A',
            `${hh.householdHead?.firstName || ''} ${hh.householdHead?.middleName || ''} ${hh.householdHead?.lastName || ''}`.trim() || 'N/A',
            hh.householdHead?.sex || 'N/A',
            hh.householdHead?.civilStatus || 'N/A',
            hh.householdHead?.contactNumber || 'N/A',
            hh.householdHead?.permanentAddress
                ? formatFullAddress(hh.householdHead.permanentAddress)
                : 'N/A',
            hh.status || 'N/A',
        ]);

        autoTable(doc, {
            startY: 45,
            head: [[
                'Household No.',
                'Head of Household',
                'Sex',
                'Civil Status',
                'Contact Number',
                'Address',
                'Status',
            ]],
            body: tableData,
            theme: 'grid',
            styles: {
                fontSize: 9,
                cellPadding: 2,
                overflow: 'linebreak',
                lineColor: [220, 220, 220],
                lineWidth: 0.1,
            },
            headStyles: {
                fillColor: [19, 70, 172], // #1346AC
                textColor: [255, 255, 255],
                fontStyle: 'bold',
                halign: 'center',
            },
            bodyStyles: {
                halign: 'center',
                textColor: 20,
            },
            margin: { top: 10 }
        });

        doc.save('households_list.pdf');
    };

    image.onerror = () => {
        console.error('Failed to load logo');
        Swal.fire('Error', 'Failed to load logo image for PDF export.', 'error');
    };
};

    const handleDownloadBackup = async (fileType) => {
        const confirmation = await Swal.fire({
            title: `Confirm Download`,
            text: fileType === 'json'
                ? 'Do you want to download the Residents and Households JSON backup?'
                : 'Do you want to download the Households and Residents Excel file?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Download',
            cancelButtonText: 'Cancel',
        });
    
        if (!confirmation.isConfirmed) return;
    
        try {
            let urls = [];
    
            if (fileType === 'json') {
                urls = [
                    { url: `${process.env.REACT_APP_BACKEND_API_KEY}/api/households/export/json`, filename: 'households_backup.json' },
                    { url: `${process.env.REACT_APP_BACKEND_API_KEY}/api/backup/residents`, filename: 'residents_backup.json' },
                ];
            } else {
                urls = [
                    { url: `${process.env.REACT_APP_BACKEND_API_KEY}/api/households/export/excel`, filename: 'households_backup.xlsx' },
                    { url: `${process.env.REACT_APP_BACKEND_API_KEY}/api/backup/residents/excel`, filename: 'residents_backup.xlsx' } // ✅
                ];
            }
    
            for (const { url, filename } of urls) {
                const response = await fetch(url);
                if (!response.ok) throw new Error(`Failed to download ${filename}`);
    
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
    
            Swal.fire({
                icon: 'success',
                title: 'Download Successful',
                text: fileType === 'json'
                    ? 'Residents and Households JSON backups have been downloaded.'
                    : 'Households and Residents Excel backups have been downloaded.',
            });
        } catch (error) {
            console.error('Error downloading backup:', error);
            Swal.fire({
                icon: 'error',
                title: 'Download Failed',
                text: 'An error occurred while downloading. Please try again.',
            });
        }
    };
      
      // ✅ Activate a Household
      const activateHousehold = async (household) => {
        const token = localStorage.getItem('token');

        if (!token) {
            Swal.fire('Unauthorized', 'You need to be logged in to activate a household.', 'error');
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "Activating this household will restore its active status.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1346AC',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Activate it!',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.put(
                        `${process.env.REACT_APP_BACKEND_API_KEY}/api/household/activate/${household._id}`,
                        {},
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    fetchHouseholds();
                    Swal.fire('Activated!', 'The household has been restored.', 'success');
                } catch (error) {
                    console.error('Error activating household:', error);
                    Swal.fire('Error', 'Something went wrong while activating.', 'error');
                }
            }
        });
    };

    return (
        <div className="bg-white p-6 rounded-lg">
            <div className='flex-col justify-between items-center mb-2'>
            <div className='flex justify-between items-center mb-2'>
            <h2 className="text-2xl font-semibold">List of Households</h2>
            <div className="flex justify-between items-center">
                <button
                    className="bg-[#1346AC] hover:bg-blue-700 text-white px-8 py-2 rounded-full font-semibold w-full"
                    onClick={() => navigate('/AddResident')}
                >
                    Add Resident
                </button>

                {/* Export as File Dropdown */}
                <div className="dropdown dropdown-hover p-2">
                    <button
                        tabIndex={0}
                        className="text-[#1346AC] hover:bg-[#1346AC] hover:text-white px- py-2 rounded-full font-semibold border border-[#1346AC] min-w-40"
                    >
                        Export as File
                    </button>
                    <ul
                        tabIndex={0}
                        className="dropdown-content menu bg-base-100 rounded-box shadow w-52 p-2 absolute left-1/2 transform -translate-x-1/2 z-50"
                    >
                        <li>
                            <button onClick={handlePrint} className="w-full text-left">Download as PDF</button>
                        </li>
                        <li>
                            <button onClick={() => handleDownloadBackup('excel')} className="w-full text-left">Download as Excel</button>
                        </li>
                        <li>
                            <button onClick={() => handleDownloadBackup('json')} className="w-full text-left">Download as JSON</button>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <div className='flex justify-between'>
            <div className="flex space-x-4">
                    <div className="min-w-48">
                        <label htmlFor="sex" className="block text-sm font-medium text-gray-700">Sex</label>
                        <div className="relative">
                            <select
                                id="sex"
                                name="sex"
                                value={filters.sex}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md appearance-none focus:ring-0 focus:border-gray-300"
                            >
                                <option>All</option>
                                <option>Male</option>
                                <option>Female</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 0 01-1.414 0l-4-4a1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="min-w-48">
                        <label htmlFor="civilStatus" className="block text-sm font-medium text-gray-700">Civil Status</label>
                        <div className="relative">
                            <select
                                id="civilStatus"
                                name="civilStatus"
                                value={filters.civilStatus}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md appearance-none focus:ring-0 focus:border-gray-300"
                            >
                                <option>All</option>
                                <option>Single</option>
                                <option>Married</option>
                                <option>Separated</option>
                                <option>Widowed</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 0 01-1.414 0l-4-4a1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="min-w-48">
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
                        <div className="relative">
                            <select
                                id="status"
                                name="status"
                                value={filters.status}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md appearance-none focus:ring-0 focus:border-gray-300"
                            >
                                <option>Active</option>
                                <option>All</option>
                                <option>Archive</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 0 01-1.414 0l-4-4a1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <button
                        className="text-blue-500 hover:text-[#1A50BE] cursor-pointer font-semibold"
                        onClick={resetFilters}
                    >
                        Reset Filters
                    </button>
                </div>
                <div className="flex flex-col items-end space-y-2">
                    <input
                        type="text"
                        placeholder="Search households"
                        className="w-80 px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500"
                        value={searchQuery}
                        onChange={handleSearchChange} 
                    />
                <div className="flex items-center space-x-2">
                    <label htmlFor="sortBy" className="text-sm font-medium text-gray-700 whitespace-nowrap">
                        Sort by
                    </label>
                    <div className="relative w-full">
                        <select
                            id="sortBy"
                            name="sortBy"
                            className="block w-full py-1 text-base hover:text-blue-500 text-[#1346AC] font-semibold appearance-none focus:outline-none focus:ring-0 px-4 pr-8"
                            value={sortBy}
                            onChange={handleSortChange}
                        >
                            <option value="Household No.">Household No.</option>
                            <option value="Name">Name</option>
                            <option value="Sex">Sex</option>
                            <option value="Civil Status">Civil Status</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
                            <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

                           
            <div className="overflow-x-auto scrollbar-thin text-center">
            <table className="min-w-full bg-white" id="printable-area">
                    <thead>
                        <tr className='bg-[#1346AC] text-white'>
                            <th className="py-2 border-b border-r border-gray-400 rounded-tl-lg">Household No.</th>
                            <th className="py-2 border-b border-r border-gray-400">Household Head Name</th>
                            <th className="py-2 border-b border-r border-gray-400">Sex</th>
                            <th className="py-2 border-b border-r border-gray-400">Civil Status</th>
                            <th className="py-2 border-b border-r border-gray-400">Contact Number</th>
                            <th className="py-2 border-b border-r border-gray-400">Address</th>
                            <th className="py-2 border-b border-r border-gray-400 actions-column">Status</th>
                            <th className="py-2 border-b border-gray-400 actions-column rounded-tr-lg">Actions</th>
                        </tr>
                    </thead>
                    <tbody className='border border-gray-400'>
                    {filteredHouseholds.map((household, index) => (
                        <tr key={household.householdID} className={`border-t border-gray-400 text-center ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100' }`}>
                            <td className="py-2 px-4 border-b border-r border-gray-400 text-center">{household.householdID}</td>
                            <td className="py-2 px-4 border-b border-r border-gray-400">
                                {`${household.householdHead?.firstName || 'N/A'} ${household.householdHead?.middleName ? household.householdHead.middleName + ' ' : ''}${household.householdHead?.lastName || 'N/A'}`}
                            </td>
                            <td className="py-2 px-4 border-b border-r border-gray-400 text-center">{household.householdHead?.sex || 'N/A'}</td>
                            <td className="py-2 px-4 border-b border-r border-gray-400">{household.householdHead?.civilStatus || 'N/A'}</td>
                            <td className="py-2 px-4 border-b border-r border-gray-400">{household.householdHead?.contactNumber || 'N/A'}</td>
                            <td className="py-2 px-4 border-b border-r border-gray-400">
                                {household.householdHead?.permanentAddress 
                                    ? formatAddress(household.householdHead.permanentAddress) 
                                    : 'Address not available'} St.
                            </td>
                            <td className="py-2 px-4 border-l border-b border-r border-gray-400 status-column">
                                <span className={`px-2 py-1 rounded-full font-semibold 
                                ${household.status === 'Active' ? 'bg-[#1346AC] text-white' : 
                                    household.status === 'Archive' ? 'bg-red-700 text-white' : 
                                    'bg-gray-400 text-white'}`}>
                                    {household.status}
                                </span>
                            </td>
                            <td className="border-b border-gray-400 space-x-2 text-center font-semibold actions-column">
                            <div className="flex justify-center space-x-4 items-center">
                                <div className="border-r border-gray-300 pr-4">
                                <button 
                                className="text-[#1346AC] hover:text-blue-500 inline-flex items-center"
                                onClick={() => navigate(`/view-household/${household.householdHead?._id}`)}
                                >
                                <MdRateReview  className="mr-1 text-lg" />
                                    VIEW
                                </button> 
                                </div>
                                <div className="border-r border-gray-300 pr-4">
                                <button 
                                className="text-[#1346AC] hover:text-yellow-500 inline-flex items-center"
                                onClick={() => navigate(`/edit-household/${household._id}`)}>
                                <MdEdit className="mr-1 text-lg" />
                                    EDIT
                                </button> 
                                </div>
                                    <div className="flex justify-center space-x-4 items-center">
                                {household.status === 'Active' ? (
                                    <button className="text-[#1346AC] hover:text-red-500 inline-flex items-center" onClick={() => openArchiveModal(household)}>
                                        <MdArchive className="mr-1 text-lg" />
                                        ARCHIVE
                                    </button>
                                ) : (
                                    <button className="text-[#1346AC] hover:text-green-800 inline-flex items-center" onClick={() => activateHousehold(household)}>
                                        <MdAutorenew className="mr-1 text-lg" />
                                        ACTIVATE
                                    </button>
                                )}
                            </div>
                            </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between items-center mt-4">
                <div className="text-sm text-gray-600">
                    Showing 1 to 10 of {filteredHouseholds.length} entries
                </div>
                <div className="flex space-x-2 font-semibold">
                    <button className="px-2 py-1 bg-gray-200 rounded hover:bg-[#1A50BE]">1</button>
                    <button className="px-2 py-1 bg-gray-200 rounded hover:bg-[#1A50BE]">2</button>
                    <button className="px-2 py-1 bg-gray-200 rounded hover:bg-[#1A50BE]">3</button>
                    <button className="px-2 py-1 bg-gray-200 rounded hover:bg-[#1A50BE]">4</button>
                </div>
            </div>
        </div>
    );  
};

export default HouseholdsList;