import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // ✅ import and register the plugin
import axios from 'axios';
import logo from '../assets/icons/logo192.png'


const BarangayProfilingTableModal = ({ filters = {}, residents, onClose }) => {
    const [barangay, setBarangay] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRange, setPageRange] = useState([1, 2, 3, 4]);
    const residentsPerPage = 10;

    useEffect(() => {
        const fetchBarangay = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/barangay`); 
                setBarangay(response.data.barangay);
            } catch (error) {
                console.error('Error fetching barangay data:', error);
            }
        };
    
        fetchBarangay();
    }, []);    

    const indexOfLast = currentPage * residentsPerPage;
    const indexOfFirst = indexOfLast - residentsPerPage;
    const currentResidents = residents.slice(indexOfFirst, indexOfLast);
    const totalPages = Math.ceil(residents.length / residentsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
            if (pageRange[0] > 1) {
                setPageRange(pageRange.map(page => page - 1));
            }
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
            if (pageRange[3] < totalPages) {
                setPageRange(pageRange.map(page => page + 1));
            }
        }
    };
  
    const handleExportPDF = () => {
        const doc = new jsPDF('landscape');
    
        const image = new Image();
        image.src = logo;
    
        image.onload = () => {
            // Add logo to PDF
            doc.addImage(image, 'PNG', 10, 10, 30, 30); // x, y, width, height
    
            // Add title and barangay info
            doc.setFontSize(18);
            doc.text('Barangay Profiling Report', 45, 25);
    
            doc.setFontSize(10);
            doc.text(
                `Barangay: ${barangay?.barangayName || ''}, Municipality: ${barangay?.municipality || ''}, Province: ${barangay?.province || ''}`,
                45,
                33
            );
    
            // Add filter info
            doc.text(
                `Filters: Sex: ${filters.sex || 'Any'}, Age Range: ${filters.ageRange || 'Any'}, Civil Status: ${filters.civilStatus || 'Any'}, Nationality: ${filters.nationality || 'Any'}, Religion: ${filters.religion || 'Any'}, Others: ${filters.voter ? 'Voter' : ''} ${filters.indigent ? 'Indigent' : ''} ${filters.soloParent ? 'Solo Parent' : ''} ${filters.fourps ? '4Ps' : ''} ${filters.pwd ? 'PWD' : ''}`,
                10,
                45
            );
    
            // Add resident table
            autoTable(doc, {
                startY: 50,
                head: [[
                    'Resident No.', 'Name', 'Sex', 'Age', 'Civil Status', 'Contact Number',
                    'Address', 'Nationality', 'Religion', 'Voter', 'Indigent', 'Solo Parent', '4Ps', 'PWD'
                ]],
                body: residents.map((resident) => [
                    resident.residentID,
                    `${resident.firstName} ${resident.middleName || ''} ${resident.lastName}`,
                    resident.sex,
                    resident.age,
                    resident.civilStatus,
                    resident.contactNumber,
                    `${resident.permanentAddress.street}, ${resident.permanentAddress.barangay}, ${resident.permanentAddress.city}`,
                    resident.nationality,
                    resident.religion,
                    resident.voter ? 'Yes' : '',
                    resident.indigent ? 'Yes' : '',
                    resident.soloParent ? 'Yes' : '',
                    resident.fourps ? 'Yes' : '',
                    resident.pwd ? 'Yes' : ''
                ]),
                theme: 'grid',
            styles: {
                fontSize: 10,
                cellPadding: 2,
                lineColor: [235, 237, 239], // border color
                lineWidth: 0.1
            },
            headStyles: {
                fillColor: [19, 70, 172], // #1346AC as RGB
                textColor: [255, 255, 255], // white text
                fontStyle: 'bold',
                halign: 'center'
            },
            bodyStyles: {
                halign: 'center',
            },
            margin: { top: 10 }
        });
    
            doc.save('barangay_profiling.pdf');
        };
    
        image.onerror = () => {
            console.error('Failed to load local logo');
            alert('Failed to load logo image. Please check the import path.');
        };
    };    
    
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-lg w-[95vw] max-w-[1500px] h-[90vh] flex flex-col overflow-hidden">
        <div className="overflow-y-auto px-4 py-2 flex-grow">
        <div className="flex items-center text-center mb-4 space-x-2">
            <img 
            src={barangay?.logo} 
            alt="Barangay Logo" 
            className="w-20 h-20 object-contain"
            />
            <h2 className="text-2xl font-bold">Barangay Profiling</h2>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 text-sm text-gray-700 mb-4">
            <div>Sex: {filters.sex}</div>
            <div>Age Range: {filters.ageRange}</div>
            <div>Civil Status: {filters.civilStatus}</div>
            <div>Nationality: {filters.nationality}</div>
            <div>Religion: {filters.religion}</div>
            <div>
            Others: {filters.voter && "Voter "} 
            {filters.indigent && "Indigent "} 
            {filters.soloParent && "Solo Parent "} 
            {filters.fourps && "4Ps "} 
            {filters.pwd && "PWD"}
            </div>
        </div>

        <table className="min-w-full border border-gray-300 text-sm">
            <thead className="bg-[#1346AC] text-white">
            <tr>
                <th className="py-2 px-2 border rounded-tl-lg">Resident No.</th>
                <th className="py-2 px-2 border">Name</th>
                <th className="py-2 px-2 border">Sex</th>
                <th className="py-2 px-2 border">Age</th>
                <th className="py-2 px-2 border">Civil Status</th>
                <th className="py-2 px-2 border">Contact Number</th>
                <th className="py-2 px-2 border">Address</th>
                <th className="py-2 px-2 border">Nationality</th>
                <th className="py-2 px-2 border">Religion</th>
                <th className="py-2 px-2 border">Voter</th>
                <th className="py-2 px-2 border">Indigent</th>
                <th className="py-2 px-2 border">Solo Parent</th>
                <th className="py-2 px-2 border">4Ps</th>
                <th className="py-2 px-2 border rounded-tr-lg">PWD</th>
            </tr>
            </thead>
            <tbody>
            {currentResidents.map((resident, index) => (
                <tr key={resident.residentID} className={`border border-gray-400 text-center ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100' }`}>
                <td className="py-2 px-2 border">{resident.residentID}</td>
                <td className="py-2 px-2 border text-left">{`${resident.firstName} ${resident.middleName || ''} ${resident.lastName}`}</td>
                <td className="py-2 px-2 border">{resident.sex}</td>
                <td className="py-2 px-2 border">{resident.age}</td>
                <td className="py-2 px-2 border">{resident.civilStatus}</td>
                <td className="py-2 px-2 border text-left">{resident.contactNumber}</td>
                <td className="py-2 px-2 border text-left">
                    {`${resident.permanentAddress.street}, ${resident.permanentAddress.barangay}, ${resident.permanentAddress.city}`}
                </td>
                <td className="py-2 px-2 border">{resident.nationality}</td>
                <td className="py-2 px-2 border">{resident.religion}</td>
                <td className="py-2 px-2 border">{resident.voter ? "✔" : ""}</td>
                <td className="py-2 px-2 border">{resident.indigent ? "✔" : ""}</td>
                <td className="py-2 px-2 border">{resident.soloParent ? "✔" : ""}</td>
                <td className="py-2 px-2 border">{resident.fourps ? "✔" : ""}</td>
                <td className="py-2 px-2 border">{resident.pwd ? "✔" : ""}</td>
                </tr>
            ))}
            </tbody>
        </table>
        </div>
        <div className="p-4 border-t flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0">
        <div className="text-sm text-gray-600 font-medium">
            Showing {Math.min(indexOfLast, residents.length)} of {residents.length} entries
        </div>

        <div className="flex items-center gap-1 font-semibold">
            <button
            onClick={handlePrevious}
            disabled={pageRange[0] === 1}
            className="px-3 py-1 mr-2 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
            >
            Previous
            </button>
            {pageRange.map((pageNumber) => (
            <button
                key={pageNumber}
                onClick={() => paginate(pageNumber)}
                disabled={pageNumber > totalPages}
                className={`px-3 py-1 rounded ${currentPage === pageNumber
                ? 'bg-[#1346AC] text-white'
                : 'bg-gray-200 hover:bg-[#1346AC] hover:text-white'
                }`}
            >
                {pageNumber}
            </button>
            ))}
            <button
            onClick={handleNext}
            disabled={pageRange[3] >= totalPages}
           className="px-3 py-1 rounded bg-gray-200 hover:bg-[#1346AC] hover:text-white"
            >
            Next
            </button>
        </div>

        <div className="flex gap-2">
            <button className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold" onClick={handleExportPDF}>
            Export List as PDF
            </button>
            <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-full font-semibold" onClick={onClose}>
            Close
            </button>
        </div>
        </div>
            </div>
        </div>
    );
};

export default BarangayProfilingTableModal;
