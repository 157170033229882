import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';

const ViewIncidentReportModal = ({ onClose, incidentReport, onVerify }) => {
    // Format the date
    const formattedDate = new Date(incidentReport.dateAndTimeofIncident).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const handleVerifyClick = async () => {
        let newStatus = '';
    
        if (incidentReport.status === 'Verified') {
            newStatus = 'Active';
        } else if (incidentReport.status === 'Active') {
            newStatus = 'Settled';
        } else {
            newStatus = 'Verified';
        }
    
        let confirmMessage = '';
        let successMessage = '';
        let buttonText = '';
    
        switch (newStatus) {
            case 'Verified':
                confirmMessage = "Do you want to verify this complaint?";
                successMessage = "The complaint has been successfully verified.";
                buttonText = "Yes, Verify it!";
                break;
            case 'Active':
                confirmMessage = "Do you want to mark this complaint as Active (Ongoing)?";
                successMessage = "The complaint has been marked as Active (Ongoing).";
                buttonText = "Yes, Mark as Active";
                break;
            case 'Settled':
                confirmMessage = "Do you want to mark this complaint as Settled?";
                successMessage = "The complaint has been marked as Settled.";
                buttonText = "Yes, Mark as Settled";
                break;
            default:
                return;
        }
    
        Swal.fire({
            title: 'Are you sure?',
            text: confirmMessage,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: buttonText,
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/incident-reports/${incidentReport._id}`, {
                        status: newStatus,
                        complainantID: incidentReport.complainantID, 
                        ReferenceNo: incidentReport.ReferenceNo      
                    });
    
                    Swal.fire(
                        newStatus === 'Settled' ? 'Settled!' : newStatus === 'Active' ? 'Marked as Active!' : 'Verified!',
                        successMessage,
                        'success'
                    );
    
                    // ✅ Refresh data in the parent component
                    if (onVerify) {
                        onVerify();
                    }
    
                    onClose(); // ✅ Close the modal
                } catch (error) {
                    console.error(`Error updating incident report status to ${newStatus}:`, error);
                }
            }
        });
    };
    
    const generateDocument = async () => {
        Swal.fire({
            title: 'Generate Document?',
            text: "This will create a complaint form document.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, generate it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Path to your .docx template (ensure it's in `public/` folder)
                const templatePath = '/complaintform.docx';
    
                // Load the DOCX template
                JSZipUtils.getBinaryContent(templatePath, (error, content) => {
                    if (error) throw error;
    
                    const zip = new PizZip(content);
                    const doc = new Docxtemplater(zip);
    
                    // Format the date
                    const date = new Date(incidentReport.dateAndTimeofIncident);
                    const formattedDay = date.getDate(); // Extract day
                    const formattedMonth = date.toLocaleString('en-US', { month: 'long' }); // Extract month name
                    const formattedYear = date.getFullYear(); // Extract year
    
                    // Populate the template with data
                    doc.setData({
                        ReferenceNo: incidentReport.ReferenceNo || 'N/A',
                        day: formattedDay || 'N/A',
                        month: formattedMonth || 'N/A',
                        year: formattedYear || 'N/A', // Added year field
                        complainantname: incidentReport.complainantname.join(', ') || 'N/A', // Converts array to string
                        respondentname: incidentReport.respondentname.join(', ') || 'N/A',
                        typeofcomplaint: incidentReport.typeofcomplaint || 'N/A',
                        incidentdescription: incidentReport.incidentdescription || 'N/A',
                        relieftobegranted: incidentReport.relieftobegranted || 'N/A',
                    });
    
                    try {
                        doc.render(); // Replace placeholders with actual data
                    } catch (error) {
                        console.error('Error rendering document:', error);
                        throw error;
                    }
    
                    // Generate and download the document
                    const out = doc.getZip().generate({
                        type: 'blob',
                        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    });
    
                    saveAs(out, `${incidentReport.ReferenceNo}.docx`); // Save the file
                    Swal.fire('Generated!', 'Complaint form has been generated.', 'success');
                });
            }
        });
    };    

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto" onClick={onClose}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/4 md:w-2/2 max-h-[80vh] scrollbar-thin overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                <h2 className="text-xl font-semibold mb-4">Complaint</h2>
                
                <div className="text-left mb-4">
                    <p className="font-semibold">Reference No.</p>
                    <p>{incidentReport.ReferenceNo}</p>
                </div>

                <div className="text-left mb-4">
                    <p className="font-semibold">Date of Incident</p>
                    <p>{formattedDate}</p>
                </div>

                {/* Complainant Names */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Complainant(s)</p>
                    <ul className="list-decimal list-inside">
                        {incidentReport.complainantname.map((name, index) => (
                            <li key={index}>{name}</li>
                        ))}
                    </ul>
                </div>

                {/* Respondent Names */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Respondent(s)</p>
                    <ul className="list-decimal list-inside">
                        {incidentReport.respondentname.map((name, index) => (
                            <li key={index}>{name}</li>
                        ))}
                    </ul>
                </div>

                {/* Type of Complaint */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Type of Complaint</p>
                    <p>{incidentReport.typeofcomplaint}</p>
                </div>

                {/* Incident Description */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Incident Description</p>
                    <p>{incidentReport.incidentdescription}</p>
                </div>

                {/* Relief to Be Granted */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Relief to Be Granted</p>
                    <p>{incidentReport.relieftobegranted}</p>
                </div>

                {/* Attachments */}
                <div className="text-left mb-4">
                    <p className="font-semibold">Attachments</p>
                    <div className="flex flex-col space-y-2">
                        {incidentReport.Attachment && incidentReport.Attachment.length > 0 ? (
                            incidentReport.Attachment.map((attachment, index) => (
                                <div key={index} className="relative flex items-center justify-between p-2 border rounded-lg shadow-sm bg-gray-50">
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 bg-[#1346AC] text-white flex items-center justify-center rounded-full mr-3">
                                            <i className="fas fa-file-alt"></i>
                                        </div>
                                        <a href={attachment.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 font-semibold hover:underline truncate">
                                            {attachment.originalname}
                                        </a>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No attachments available</p>
                        )}
                    </div>
                </div>

                {/* Remarks Section */}
                {incidentReport.remarks && (
                    <div className="text-left mb-4">
                        <p className="font-semibold">Remarks</p>
                        <p>{incidentReport.remarks}</p>
                    </div>
                )}

                {/* Buttons */}
                <div className="flex flex-col space-y-2 text-center mt-6">
                <button
                        onClick={handleVerifyClick}
                        className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold hover:bg-blue-700"
                    >
                        {incidentReport.status === 'Verified' ? 'Mark as Active' : 
                        incidentReport.status === 'Active' ? 'Mark as Settled' : 'Verify'}
                    </button>
                    <button
                        className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold text-center hover:bg-blue-700"
                        onClick={generateDocument}
                    >
                        Generate Document
                    </button>
                    <button onClick={onClose} className="bg-white text-[#1346AC] border border-[#1346AC] px-4 py-2 rounded-full font-semibold mt-2">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ViewIncidentReportModal;
