import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { MdRateReview, MdCheckBox , MdDangerous, MdArchive } from "react-icons/md";
import logo from '../assets/icons/logo192.png'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const AccountsForVerification = () => {
    const [residents, setResidents] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [filters, setFilters] = useState({
        sex: 'All',
        civilStatus: 'All',
        accountStatus: 'All',
        created_at: 'All', 
    });
    const [currentPage, setCurrentPage] = useState(1);  // Pagination state
    const itemsPerPage = 10;  // Number of residents to show per page
    const [showDenyModal, setShowDenyModal] = useState(false); // State to control modal visibility
    const [selectedResidentId, setSelectedResidentId] = useState(null); // State to hold the ID of the resident being denied
    const [remarks, setRemarks] = useState(''); // State to hold the input remarks
    const [selectedReason, setSelectedReason] = useState(""); 
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState("Resident No.");
    const [sortDirection, setSortDirection] = useState("asc");
    const [barangay, setBarangay] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBarangay = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/barangay`); 
                setBarangay(response.data.barangay);
            } catch (error) {
                console.error('Error fetching barangay data:', error);
            }
        };
    
        fetchBarangay();
    }, []); 

    useEffect(() => {
        fetchResidents();
    }, []);

    const fetchResidents = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setResidents(response.data.residents);
        } catch (error) {
            console.error('Error fetching residents:', error);
        }
    };

    const resetFilters = () => {
        setFilters({
            sex: 'All',
            civilStatus: 'All',
            accountStatus: 'All',  // Reset to "All" (showing "Pending" by default)
            created_at: 'All',  // Reset to show "Oldest to Latest"
        });
    };

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
        let sortedResidents = [...residents];
        if (e.target.value === 'Resident No.') {
            sortedResidents.sort((a, b) => a.residentID - b.residentID);
        } else if (e.target.value === 'Name') {
            sortedResidents.sort((a, b) => a.lastName.localeCompare(b.lastName));
        } else if (e.target.value === 'Sex') {
            sortedResidents.sort((a, b) => a.sex.localeCompare(b.sex));
        } else if (e.target.value === 'Civil Status') {
            sortedResidents.sort((a, b) => a.civilStatus.localeCompare(b.civilStatus));
        } else if (e.target.value === 'Contact Number') {
            sortedResidents.sort((a, b) => a.contactNumber.localeCompare(b.contactNumber));
        } else if (e.target.value === 'Address') {
            sortedResidents.sort((a, b) => formatAddress(a.permanentAddress).localeCompare(formatAddress(b.permanentAddress)));
        }
        setResidents(sortedResidents);
    };

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const approveResident = async (residentID) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire("Unauthorized", "You need to be logged in to approve a resident.", "error");
                return;
            }
    
            // ✅ Proceed with approval confirmation
            Swal.fire({
                title: "Approve Resident?",
                text: "Are you sure you want to approve this resident?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, Approve!",
                cancelButtonText: "Cancel",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const approveResponse = await axios.put(
                            `${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/approve/${residentID}`,
                            {},
                            { headers: { Authorization: `Bearer ${token}` } }
                        );
    
                        Swal.fire("Approved!", "The resident has been approved.", "success");
                        fetchResidents(); // Refresh residents list
                    } catch (error) {
                        console.error("❌ Error approving resident:", error);
    
                        // ✅ Extract the error message from the API response
                        let errorMessage = "There was an issue approving the resident.";
                        if (error.response && error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message;
                        }
    
                        Swal.fire("Error", errorMessage, "error");
                    }
                }
            });
        } catch (error) {
            console.error("❌ Unexpected error in approveResident:", error);
            Swal.fire("Error", "An unexpected error occurred. Try again later.", "error");
        }
    };    

    const denyResident = async () => {
        if (!remarks.trim()) {
            Swal.fire('Error', 'Please provide a reason for denying the resident.', 'error');
            return;
        }
    
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to deny this resident?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Deny it!',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/deny/${selectedResidentId}`, {
                        remarks
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    });
    
                    Swal.fire('Denied!', 'The resident has been denied.', 'success');
    
                    fetchResidents(); // Refresh list after denial
                    setShowDenyModal(false);
                    setRemarks('');
                } catch (error) {
                    console.error("Error denying resident:", error);
                    Swal.fire('Error', 'There was an issue denying the resident.', 'error');
                }
            }
        });
    };
    

    const archiveResident = async (residentID, householdID, isHouseholdHead) => {
        Swal.fire({
            title: 'Are you sure?',
            text: isHouseholdHead
                ? "This resident is the household head. You need to assign a new head before archiving."
                : "Do you want to archive this resident? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Archive it!',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const token = localStorage.getItem('token');
                    if (!token) {
                        Swal.fire('Error', 'Unauthorized access. Please log in.', 'error');
                        return;
                    }
    
                    // ✅ If the resident is a household head, select a new head first
                    if (isHouseholdHead) {
                        if (!householdID || householdID === "undefined") {
                            Swal.fire('Error', 'Household ID is missing or invalid.', 'error');
                            return;
                        }
    
                        // Fetch household members
                        const householdResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API_KEY}/api/household/id/${householdID}`);
                        const household = householdResponse.data;
    
                        if (!household || !household.members.length) {
                            Swal.fire('Error', 'No other members in household. Cannot assign a new head.', 'error');
                            return;
                        }
    
                        // ✅ Exclude the current resident from memberOptions
                        const memberOptions = {};
                        household.members
                            .filter(member => member._id !== residentID) // Remove the current resident from the dropdown
                            .forEach(member => {
                                memberOptions[member._id] = `${member.firstName} ${member.middleName || ''} ${member.lastName}`;
                            });
    
                        if (Object.keys(memberOptions).length === 0) {
                            Swal.fire('Error', 'No available members to assign as household head.', 'error');
                            return;
                        }
    
                        // Show Swal dropdown for selecting new head
                        const { value: newHeadId } = await Swal.fire({
                            title: 'Select New Household Head',
                            input: 'select',
                            inputOptions: memberOptions,
                            inputPlaceholder: 'Choose a new head',
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                            confirmButtonColor: '#1346AC'
                        });
    
                        if (!newHeadId) {
                            Swal.fire('Cancelled', 'Household head change was cancelled.', 'info');
                            return;
                        }
    
                        // ✅ Prompt for relationship
                        const { value: relationship } = await Swal.fire({
                            title: 'Select Relationship to New Head',
                            input: 'select',
                            inputOptions: {
                                'Spouse': 'Spouse',
                                'Parent': 'Parent',
                                'Child': 'Child',
                                'Sibling': 'Sibling',
                                'Grandchild': 'Grandchild',
                                'Grandparent': 'Grandparent',
                                'Niece/Nephew': 'Niece/Nephew',
                                'Uncle/Aunt': 'Uncle/Aunt',
                                'Cousin': 'Cousin',
                                'Other': 'Other'
                            },
                            inputPlaceholder: 'Select relationship',
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                            confirmButtonColor: '#1346AC',
                        });
    
                        if (!relationship) {
                            Swal.fire('Cancelled', 'Household head change was cancelled.', 'info');
                            return;
                        }
    
                        // ✅ Update Household Head in Database
                        await axios.put(
                            `${process.env.REACT_APP_BACKEND_API_KEY}/api/household/${householdID}/change-head`,
                            { newHeadId, formerHeadId: residentID, relationship },
                            { headers: { Authorization: `Bearer ${token}` } }
                        );
                    }
    
                    // ✅ Archive Resident
                    await axios.put(
                        `${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/${residentID}`,
                        { accountStatus: 'Archive' },
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
    
                    Swal.fire('Archived!', 'The resident has been archived.', 'success');
                    fetchResidents(); // Refresh list
                } catch (error) {
                    console.error('Error archiving resident:', error.response ? error.response.data : error.message);
                    Swal.fire('Error', 'There was an issue archiving the resident.', 'error');
                }
            }
        });
    };
    

    const openDenyModal = (residentID) => {
        setSelectedResidentId(residentID);
        setShowDenyModal(true);
    };

    // Filtered residents based on search, sex, civil status, and account status
    const filteredResidents = residents.filter((resident) => {
        const fullName = `${resident.firstName} ${resident.middleName ? resident.middleName + ' ' : ''}${resident.lastName}`.toLowerCase();
        const matchesSearch = searchTerm.trim() === '' || fullName.includes(searchTerm.toLowerCase());
        const matchesSex = filters.sex === 'All' || resident.sex === filters.sex;
        const matchesCivilStatus = filters.civilStatus === 'All' || resident.civilStatus === filters.civilStatus;

        // Filter account status - "All" should behave like "Pending", showing only "Pending" unless explicitly filtered
        const matchesAccountStatus = filters.accountStatus === 'All'
            ? resident.accountStatus === 'Pending'
            : resident.accountStatus === filters.accountStatus;

        return matchesSearch && matchesSex && matchesCivilStatus && matchesAccountStatus;
    });

    const handleSortOrderChange = (e) => {
        setSortOrder(e.target.value);
        setSortDirection("asc"); // Reset to default direction
    };
    
    const handleSortDirectionChange = (e) => {
        setSortDirection(e.target.value);
    };

    // Sorting logic
    const sortedResidents = [...filteredResidents].sort((a, b) => {
        if (sortOrder === "Resident No.") {
            // Extract numerical part from Resident No. (e.g., R2025-0001 -> 0001)
            const numA = parseInt(a.residentID.split("-")[1], 10);
            const numB = parseInt(b.residentID.split("-")[1], 10);
            return sortDirection === "asc" ? numA - numB : numB - numA;
        }
        if (sortOrder === "Name") {
            return sortDirection === "asc"
                ? a.lastName.localeCompare(b.lastName)
                : b.lastName.localeCompare(a.lastName);
        }
        if (sortOrder === "Address") {
            // Sort Address based on lot numbers first, then street/subdivision
            const addressA = `${a.permanentAddress.lotNo || "000"} ${a.permanentAddress.street || ""}`;
            const addressB = `${b.permanentAddress.lotNo || "000"} ${b.permanentAddress.street || ""}`;
            return sortDirection === "asc" ? addressA.localeCompare(addressB) : addressB.localeCompare(addressA);
        }
        return 0;
    });

    // Pagination calculations
    const indexOfLastResident = currentPage * itemsPerPage;
    const indexOfFirstResident = indexOfLastResident - itemsPerPage;
    const currentResidents = sortedResidents.slice(indexOfFirstResident, indexOfLastResident);
    const totalPages = Math.ceil(sortedResidents.length / itemsPerPage);

    const formatAddress = (permanentAddress) => {
        let address = [
            permanentAddress.houseNo,
            permanentAddress.street,
        ].filter(Boolean).join(', ');

        const fullAddress = address;
        return fullAddress.length > 50 ? `${fullAddress.slice(0, 50)}...` : fullAddress;
    };

    const deleteResident = async (residentID) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/${residentID}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            fetchResidents(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting resident:', error.response ? error.response.data : error.message);
        }
    };

    const handlePrint = () => {
        const doc = new jsPDF('landscape');
        const image = new Image();
        image.src = logo;
    
        image.onload = () => {
            doc.addImage(image, 'PNG', 10, 10, 30, 30);
    
            doc.setFontSize(18);
            doc.text('List of Accounts for Verification', 45, 25);
    
            doc.setFontSize(10);
            doc.text(
                `Barangay: ${barangay?.barangayName || ''}, Municipality: ${barangay?.municipality || ''}, Province: ${barangay?.province || ''}`,
                45,
                33
            );   
    
            autoTable(doc, {
                startY: 45,
                head: [[
                    'Resident No.', 'Name', 'Sex', 'Civil Status', 'Contact No.',
                    'Address', 'Status'
                ]],
                body: currentResidents.map(resident => [
                    resident.residentID,
                    `${resident.firstName} ${resident.middleName || ''} ${resident.lastName}`,
                    resident.sex,
                    resident.civilStatus,
                    resident.contactNumber,
                    formatAddress(resident.permanentAddress),
                    resident.accountStatus
                ]),
                theme: 'grid',
                styles: {
                    fontSize: 10,
                    cellPadding: 2,
                    lineColor: [235, 237, 239],
                    lineWidth: 0.1
                },
                headStyles: {
                    fillColor: [19, 70, 172],
                    textColor: [255, 255, 255],
                    fontStyle: 'bold',
                    halign: 'center'
                },
                bodyStyles: {
                    halign: 'center',
                },
                margin: { top: 10 }
            });
    
            doc.save('verification_accounts.pdf');
        };
    
        image.onerror = () => {
            console.error('Failed to load logo');
            alert('Logo image could not be loaded.');
        };
    };
    


     const handleDownloadBackup = async (fileType) => {
            const confirmation = await Swal.fire({
                title: `Confirm Download`,
                text: fileType === 'json'
                    ? 'Do you want to download the Residents and Households JSON backup?'
                    : 'Do you want to download the Households and Residents Excel file?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Download',
                cancelButtonText: 'Cancel',
            });
        
            if (!confirmation.isConfirmed) return;
        
            try {
                let urls = [];
        
                if (fileType === 'json') {
                    urls = [
                        { url: `${process.env.REACT_APP_BACKEND_API_KEY}/api/households/export/json`, filename: 'households_backup.json' },
                        { url: `${process.env.REACT_APP_BACKEND_API_KEY}/api/backup/residents`, filename: 'residents_backup.json' },
                    ];
                } else {
                    urls = [
                        { url: `${process.env.REACT_APP_BACKEND_API_KEY}/api/households/export/excel`, filename: 'households_backup.xlsx' },
                        { url: `${process.env.REACT_APP_BACKEND_API_KEY}/api/backup/residents/excel`, filename: 'residents_backup.xlsx' } // ✅ Added Residents Excel download
                    ];
                }
        
                for (const { url, filename } of urls) {
                    const response = await fetch(url);
                    if (!response.ok) throw new Error(`Failed to download ${filename}`);
        
                    const blob = await response.blob();
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
        
                Swal.fire({
                    icon: 'success',
                    title: 'Download Successful',
                    text: fileType === 'json'
                        ? 'Residents and Households JSON backups have been downloaded.'
                        : 'Households and Residents Excel backups have been downloaded.',
                });
            } catch (error) {
                console.error('Error downloading backup:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    text: 'An error occurred while downloading. Please try again.',
                });
            }
        };    


    return (
        <div className="bg-white p-6 rounded-lg">
            <div className='flex-col justify-between items-center mb-2'>
            <div className='flex justify-between items-center text-center mb-2'>
            <h2 className="text-2xl font-semibold">List of Accounts for Verification</h2>
            <div className="flex justify-between items-center">
                {/* Export as File Dropdown */}
                <div className="dropdown dropdown-hover p-2">
                    <button
                        tabIndex={0}
                        className="text-[#1346AC] hover:bg-[#1346AC] hover:text-white px- py-2 rounded-full font-semibold border border-[#1346AC] min-w-40"
                    >
                        Export as File
                    </button>
                    <ul
                        tabIndex={0}
                        className="dropdown-content menu bg-base-100 rounded-box shadow w-52 p-2 absolute left-1/2 transform -translate-x-1/2 z-50"
                    >
                        <li>
                            <button onClick={handlePrint} className="w-full text-left">Download as PDF</button>
                        </li>
                        <li>
                            <button onClick={() => handleDownloadBackup('excel')} className="w-full text-left">Download as Excel</button>
                        </li>
                        <li>
                            <button onClick={() => handleDownloadBackup('json')} className="w-full text-left">Download as JSON</button>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <div className="flex justify-between">
                <div className="flex space-x-4">
                    <div className="w-48">
                        <label htmlFor="sex" className="block text-sm font-medium text-gray-700">Sex</label>
                        <div className="relative">
                            <select
                                id="sex"
                                name="sex"
                                value={filters.sex}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md appearance-none focus:ring-0 focus:border-gray-300"
                            >
                                <option>All</option>
                                <option>Male</option>
                                <option>Female</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M7 10l5 5 5-5H7z" />
                            </svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-48">
                        <label htmlFor="civilStatus" className="block text-sm font-medium text-gray-700">Civil Status</label>
                        <div className="relative">
                            <select
                                id="civilStatus"
                                name="civilStatus"
                                value={filters.civilStatus}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md appearance-none focus:ring-0 focus:border-gray-300"
                            >
                                <option>All</option>
                                <option>Single</option>
                                <option>Married</option>
                                <option>Separated</option>
                                <option>Widowed</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-48">
                        <label htmlFor="accountStatus" className="block text-sm font-medium text-gray-700">Status</label>
                        <div className="relative">
                            <select
                                id="accountStatus"
                                name="accountStatus"
                                value={filters.accountStatus}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md appearance-none focus:ring-0 focus:border-gray-300"
                            >
                                <option>All</option>
                                <option>Pending</option>
                                <option>Denied</option>
                                <option>Archive</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-48">
                        <label htmlFor="civilStatus" className="block text-sm font-medium text-gray-700">Date</label>
                        <div className="relative">
                            <select
                                id="created_at"
                                name="created_at"
                                value={filters.created_at}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md appearance-none focus:ring-0 focus:border-gray-300"
                            >
                                <option>All</option>
                                <option>Oldest to Latest</option>
                                <option>Latest to Oldest</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <svg className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <button
                        className="text-[#1346AC] hover:text-blue-500 cursor-pointer font-semibold"
                        onClick={resetFilters}
                    >
                        Reset Filters
                    </button>
                </div>
                <div className="flex flex-col items-end space-y-2">
                <input
                        type="text"
                        placeholder="Search residents"
                        className="w-80 px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onFocus={(e) => e.target.placeholder = ""}
                        onBlur={(e) => e.target.placeholder = "Search residents"}
                    />
                <div className="flex items-center space-x-4">
                    {/* Sort By Dropdown */}
                    <div className="flex items-center">
                        <label htmlFor="sortBy" className="text-sm font-medium text-gray-700 mr-2">Sort by</label>
                        <div className="relative">
                            <select
                                id="sortBy"
                                name="sortBy"
                                className="block appearance-none w-full bg-white text-[#1346AC] font-semibold py-2 px-1 pr-8 rounded leading-tight focus:outline-none"
                                value={sortOrder}
                                onChange={handleSortOrderChange}
                            >
                                <option value="Resident No.">Resident No.</option>
                                <option value="Name">Name</option>
                                <option value="Address">Address</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M7 10l5 5 5-5H7z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    {/* Sort Direction Dropdown */}
                    <div className="relative">
                        <select
                            id="sortDirection"
                            name="sortDirection"
                            className="block appearance-none w-full bg-white text-[#1346AC] font-semibold py-2 px-1 pr-8 rounded leading-tight focus:outline-none"
                            value={sortDirection}
                            onChange={handleSortDirectionChange}
                        >
                            {sortOrder === "Resident No." && (
                                <>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </>
                            )}
                            {sortOrder === "Name" && (
                                <>
                                    <option value="asc">A-Z</option>
                                    <option value="desc">Z-A</option>
                                </>
                            )}
                            {sortOrder === "Address" && (
                                <>
                                    <option value="asc">A-Z</option>
                                    <option value="desc">Z-A</option>
                                    <option value="numeric">Lot Number</option>
                                </>
                            )}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M7 10l5 5 5-5H7z" />
                            </svg>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            <div className="overflow-x-auto scrollbar-thin">
            <table className="min-w-full bg-white" id="printable-area">
                <thead>
                    <tr className='bg-[#1346AC] text-white text-center'>
                        <th className="py-2 px-4 border-b border-r border-gray-400 rounded-tl-lg">Resident No.</th>
                        <th className="py-2 px-4 border-b border-r border-gray-400">Name</th>
                        <th className="py-2 px-4 border-b border-r border-gray-400">Sex</th>
                        <th className="py-2 px-4 border-b border-r border-gray-400">Civil Status</th>
                        <th className="py-2 px-4 border-b border-r border-gray-400">Contact Number</th>
                        <th className="py-2 px-4 border-b border-r border-gray-400">Address</th>
                        <th className="py-2 px-4 border-b border-r border-gray-400 status-column">Status</th>
                        <th className="py-2 px-4 border-b border-gray-400 rounded-tr-lg actions-column">Actions</th>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {currentResidents.map((resident, index) => (
                        <tr key={resident._id} className={`border-t border-gray-400 text-center ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100' }`}>
                            <td className="py-2 px-4 border-l border-b border-r border-gray-400 cursor-pointer text-blue-500 hover:underline font-normal hover:font-semibold" onClick={() => navigate(`/view-request/${resident._id}`)}>{resident.residentID}</td>
                            <td className="py-2 px-2 w-52 border-b border-r border-gray-400 cursor-pointer text-blue-500 hover:underline font-normal hover:font-semibold truncate" onClick={() => navigate(`/view-request/${resident._id}`)}>
                                {`${resident.firstName} ${resident.middleName ? resident.middleName + ' ' : ''}${resident.lastName}`}
                            </td>
                            <td className="py-2 px-4 border-l border-b border-r border-gray-400 cursor-pointer text-blue-500 hover:underline font-normal hover:font-semibold" onClick={() => navigate(`/view-request/${resident._id}`)}>{resident.sex}</td>
                            <td className="py-2 px-4 border-l border-b border-r border-gray-400 cursor-pointer text-blue-500 hover:underline font-normal hover:font-semibold" onClick={() => navigate(`/view-request/${resident._id}`)}>{resident.civilStatus}</td>
                            <td className="py-2 px-4 border-l border-b border-r border-gray-400 cursor-pointer text-blue-500 hover:underline font-normal hover:font-semibold" onClick={() => navigate(`/view-request/${resident._id}`)}>{resident.contactNumber}</td>
                            <td className="py-2 px-2 w-56 border-l border-b border-r border-gray-400 cursor-pointer text-blue-500 hover:underline font-normal hover:font-semibold truncate" onClick={() => navigate(`/view-request/${resident._id}`)}>{formatAddress(resident.permanentAddress)} St.</td>
                            <td className="py-2 px-4 border-l border-b border-r border-gray-400 status-column">
                                <span className={`px-2 py-1 rounded-full font-semibold 
                                ${resident.accountStatus === 'Pending' ? 'bg-yellow-200' : 
                                    resident.accountStatus === 'Denied' ? 'bg-red-500' : 
                                    resident.accountStatus === 'Archived' ? 'bg-red-700'  : 
                                    'bg-red-200'}`}>
                                    {resident.accountStatus}
                                </span>
                            </td>
                            <td className="px-2 w-72 border-b border-r border-gray-300 text-center font-semibold actions-column">
                                <div className="flex justify-center space-x-4 items-center">
                                <div className="border-r border-gray-400 pr-4">
                                    <button className="pl-2 text-[#1346AC] hover:text-blue-500 inline-flex items-center" 
                                    onClick={() => navigate(`/view-request/${resident._id}`)}>
                                        <MdRateReview  className="mr-1 text-lg" />
                                        VIEW
                                    </button> 
                                    </div>
                                    <div className={`pr-4 ${resident.accountStatus !== "Archive" ? "border-r border-gray-300" : ""}`}>
                                    <button 
                                        className="text-[#1346AC] hover:text-blue-500 inline-flex items-center" 
                                        onClick={() => approveResident(resident._id)}>
                                        <MdCheckBox className="mr-1 text-lg" />
                                        APPROVE
                                    </button>
                                </div>
                                {resident.accountStatus !== "Archive" && (
                                        <div className="border-r border-gray-300 pr-4">
                                            <button 
                                                className="text-[#1346AC] hover:text-yellow-500 inline-flex items-center"
                                                onClick={() => openDenyModal(resident._id)}>
                                                <MdDangerous className="mr-1 text-lg" />
                                                DENY
                                            </button>
                                        </div>
                                    )}
                                {resident.accountStatus !== "Archive" && (
                                    <div>
                                        <button 
                                            className="pr-2 text-[#1346AC] hover:text-red-500 inline-flex items-center"
                                            onClick={() => {
                                                if (!resident.householdID) {
                                                    Swal.fire('Error', 'Resident does not belong to a household.', 'error');
                                                    return;
                                                }
                                                archiveResident(resident._id, resident.householdID, resident.roleinHousehold === "Household Head");
                                            }}>
                                            <MdArchive className="mr-1 text-lg" />
                                            ARCHIVE
                                        </button>
                                    </div>
                                )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <div className="flex justify-between items-center mt-4">
                <div className="text-sm text-gray-600">
                    Showing {indexOfFirstResident + 1} to {Math.min(indexOfLastResident, filteredResidents.length)} of {filteredResidents.length} entries
                </div>
                <div className="flex space-x-2 font-semibold">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            className={`px-2 py-1 rounded ${currentPage === index + 1 ? 'bg-[#1346AC] text-white' : 'bg-gray-200 hover:bg-[#1346AC]'}`}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>

            {showDenyModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-8 rounded shadow-lg items-center w-2/12 max-w-lg">
                        <h2 className="text-lg font-semibold mb-4">Select Reason for Denial</h2>

                        {/* Dropdown for Denial Reasons */}
                        <select
                            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-3"
                            value={selectedReason}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSelectedReason(value);
                                setRemarks(value === "Others" ? "" : value); 
                            }}
                        >
                            <option value="">Select a reason</option>
                            <option value="Incomplete documents">Incomplete documents</option>
                            <option value="Incorrect information">Incorrect information</option>
                            <option value="Incorrect Permanent Address">Incorrect Permanent Address</option>
                            <option value="Not eligible">Not eligible</option>
                            <option value="Others">Others</option>
                        </select>

                        {/* Textarea only appears when "Others" is selected */}
                        {selectedReason === "Others" && (
                            <textarea
                                className="w-full border border-gray-300 p-6 rounded mb-4"
                                placeholder="Enter custom remarks..."
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        )}

                        <div className="flex justify-center space-x-4">
                            <button
                                className="bg-[#d5d8dd] hover:bg-[#d9dbe0] px-4 py-2 rounded"
                                onClick={() => {
                                    setShowDenyModal(false);
                                    setSelectedReason(""); // Reset dropdown selection when closing
                                    setRemarks(""); // Reset remarks when closing
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-[#1346AC] hover:bg-blue-500 text-white px-4 py-2 rounded"
                                onClick={denyResident}
                                disabled={!remarks.trim()} // Prevents submitting an empty remark
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountsForVerification;
