import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';
import Swal from 'sweetalert2';

const ViewDocumentRequestModal = ({ onClose, documentRequest, onDocumentUpdate }) => {
    const [residentData, setResidentData] = useState(null);

    useEffect(() => {
        if (!documentRequest || !documentRequest.recipientID) {
            console.warn("⚠ Missing recipient ID. Skipping API call.");
            return;
        }
    
        let apiUrl = "";
        
        // Determine the correct API route based on the recipientID format
        if (/^R\d{4}-\d{4}$/.test(documentRequest.recipientID)) { 
            // If recipientID matches a resident format (e.g., "R2025-0001"), use the correct API
            apiUrl = `${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/residentID/${documentRequest.recipientID}`;
        } else {
            // Otherwise, default to the general API for officials
            apiUrl = `${process.env.REACT_APP_BACKEND_API_KEY}/api/residents/${documentRequest.recipientID}`;
        }
    
        axios.get(apiUrl)
            .then(response => {
                setResidentData(response.data);
            })
            .catch(error => {
                console.error("❌ Error fetching resident data:", error.response ? error.response.data : error);
                setResidentData(null);
            });
    
    }, [documentRequest]); // Runs when `documentRequest` changes
    
    
    const generateDocument = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This will generate the document and update the status to Released.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Path to your .docx template in the public folder
                const templatePath = '/barangayclearance.docx';

                // Fetch the .docx template
                JSZipUtils.getBinaryContent(templatePath, (error, content) => {
                    if (error) throw error;

                    const zip = new PizZip(content); // Load content into PizZip
                    const doc = new Docxtemplater(zip); // Load PizZip into docxtemplater

                    // Set the data for the placeholders in the DOCX template
                    doc.setData({
                        type: documentRequest.documentType || 'N/A',
                        name: documentRequest.recipient || 'N/A',
                        age: residentData?.age || 'N/A',
                        sex: residentData?.sex || 'N/A',
                        nationality: residentData?.nationality || 'N/A',
                        civilStatus: residentData?.civilStatus || 'N/A',
                        birthday: residentData?.birthday ? new Date(residentData.birthday).toLocaleDateString() : 'N/A',
                        birthplace: residentData?.birthplace || 'N/A',
                        address: `${residentData?.permanentAddress?.lotNo || 'N/A'}, ${residentData?.permanentAddress?.street || 'N/A'}, ${residentData?.permanentAddress?.city || 'N/A'}, ${residentData?.permanentAddress?.province || 'N/A'}`,
                        purpose: documentRequest.purpose || 'N/A',
                    });

                    try {
                        doc.render(); // Render the document (replace placeholders with actual values)
                    } catch (error) {
                        console.error('Error rendering document:', error);
                        throw error;
                    }

                    // Generate the document as a Blob
                    const out = doc.getZip().generate({
                        type: 'blob',
                        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    });

                    // Save the document locally
                    saveAs(out, `${documentRequest.ReferenceNo}.docx`);
                });

                // After generating the document, update the status to "Released"
                try {
                    const updatedRequest = {
                        ...documentRequest,
                        status: 'Released',
                        requestedBy: documentRequest.requestedBy._id,
                        ReferenceNo: documentRequest.ReferenceNo // Update the status
                    };

                    const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/document-requests/${documentRequest._id}`, updatedRequest);
                    console.log('Document status updated:', response.data);

                    // Call the parent update function to refresh the document list
                    onDocumentUpdate();
                    Swal.fire('Generated!', 'The document has been generated and status updated to Released.', 'success');

                    // Close the modal
                    onClose();
                } catch (error) {
                    console.error('Error updating document request status:', error);
                }
            }
        });
    };
    
    const approveDocumentRequest = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to approve this document request?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const updatedRequest = {
                        ...documentRequest,
                        status: 'Approved',
                        requestedBy: documentRequest.requestedBy._id,
                        ReferenceNo: documentRequest.ReferenceNo // Mark as approved
                    };

                    const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_KEY}/api/document-requests/${documentRequest._id}`, updatedRequest);
                    console.log('Document request approved:', response.data);

                    // Refresh document list
                    onDocumentUpdate();
                    Swal.fire('Approved!', 'The document request has been approved.', 'success');

                    // Close the modal
                    onClose();
                } catch (error) {
                    console.error('Error approving document request:', error);
                }
            }
        });
    };
    
    // Close the modal when clicking on the background
    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto" onClick={handleBackgroundClick}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/4 md:w-2/2 max-h-[80vh] scrollbar-thin overflow-y-auto" onClick={(e) => e.stopPropagation()}>
                <h2 className="text-xl font-semibold mb-4">View Document Request</h2>

                <div className="text-left mb-4">
                    <p className="font-semibold">Reference No.</p>
                    <p>{documentRequest.ReferenceNo}</p>
                </div>

                <div className="text-left mb-4">
                    <p className="font-semibold">Status</p>
                    <p>{ documentRequest.status}</p>
                </div>
                
                {documentRequest.status === 'Rejected' && documentRequest.remarks && (
                    <div className="text-left mb-4">
                        <p className="font-semibold">Remarks (Mga Puna)</p>
                        <p>{documentRequest.remarks}</p>
                    </div>
                )}

                <div className="text-left mb-4">
                    <p className="font-semibold">Date Submitted (Petsa Isinumite)</p>
                    <p>{new Date(documentRequest.created_at).toLocaleDateString()}</p>
                </div>

                <div className="text-left mb-4">
                    <p className="font-semibold">Date Last Edited (Petsa Huling Binago)</p>
                    <p>{new Date(documentRequest.updated_at).toLocaleDateString()}</p>
                </div>

                <hr className='mb-4'/>
                <div className="text-left mb-4">
                    <p className="font-semibold">Document Type (Uri ng Dokumento)</p>
                    <p>{documentRequest.documentType}</p>
                </div>

                <div className="text-left mb-4">
                    <p className="font-semibold">Purpose of Request (Layunin ng Request)</p>
                    <p>{documentRequest.purpose}</p>
                </div>

                <div className="text-left mb-4">
                    <p className="font-semibold">Requestor (Tagapagsumite)</p>
                    <p>{documentRequest.residentName}</p>
                </div>

                <div className="text-left mb-4">
                    <p className="font-semibold">Recipient (Tagatanggap)</p>
                    <p>{documentRequest.recipient}</p>
                </div>
                              
                {/* Resident Data */}
                {residentData ? (
                <>
                    <div className="text-left mb-4">
                        <p className="font-semibold">Age</p>
                        <p>{residentData.age || "Data not available"}</p> {/* Fallback for empty values */}
                    </div>

                    <div className="text-left mb-4">
                        <p className="font-semibold">Civil Status</p>
                        <p>{residentData.civilStatus || "Data not available"}</p>
                    </div>

                    <div className="text-left mb-4">
                        <p className="font-semibold">Nationality</p>
                        <p>{residentData.nationality || "Data not available"}</p>
                    </div>

                    <div className="text-left mb-4">
                        <p className="font-semibold">Sex</p>
                        <p>{residentData.sex || "Data not available"}</p>
                    </div>

                    <div className="text-left mb-4">
                        <p className="font-semibold">Birthdate</p>
                        <p>{residentData.birthday ? new Date(residentData.birthday).toLocaleDateString() : "Data not available"}</p>
                    </div>

                    <div className="text-left mb-4">
                        <p className="font-semibold">Birth Place</p>
                        <p>{residentData.birthplace || "Data not available"}</p>
                    </div>

                    <div className="text-left mb-4">
                        <p className="font-semibold">Address</p>
                        <p>{residentData.permanentAddress?.lotNo || "Data not available"},  {residentData.permanentAddress?.street || "Data not available"}, {residentData.permanentAddress?.city || "Data not available"}, {residentData.permanentAddress?.province || "Data not available"}
                    </p> 
                </div>
                </>
            ) : (
                <p>Loading resident data...</p>
            )}

                <div className="text-left mb-4">
                    <p className="font-semibold">Attachments</p>
                    <div className="flex flex-col space-y-2">
                        {documentRequest.ValidID && documentRequest.ValidID.length > 0 ? (
                            documentRequest.ValidID.map((id, index) => (
                                <div key={index} className="relative flex items-center justify-between p-2 border rounded-lg shadow-sm bg-gray-50">
                                    <div className="flex items-center">
                                        <div className="w-10 h-10 bg-[#1346AC] text-white flex items-center justify-center rounded-full mr-3">
                                            <i className="fas fa-file-alt"></i>
                                        </div>
                                        <a href={id.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 font-semibold hover:underline truncate">
                                            {id.originalname}
                                        </a>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No attachments available</p>
                        )}
                    </div>
                </div>

                <div className="flex flex-col space-y-2 text-center">
                {['Approved', 'Released'].includes(documentRequest.status) && (
                            <button
                                className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold text-center hover:bg-blue-700"
                                onClick={generateDocument}
                            >
                                Generate Document
                            </button>
                        )}
                    <button className="bg-[#1346AC] text-white px-4 py-2 rounded-full font-semibold text-center hover:bg-blue-700"
                        onClick={approveDocumentRequest}>
                        Approve
                    </button>
                    <button
                        className="bg-white text-[#1346AC] border border-[#1346AC] px-4 py-2 rounded-full font-semibold text-center hover:bg-[#1346AC] hover:text-white"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ViewDocumentRequestModal;
